const GraduationCapSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M17.9083 8.49165L17.075 8.03332L9.575 3.86665H9.48333C9.43224 3.84505 9.37923 3.82831 9.325 3.81665H9.16667H9.01667C8.95973 3.82832 8.90395 3.84505 8.85 3.86665H8.75833L1.25833 8.03332C1.13009 8.106 1.02341 8.2114 0.949195 8.33877C0.874979 8.46613 0.835876 8.61091 0.835876 8.75832C0.835876 8.90573 0.874979 9.0505 0.949195 9.17787C1.02341 9.30524 1.13009 9.41064 1.25833 9.48332L3.33333 10.6333V14.5833C3.33333 15.2464 3.59673 15.8822 4.06557 16.3511C4.53441 16.8199 5.17029 17.0833 5.83333 17.0833H12.5C13.163 17.0833 13.7989 16.8199 14.2678 16.3511C14.7366 15.8822 15 15.2464 15 14.5833V10.6333L16.6667 9.69998V12.0833C16.6667 12.3043 16.7545 12.5163 16.9107 12.6726C17.067 12.8289 17.279 12.9167 17.5 12.9167C17.721 12.9167 17.933 12.8289 18.0893 12.6726C18.2455 12.5163 18.3333 12.3043 18.3333 12.0833V9.21665C18.3331 9.06899 18.2936 8.92404 18.2189 8.79665C18.1442 8.66926 18.0371 8.56401 17.9083 8.49165ZM13.3333 14.5833C13.3333 14.8043 13.2455 15.0163 13.0893 15.1726C12.933 15.3289 12.721 15.4167 12.5 15.4167H5.83333C5.61232 15.4167 5.40036 15.3289 5.24408 15.1726C5.0878 15.0163 5 14.8043 5 14.5833V11.5583L8.75833 13.6417L8.88333 13.6917H8.95833C9.0275 13.7004 9.0975 13.7004 9.16667 13.6917C9.23584 13.7004 9.30583 13.7004 9.375 13.6917H9.45C9.49424 13.6823 9.53653 13.6654 9.575 13.6417L13.3333 11.5583V14.5833ZM9.16667 11.9667L3.38333 8.74998L9.16667 5.53332L14.95 8.74998L9.16667 11.9667Z" />
  </svg>
);
export default GraduationCapSVG;
