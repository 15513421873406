import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, notification, Popover, Table } from "antd";
import { ColumnType } from "antd/es/table";
import programApi from "api/program";
import TSButton from "app/components/TSButton";
import { ThreeDot } from "app/icons/ThreeDot";
import { KEY_VALUES } from "common/constants";
import { IResponseGetProgram } from "interfaces/IProgram";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export interface ProgramListProps {
  dataSource: IResponseGetProgram[];
  isLoading: boolean;
  renderListData: () => Promise<void>;
}

const ProgramList = ({
  dataSource,
  isLoading,
  renderListData,
}: ProgramListProps) => {
  const { t } = useTranslation();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isShowAction, setIsShowAction] = useState(false);
  const [recordIdSelected, setRecordIdSelected] = useState("");
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const { confirm } = Modal;
  const handleOpenPopover = (newOpen: boolean) => {
    setIsOpenPopover(newOpen);
  };
  const navigate = useNavigate();
  const handleDeleteCompany = async (_id: string) => {
    try {
      await programApi.deleteProgram(_id);
      notification.success({
        message: "Success",
        description: "Delete Program Successfully"!,
      });
    } catch (err) {
      notification.error({
        message: "Failed",
        description: "Delete Program Failed"!,
      });
    }
    renderListData();
    setIsLoadingDelete(false);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: t("Delete the company"),
      icon: <ExclamationCircleFilled />,
      content: t("Are you sure to delete this program"),
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk() {
        setIsLoadingDelete(true);
        handleDeleteCompany(recordIdSelected);
      },
    });
  };

  const companyIdLocal = localStorage.getItem(KEY_VALUES.COMPANY_ID) ?? "";
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>(
    [companyIdLocal] ?? []
  );
  useEffect(() => {
    if (selectedKeys.length) return;
    if (dataSource.length) {
      setSelectedKeys([dataSource[0]?.id]);
    }
  }, [dataSource, selectedKeys]);

  const columns: ColumnType<IResponseGetProgram>[] = [
    {
      title: t("Programs").toUpperCase(),
      dataIndex: "name",
      key: "name",
      sorter: (a: IResponseGetProgram, b: IResponseGetProgram) =>
        a.name.localeCompare(b.name),
      width: "40%",
      render: (text: any, record: IResponseGetProgram) => {
        return (
          <Link className="text-grey5" to={record.id}>
            <div className="w-full inline-flex justify-between items-center relative">
              <div className="inline-flex items-center">
                <div
                  className="w-2 h-2 rounded mr-2 absolute"
                  style={{
                    backgroundColor: "black",
                  }}
                ></div>
                <span className="ml-4">{text}</span>
              </div>
            </div>
          </Link>
        );
      },
    },
    {
      title: t("Days").toUpperCase(),
      dataIndex: "number_of_days",
      key: "days",
      sorter: (a: IResponseGetProgram, b: IResponseGetProgram) =>
        a.number_of_days - b.number_of_days,
      render: (text: any, record: IResponseGetProgram) => {
        return (
          <div className="w-full inline-flex justify-between items-center">
            <div className="inline-flex items-center">
              <span>{text}</span>
            </div>
            {isShowAction && recordIdSelected === record.id && (
              <Popover
                content={
                  <div className="inline-flex w-full justify-between">
                    <TSButton
                      onClick={() =>
                        navigate(record.id, { state: { isEdit: true } })
                      }
                      className="w-[45%]"
                    >
                      {t("Edit")}
                    </TSButton>
                    <TSButton className="w-[45%]" onClick={showDeleteConfirm}>
                      {t("Delete")}
                    </TSButton>
                  </div>
                }
                title={t("Action")}
                trigger="hover"
                open={isOpenPopover}
                onOpenChange={handleOpenPopover}
              >
                <ThreeDot className="cursor-pointer" />
              </Popover>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        loading={isLoading || isLoadingDelete}
        onRow={(record) => ({
          onClick: () => {
            setSelectedKeys([record.id]);
          },
          onMouseEnter: () => {
            setRecordIdSelected(record.id);
            setIsShowAction(true);
          },
          onMouseLeave: () => {
            setRecordIdSelected("");
            setIsShowAction(false);
            setIsOpenPopover(false);
          },
        })}
        rowKey={(record) => record.id}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 12,
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export default ProgramList;
