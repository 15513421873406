import { Progress } from "antd";
import { IProgressLineProp } from "./ProgressLineComponent.d";
export default function ProgressLineComponent({
  title,
  total,
  current,
  color,
}: IProgressLineProp) {
  return (
    <div>
      <div className="flex justify-between font-semibold text-[12px] text-[#454545]">
        <p>{title}</p>
        <p>{current}</p>
      </div>
      <Progress
        percent={(current * 100) / total}
        showInfo={false}
        strokeColor={{ "0%": color ?? "#000000", "100%": color ?? "#000000" }}
        size={[250, 12]}
      />
    </div>
  );
}
