const AngleDoubleLeftSvg = () => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2599 18.6567C14.4768 18.8754 14.7349 19.049 15.0192 19.1675C15.3036 19.2859 15.6085 19.3469 15.9166 19.3469C16.2246 19.3469 16.5296 19.2859 16.8139 19.1675C17.0982 19.049 17.3563 18.8754 17.5732 18.6567L24.5732 11.6567C24.7919 11.4398 24.9655 11.1817 25.084 10.8974C25.2024 10.6131 25.2634 10.3081 25.2634 10.0001C25.2634 9.69203 25.2024 9.38705 25.084 9.10272C24.9655 8.81838 24.7919 8.56031 24.5732 8.34339L17.5732 1.3434C17.3563 1.12469 17.0982 0.951109 16.8139 0.832649C16.5296 0.71419 16.2246 0.653198 15.9166 0.653198C15.6085 0.653198 15.3036 0.71419 15.0192 0.832649C14.7349 0.951109 14.4768 1.12469 14.2599 1.3434C14.0412 1.56031 13.8676 1.81838 13.7492 2.10272C13.6307 2.38705 13.5697 2.69203 13.5697 3.00006C13.5697 3.30809 13.6307 3.61307 13.7492 3.89741C13.8676 4.18175 14.0412 4.43981 14.2599 4.65673L19.6266 10.0001L14.2599 15.3434C14.0412 15.5603 13.8676 15.8184 13.7492 16.1027C13.6307 16.3871 13.5697 16.692 13.5697 17.0001C13.5697 17.3081 13.6307 17.6131 13.7492 17.8974C13.8676 18.1817 14.0412 18.4398 14.2599 18.6567ZM6.79323 10.0001L1.33323 15.3434C0.893854 15.7828 0.647015 16.3787 0.647015 17.0001C0.647015 17.6214 0.893854 18.2174 1.33323 18.6567C1.77261 19.0961 2.36852 19.3429 2.98989 19.3429C3.61127 19.3429 4.20719 19.0961 4.64656 18.6567L11.6466 11.6567C11.8653 11.4398 12.0388 11.1817 12.1573 10.8974C12.2758 10.6131 12.3368 10.3081 12.3368 10.0001C12.3368 9.69203 12.2758 9.38705 12.1573 9.10272C12.0388 8.81838 11.8653 8.56031 11.6466 8.34339L4.64656 1.3434C4.42965 1.12469 4.17158 0.951109 3.88724 0.832649C3.60291 0.71419 3.29792 0.653198 2.98989 0.653198C2.68187 0.653198 2.37689 0.71419 2.09255 0.832649C1.80821 0.951109 1.55014 1.12469 1.33323 1.3434C1.11453 1.56031 0.940945 1.81838 0.822483 2.10272C0.704023 2.38705 0.643032 2.69203 0.643032 3.00006C0.643032 3.30809 0.704023 3.61307 0.822483 3.89741C0.940945 4.18175 1.11453 4.43981 1.33323 4.65673L6.79323 10.0001Z"
      fill="#F8F8F8"
    />
  </svg>
);
export default AngleDoubleLeftSvg;
