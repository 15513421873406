import { useQuery } from "@tanstack/react-query";
import coachApi from "api/coach";
import TSButton from "app/components/TSButton";
import TSTable from "app/components/TSTable/TSTable";
import { AxiosResponse } from "axios";
import useAsyncEffect from "hooks/useAsyncEffect";
import {
  IResponseGetSessionDetail,
  ParticipantInfo,
} from "interfaces/IMasterclass";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";

export interface ListParticipantProps {
  dataSource: ParticipantInfo[];
  isLoading: boolean;
  renderListData: () => Promise<void>;
  listAnalysis: (string | null)[];
}

interface ParticipantInfoWithStatus extends ParticipantInfo {
  status: number | null;
}

const ListParticipant = ({
  dataSource,
  renderListData,
  listAnalysis,
}: ListParticipantProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { classId, sessionIndex } = useParams();

  const [asyncDataSource, setAsyncDataSource] = useState<
    ParticipantInfoWithStatus[]
  >([]);

  const [isFetchingLoading, setIsFetchingLoading] = useState(false);

  const { data, isLoading } = useQuery<
    AxiosResponse<IResponseGetSessionDetail>
  >(["classId", classId, "sessionIndex", sessionIndex], () =>
    coachApi.getSessionDetail(classId as string, Number(sessionIndex))
  );

  useAsyncEffect(async () => {
    if (!(dataSource && dataSource.length)) return;

    try {
      setIsFetchingLoading(true);
      const analysisProgressArr: Array<AxiosResponse<number> | null> =
        await Promise.all(
          listAnalysis.map((id) => {
            if (!id) return Promise.resolve(null);
            return coachApi.getAnalysisProgress(id);
          })
        );

      const newDataSource = dataSource.map((item, index) => ({
        ...item,
        status: analysisProgressArr[index]
          ? analysisProgressArr[index]!.data
          : null,
      }));

      setAsyncDataSource(newDataSource);
    } catch (_) {
    } finally {
      setIsFetchingLoading(false);
    }
  }, [dataSource, listAnalysis]);

  const columns = [
    {
      title: t("names").toUpperCase(),
      dataIndex: "name",
      key: "name",
      width: "70%",
      sorter: (a: ParticipantInfoWithStatus, b: ParticipantInfoWithStatus) =>
        [a.first_name, a.last_name]
          .filter(Boolean)
          .join(" ")
          .localeCompare([b.first_name, b.last_name].filter(Boolean).join(" ")),
      render: (_: any, record: ParticipantInfoWithStatus) => {
        return (
          <div className="w-full inline-flex justify-between items-center relative">
            <div className="inline-flex items-center">
              <span>
                {[record.first_name, record.last_name]
                  .filter(Boolean)
                  .join(" ")}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      render: (_: any, record: ParticipantInfoWithStatus) => {
        return (
          <div
            className={`flex items-center justify-end ${
              record.status === null ? "hover-active" : ""
            }`}
          >
            {record.status === 1 && (
              <TSButton
                className="text-sm font-medium flex items-center black-btn"
                icon={
                  <img
                    src="/images/chart-pie.svg"
                    alt="Voir analyse"
                    width={24}
                    height={24}
                  />
                }
                onClick={() => {
                  navigate({
                    pathname: "./analysis",
                    search: createSearchParams({
                      participantId: record.id,
                    }).toString(),
                  });
                }}
              >
                {t("Voir analyse")}
              </TSButton>
            )}

            {record.status !== null && Number(record.status) < 1 && (
              <TSButton
                type="text"
                className="text-sm font-medium flex items-center select-none pointer-events-none cursor-default invert-color-btn"
                icon={
                  <img
                    src="/images/spinner.gif"
                    alt="Analyse en cours..."
                    width={25}
                    height={25}
                  />
                }
              >
                {t("Analyse en cours...")}
              </TSButton>
            )}
            {record.status === null && (
              <img
                src="/images/chevron-right.svg"
                alt="chevron-right"
                width={9.5}
                height={16.5}
              />
            )}
          </div>
        );
      },
    },
  ];

  //TODO: update later
  const onClickOnRowTable = (record: ParticipantInfoWithStatus) => {
    if (record.status !== null) return;

    navigate(
      data?.data.participants
        .findIndex((item) => item.id === record.id)
        .toString()!
    );
  };

  return (
    <TSTable
      className="invert-color-table"
      sticky
      scroll={{ y: "calc(100vh - 240px)" }}
      isLoading={isLoading || isFetchingLoading}
      dataSource={asyncDataSource}
      columns={columns}
      onClickRow={onClickOnRowTable}
      defaultPageSize={10}
    />
  );
};

export default ListParticipant;
