import companyApi from "api/company";
import TSHeader from "app/components/TSHeader";
import { BuildingIcon } from "app/icons";
import { CONS_COLOR } from "common/constants";
import { filterDataSource } from "common/helper";
import CompanyContext, { CompanyContextType } from "context/CompanyContext";
import { IResponseGetCompany } from "interfaces";
import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListCompany from "./ListCompany";
import ModalAdd from "./ModalAdd";
import "./index.css";
import { useGetAccountInfoQuery } from "hooks/useGetAccountInfoQuery";

const Company = () => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [listCompany, setListCompany] = useState<IResponseGetCompany[]>([]);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IResponseGetCompany[]>([]);
  const [valueInput, setValueInput] = useState("");
  const companyContext = useContext(CompanyContext) as CompanyContextType;
  const [companyIdDefault, setCompanyIdDefault] = useState(companyContext?.companySelected?.id);
  const { isAdmin } = useGetAccountInfoQuery();
  const getCompanies = useCallback(async () => {
    return companyApi
      .getCompanies()
      .then(({ data }) => {
        setListCompany(data);
        setIsLoading(false);
        const findCompanyInLocal = data.some((item) => item.id === companyIdDefault);
        if (!findCompanyInLocal) setCompanyIdDefault(data[0].id);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [companyIdDefault]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        await getCompanies();
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchCompanies();
  }, [getCompanies]);

  useEffect(() => {
    setCompanyIdDefault(companyContext?.companySelected?.id);
  }, [companyContext?.companySelected?.id]);

  useEffect(() => {
    setDataSource([...listCompany]);
  }, [listCompany]);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const filterCompany = (e: ChangeEvent<HTMLInputElement>) => {
    const valueInput = e.target.value;
    setValueInput(valueInput);
    if (!valueInput) return setIsFilter(false);
    setIsFilter(true);
  };

  const listFilter = [
    {
      label: t("Companies"),
      icon: <BuildingIcon className="white-red align-text-middle" style={{ color: CONS_COLOR.TEXT_BLACK }} />,
      filterMethod: filterCompany,
    },
  ];

  return (
    <div className="relative h-full">
      <TSHeader
        titleHeader={t("Companies")}
        textLabelInput={isAdmin ? t("New Company") : ""}
        listFilter={listFilter}
        modalForm={<ModalAdd renderListData={getCompanies} isOpen={isOpenModal} handleCloseModal={handleCloseModal} />}
        handleOpenModal={handleOpenModal}
      >
        {t("Reports")}
      </TSHeader>
      <ListCompany
        listCompany={listCompany}
        dataSource={isFilter ? filterDataSource<IResponseGetCompany>(dataSource, valueInput) : dataSource}
        isLoading={isLoading}
        renderListData={getCompanies}
        companyIdDefault={companyIdDefault}
      />
    </div>
  );
};

export default Company;
