import { Button } from "antd";
import type { ChartData } from "chart.js";
import "chart.js/auto";
import useWindowSize from "hooks/WindowResize";
import React, { MouseEvent, useEffect, useLayoutEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { cn } from "utils/cn";
import {
  ChartColorLineHex,
  DAYS_OF_MONTH,
  DAYS_OF_WEEK,
  LabelButton,
} from "./report.constant";
import { convertScoresLength, getListDays } from "./report.handler";
import { IResponseGetSummaryReport } from "./type";

// config chart
export const options = {
  responsive: true,
  elements: {
    line: {
      tension: 0.2,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};
export function getRandomHexColor(
  value: string,
  listColor: { [key: string]: string }
) {
  let colorAvailable = listColor[value];
  if (colorAvailable) return colorAvailable;

  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
function createColorForMissingField(
  listData: IResponseGetSummaryReport,
  listColor: { [key: string]: string }
) {
  for (const [key] of Object.entries(listData.scores)) {
    if (!listColor[key]) {
      listColor[key] = getRandomHexColor(key, listColor);
    }
  }
  return listColor;
}

function ReportChart({ chartData }: { chartData: IResponseGetSummaryReport }) {
  const [labels, setLabels] = useState(getListDays(7));
  const [labelSelected, setLabelSelected] = useState(LabelButton.WEEK);
  const [dataDefault, setDataDefault] = useState<ChartData<"line">>({
    labels,
    datasets: [],
  });

  const [width, height] = useWindowSize();
  const [heightCtnChartReport, setHeightCtnChartReport] = useState(600);
  const [data, setData] = useState<ChartData<"line">>(dataDefault);
  const [colorList] = useState<{ [key: string]: string }>(ChartColorLineHex);
  const [lengthDataset] = useState<number>(chartData.scores.body?.length ?? 0);
  const [currentLengthDataset, setCurrentLengthDataset] =
    useState(DAYS_OF_WEEK);
  const [keysScoreList] = useState<string[]>(Object.keys(chartData?.scores));

  // get data from api convert data for chart
  useEffect(() => {
    convertChartDataToDataSet(chartData);
  }, [chartData]);

  useEffect(() => {
    setData({ ...dataDefault, labels });
    createColorForMissingField(chartData, colorList);
  }, [dataDefault]);

  useEffect(() => {
    if (data.datasets.length === 1) {
      let colorGradient = (context: { chart: { ctx: any } }) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, data.datasets[0].borderColor);
        gradient.addColorStop(1, "rgba(255, 255,  255, 0)");
        return gradient;
      };

      data.datasets[0].backgroundColor = colorGradient;
    }
  }, [data.datasets.length]);

  useEffect(() => {
    const newScores = convertScoresLength(
      chartData.scores,
      currentLengthDataset
    );
    const newChartData = JSON.parse(JSON.stringify(chartData));
    newChartData.scores = newScores;
    convertChartDataToDataSet(newChartData, labels);
  }, [currentLengthDataset, labelSelected, labels]);

  useEffect(() => {
    setTimeout(() => {
      resetLabelButtons();
    }, 0);
    resetLabelButtons();
  }, [labelSelected]);
  function convertChartDataToDataSet(
    chartData: IResponseGetSummaryReport,
    labelList: string[] = []
  ) {
    let newDataset: any = [];

    for (const [key, value] of Object.entries(chartData.scores)) {
      newDataset.push({
        id: key,
        fill: true,
        label: key,
        data: value,
        borderColor: getRandomHexColor(key, ChartColorLineHex),
        backgroundColor: "#FF000000",
      });
    }
    setDataDefault({
      ...data,
      datasets: newDataset,
    });
    if (labelList) {
      setDataDefault({
        ...data,
        datasets: newDataset,
        labels: labelList,
      });
    }
  }
  function handleOnclickLabel(label: string) {
    setLabelSelected(label);

    switch (label) {
      case LabelButton.WEEK:
        if (lengthDataset < 7) {
          setLabels(getListDays(lengthDataset));
          setCurrentLengthDataset(lengthDataset);
          break;
        }
        setLabels(getListDays(7));
        setCurrentLengthDataset(DAYS_OF_WEEK);

        break;
      case LabelButton.MONTH:
        setCurrentLengthDataset(DAYS_OF_MONTH);
        if (lengthDataset < 30) {
          setLabels(getListDays(lengthDataset));
          setCurrentLengthDataset(lengthDataset);

          break;
        }
        setLabels(getListDays(30));
        setCurrentLengthDataset(DAYS_OF_MONTH);

        break;
      case LabelButton.TOTAL_DURATION:
        setCurrentLengthDataset(lengthDataset);
        setLabels(getListDays(lengthDataset));
        break;
      default:
        break;
    }
  }

  const handleClickLabel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let isActive = event.currentTarget.classList.contains("!bg-[#D9D9D9]");
    if (isActive) {
      event.currentTarget.classList.remove("!bg-[#D9D9D9]");
      event.currentTarget.classList.remove("!text-[#454545]");
    } else {
      event.currentTarget.classList.add("!bg-[#D9D9D9]");
      event.currentTarget.classList.add("!text-[#454545]");
    }
    const { value } = event.currentTarget;
    if (!value) return;

    const dataSelected = data.datasets.find(
      (dataset) => dataset.label === value
    );
    // have data selected => remove
    if (dataSelected) {
      const newData = data.datasets.filter(
        (dataset) => dataset.label !== value
      );
      addGradient({
        ...data,
        datasets: newData,
      });

      setData({
        ...data,
        datasets: newData,
      });
      return;
    }

    // not have data selected => add
    if (!dataSelected) {
      const retrieveData = dataDefault.datasets.find(
        (dataset) => dataset.label === value
      );
      if (!retrieveData) return;
      addGradient({
        ...data,
        datasets: [...data.datasets, retrieveData],
      });
      setData({
        ...data,
        datasets: [...data.datasets, retrieveData],
      });
      return;
    }
  };
  function addGradient(data: ChartData<"line">) {
    if (data.datasets.length === 0) return;
    if (data.datasets.length === 1) {
      data.datasets[0].backgroundColor = (context: { chart: { ctx: any } }) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, data.datasets[0].borderColor);
        gradient.addColorStop(1, "rgba(255, 255,  255, 0)");
        return gradient;
      };
      return;
    }
    if (data.datasets.length > 1) {
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i]["backgroundColor"] = "#FF000000";
      }
    }
  }
  function resetLabelButtons() {
    const elementButton = document.getElementsByClassName("!bg-[#D9D9D9]");
    const elementButton2 = document.getElementsByClassName("!text-[#454545]");

    if (elementButton2.length > 0) {
      for (let i = 0; i < elementButton.length; i++) {
        elementButton[i].classList.remove("!text-[#454545]");
      }
    }
    if (elementButton.length > 0) {
      for (let i = 0; i < elementButton.length; i++) {
        elementButton[i].classList.remove("!bg-[#D9D9D9]");
      }
    }
  }

  function renderButtons() {
    return keysScoreList.map((keyScore: any) => {
      return (
        <Button
          key={keyScore}
          style={{
            backgroundColor: `${getRandomHexColor(
              keyScore,
              ChartColorLineHex
            )}`,
          }}
          className={cn(
            `text-xs font-medium !px-8 text-[#fff] capitalize  bg-[#DB708A]`
          )}
          shape="round"
          type="default"
          size="middle"
          value={keyScore}
          onClick={
            handleClickLabel as unknown as React.MouseEventHandler<HTMLAnchorElement> &
              React.MouseEventHandler<HTMLButtonElement>
          }
        >
          {keyScore}
        </Button>
      );
    });
  }

  useLayoutEffect(() => {
    const reportChart = document.getElementById("report-chart");
    if (reportChart) {
      setHeightCtnChartReport(reportChart.clientWidth / 2);
    }
  }, [width, height]);

  return (
    <div className="m-6">
      <div className="flex row gap-3 mb-6">
        <Button
          className={cn("text-xs bg-[#D9D9D9] font-medium !px-8", {
            "bg-[#0F1827] text-[#FFFFFF] hover:!text-[#FFFFFF]":
              labelSelected === LabelButton.WEEK,
          })}
          shape="round"
          type="default"
          size="middle"
          onClick={() => handleOnclickLabel(LabelButton.WEEK)}
        >
          Week
        </Button>
        <Button
          className={cn("text-xs bg-[#D9D9D9] font-medium !px-8", {
            "bg-[#0F1827] text-[#FFFFFF] hover:!text-[#FFFFFF]":
              labelSelected === LabelButton.MONTH,
          })}
          shape="round"
          type="default"
          size="middle"
          onClick={() => handleOnclickLabel(LabelButton.MONTH)}
        >
          Month
        </Button>
        <Button
          className={cn("text-xs bg-[#D9D9D9] font-medium !px-8", {
            "bg-[#0F1827] text-[#FFFFFF] hover:!text-[#FFFFFF]":
              labelSelected === LabelButton.TOTAL_DURATION,
          })}
          shape="round"
          type="default"
          size="middle"
          onClick={() => handleOnclickLabel(LabelButton.TOTAL_DURATION)}
        >
          Total duration
        </Button>
      </div>
      <div
        className="mb-6"
        id="report-chart"
        style={{ height: `${heightCtnChartReport}px` }}
      >
        <Line className="bg-white" options={options} data={data} />
      </div>
      <div className="flex row justify-between flex-wrap">
        {renderButtons()}
      </div>
    </div>
  );
}
export default ReportChart;
