import { axiosClient } from "./apiClient";
import { IReportQuery } from "interfaces/IReport";

const reportsApi = {
  getSelfEvaluation({
    classIDs,
    participantIDs,
  }: IReportQuery) {
    const url = "/reports/self-evaluation";
    return axiosClient.get(url, { params: { class_ids: classIDs, participant_ids: participantIDs }, paramsSerializer: { indexes: null, }});
  },
  getSummary({
    classIDs,
    participantIDs,
  }: IReportQuery) {
    const url = "/reports/summary";
    return axiosClient.get(url, { params: { class_ids: classIDs, participant_ids: participantIDs }, paramsSerializer: { indexes: null, }});
  },
};

export default reportsApi;
