import participantApi from "api/participant";
import TSHeader from "app/components/TSHeader";
import { GraduationCap } from "app/icons";
import { CONS_COLOR, KEY_VALUES } from "common/constants";
import { getUser } from "common/helper";
import { IResponseGetParticipants } from "interfaces";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListParticipant from "./ListParticipant";
import ModalAdd from "./ModalAdd";
import "./index.css";

export default function Participant() {
  const { t } = useTranslation();
  const user = getUser()?.user ?? "";
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [listParticipant, setListParticipant] = useState<
    IResponseGetParticipants[]
  >([]);
  const [dataSource, setDataSource] = useState<IResponseGetParticipants[]>([]);
  const [valueInput, setValueInput] = useState("");

  const getParticipants = useCallback(async () => {
    const companyId = localStorage.getItem(KEY_VALUES.COMPANY_ID) ?? "";
    return participantApi
      .getListParticipant(companyId)
      .then(({ data }: { data: IResponseGetParticipants[] }) => {
        const filterDataSource = data.filter((item) => item.email !== user);
        setListParticipant(filterDataSource);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [user]);
  useEffect(() => {
    getParticipants();
  }, [getParticipants]);
  useEffect(() => {
    setDataSource([...listParticipant]);
  }, [listParticipant]);
  const filterNameClass = (e: ChangeEvent<HTMLInputElement>) => {
    const valueInput = e.target.value;
    setValueInput(valueInput);
  };
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  const listFilter = [
    {
      label: t("Class"),
      icon: (
        <GraduationCap
          className="white-red align-text-middle"
          style={{ color: CONS_COLOR.TEXT_BLACK, verticalAlign: "text-bottom" }}
        />
      ),
      filterMethod: filterNameClass,
    },
  ];

  const filterDataSource = () => {
    const dataFilter = listParticipant.filter((module) => {
      let moduleExistByCategory = false;
      const { classes } = module;
      classes?.forEach(({ name }) => {
        if (name.toLocaleLowerCase().includes(valueInput.toLocaleLowerCase()))
          moduleExistByCategory = true;
      });
      const checkFilterModuleByCategory = () => {
        if (!valueInput) return false;
        return moduleExistByCategory;
      };
      return checkFilterModuleByCategory();
    });
    return dataFilter;
  };
  return (
    <div className="relative h-full">
      <TSHeader
        titleHeader={t("Participants")}
        textLabelInput={t("New User")}
        listFilter={listFilter}
        modalForm={
          <ModalAdd
            renderListData={getParticipants}
            isOpen={isOpenModal}
            handleCloseModal={handleCloseModal}
          />
        }
        handleOpenModal={handleOpenModal}
      >
        {t("Reports")}
      </TSHeader>
      <ListParticipant
        dataSource={valueInput ? filterDataSource() : dataSource}
        isLoading={isLoading}
        renderListData={getParticipants}
      />
    </div>
  );
}
