import classNames from "classnames";
import { IModuleItem } from "interfaces/IProgram";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import ModuleItem from "./ModuleItem";

export interface ModuleItemProps {
  dayModules: IModuleItem[];
  className?: string;
  index: number;
  onMoveModule: (
    dragIndex: number,
    hoverIndex: number,
    columnIndex: number
  ) => void;
  onDeleteModule?: (columnIndex: number, itemIndex: number) => void;
  onDrop: (index: any, item: any) => void;
  canDrag: boolean;
}

function DayColumn({
  dayModules,
  index,
  onDrop,
  onMoveModule,
  onDeleteModule,
  canDrag,
}: ModuleItemProps) {
  const { t } = useTranslation();

  const [, drop] = useDrop(() => ({
    accept: "Box",
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div
      ref={drop}
      className={classNames(
        "border-grey2 border-0 border-r-[1px] border-solid ",
        index === 0 ? "min-w-[196px]" : "min-w-[184px]"
      )}
    >
      <div className="bg-greyBg border-0 border-b-[1px] border-solid border-grey2">
        <p className="py-5 text-center text-grey4 text-[14px] leading-[20px] font-bold">
          {t(`Day ${index + 1}`)}
        </p>
      </div>
      <div
        className={classNames(
          "overflow-y-auto max-h-[calc(100vh-177px)]",
          index === 0 ? "pl-6 pr-3" : "px-3",
          "pt-3 pb-[100px]"
        )}
      >
        {dayModules.map((module, secondIndex) => {
          return (
            <div key={`${module?.id} ${secondIndex}`} className="mb-2">
              <ModuleItem
                module={module}
                itemIndex={secondIndex}
                columnIndex={index}
                className="w-[148px] px-4 pb-[11px] pt-[11px] h-[106px]"
                durationClassName="bottom-[10px] right-[12px]"
                moduleNameClassName="line-clamp-4"
                onMoveModule={onMoveModule}
                onDeleteModule={onDeleteModule}
                canDrag={canDrag}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DayColumn;
