const VolumeOnSVG = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3571 3.45056C12.3068 2.96422 12.2543 2.45963 12.1254 1.95611C11.7741 0.75153 10.801 9.59513e-06 9.75762 9.59513e-06C9.17565 -0.00213761 8.43954 0.356445 8.0222 0.719322L4.56287 3.61697H2.75194C1.41918 3.61697 0.347511 4.6444 0.145131 6.12705C-0.0268386 7.55064 -0.0687825 10.2379 0.145131 11.8042C0.330733 13.3706 1.35416 14.383 2.75194 14.383H4.56287L8.08931 17.3236C8.45107 17.6382 9.08967 17.9989 9.67688 17.9989C9.71463 18 9.74819 18 9.78174 18C10.845 18 11.7814 17.2206 12.1327 16.0192C12.265 15.5113 12.3114 15.0352 12.3563 14.5752L12.3571 14.5666L12.4043 14.1082C12.5846 12.6213 12.5846 5.36908 12.4043 3.89288L12.3571 3.45056ZM16.4071 3.49489C16.1188 3.06974 15.5473 2.96345 15.1289 3.25869C14.7147 3.55608 14.6119 4.14227 14.9003 4.56634C15.7025 5.74837 16.1439 7.32227 16.1439 9.00031C16.1439 10.6773 15.7025 12.2522 14.9003 13.4343C14.6119 13.8583 14.7147 14.4445 15.1299 14.7419C15.2851 14.8514 15.4665 14.9094 15.6532 14.9094C15.9541 14.9094 16.2352 14.758 16.4071 14.5057C17.4201 13.0134 17.979 11.0584 17.979 9.00031C17.979 6.94222 17.4201 4.98719 16.4071 3.49489ZM18.2943 0.221485C18.7117 -0.0748286 19.2852 0.0336051 19.5715 0.457677C21.1402 2.76699 22.0042 5.80206 22.0042 9.00031C22.0042 12.2007 21.1402 15.2347 19.5715 17.544C19.4006 17.7963 19.1185 17.9477 18.8176 17.9477C18.6309 17.9477 18.4506 17.8897 18.2954 17.7802C17.8801 17.4828 17.7774 16.8977 18.0647 16.4726C19.4216 14.4735 20.1692 11.8196 20.1692 9.00031C20.1692 6.18211 19.4216 3.52817 18.0647 1.52913C17.7774 1.10506 17.8801 0.518872 18.2943 0.221485Z"
      fill="white"
    />
  </svg>
);
export default VolumeOnSVG;
