import Layout from "./Layout";
import Menu from "./Menu";
import Pagination from "./Pagination";
import Table from "./Table";

const components = {
  ...Layout,
  ...Menu,
  ...Table,
  ...Pagination,
};

export default components;
