import companyApi from "api/company";
import { KEY_VALUES } from "common/constants";
import { IResponseGetInfoCompany } from "interfaces";
import { createContext, useEffect, useMemo, useState } from "react";
// Type
export type CompanyContextType = {
  companySelected: IResponseGetInfoCompany;
  selectCompany: (company: IResponseGetInfoCompany) => void;
};
// Initiate Context
const CompanyContext = createContext<CompanyContextType | null>(null);
// Provide Context
export const CompanyProvider = ({ children }: any) => {
  const [companySelected, selectCompany] = useState<IResponseGetInfoCompany>({
    id: localStorage.getItem(KEY_VALUES.COMPANY_ID) ?? "",
    name: "",
  });

  useEffect(() => {
    const companyId = localStorage.getItem("companyId") ?? "";
    if (companyId)
      companyApi
        .getInfoCompany(companyId)
        .then((data) => selectCompany(data.data ?? { ...companySelected }));
  }, []);

  const companyProviderValue = useMemo(
    () => ({ companySelected, selectCompany }),
    [companySelected, selectCompany]
  );

  return (
    <CompanyContext.Provider value={companyProviderValue}>
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyContext;
