import { axiosClient } from "./apiClient";

const moduleApi = {
  getModules() {
    const url = "/modules/list";
    return axiosClient.get(url);
  },
  getModulesByCompany(companyId: string) {
    const url = "/modules/list";
    return axiosClient.get(url, {
      params: {
        company_id: companyId,
      },
    });
  },
  deleteModule(moduleId: string) {
    const url = `/modules/${moduleId}`;
    return axiosClient.delete(url);
  },
};

export default moduleApi;
