import { notification } from "antd";
import companyApi from "api/company";
import PopoverActionButton from "app/components/PopoverActionButton/PopoverActionButton";
import NotificationErrorBtn from "app/components/TSNotification/NotificationErrorBtn";
import TSTable from "app/components/TSTable/TSTable";
import { KEY_VALUES, NAME_FEATURE, TYPE_NOTIFICATION } from "common/constants";
import { notificationSuccess } from "common/helper";
import CompanyContext, { CompanyContextType } from "context/CompanyContext";
import { IResponseGetCompany } from "interfaces";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IListCompany } from "./ListCompany.d";
import ModalEdit from "./ModalEdit";
import { KEY_VALUE, TITLE_COLUMN } from "./constant";

const ListCompany = ({
  listCompany,
  dataSource,
  isLoading,
  renderListData,
  companyIdDefault,
}: IListCompany) => {
  const { t } = useTranslation();
  const companyContext = useContext(CompanyContext) as CompanyContextType;
  const [recordSelected, setRecordSelected] = useState<IResponseGetCompany>({
    id: "",
    name: "",
    owner: "",
  });
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isShowAction, setIsShowAction] = useState(false);
  const [recordIdSelected, setRecordIdSelected] = useState("");
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleOpenPopover = (newOpen: boolean) => {
    setIsOpenPopover(newOpen);
  };
  const handleDeleteCompany = async (id: string) => {
    try {
      await companyApi.deleteCompany(id);
      renderListData();
      setIsLoadingDelete(false);
      notificationSuccess(TYPE_NOTIFICATION.DELETE, NAME_FEATURE.COMPANY);
    } catch (err: any) {
      const { data } = err.response;
      notification.error({
        message: "Failed",
        description: data.message,
        btn: (
          <NotificationErrorBtn
            renderListData={renderListData}
            notification={notification}
          />
        ),
      });
      setIsLoadingDelete(false);
    }
  };

  const handleOkDeleteModal = () => {
    setIsLoadingDelete(true);
    handleDeleteCompany(recordIdSelected);
    setSelectedKeys([companyIdDefault]);
  };

  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([
    companyIdDefault,
  ]);
  // Set default company info when loading page
  useEffect(() => {
    if (listCompany.length)
      companyContext?.selectCompany(
        listCompany.find((data) => data.id === companyIdDefault) ??
          listCompany[0]
      );
  }, [listCompany]);

  useEffect(() => {
    setSelectedKeys([companyIdDefault]);
  }, [companyIdDefault]);

  useEffect(() => {
    if (selectedKeys.length) return;
    if (dataSource.length) {
      setSelectedKeys([dataSource[0]?.id]);
    }
  }, [dataSource, selectedKeys]);

  // Update state company selected in local and React context to using
  useEffect(() => {
    localStorage.setItem(KEY_VALUES.COMPANY_ID, selectedKeys.toString());
    if (listCompany.length)
      companyContext?.selectCompany(
        dataSource.find((data) => data.id === selectedKeys.toString()) ??
          listCompany[0]
      );
  }, [selectedKeys]);

  const handleEditBtn = () => {
    setShowModalEdit(!showModalEdit);
  };

  const handleCancelEdit = () => {
    setShowModalEdit(false);
  };
  const columns = [
    {
      title: t(TITLE_COLUMN.COMPANY).toUpperCase(),
      dataIndex: KEY_VALUE.NAME_COMPANY,
      key: KEY_VALUE.NAME_COMPANY,
      sorter: (a: IResponseGetCompany, b: IResponseGetCompany) =>
        a.name.localeCompare(b.name),
    },
    {
      title: t(TITLE_COLUMN.CONTACT).toUpperCase(),
      dataIndex: KEY_VALUE.NAME_OWNER,
      key: KEY_VALUE.NAME_OWNER,
      sorter: (a: IResponseGetCompany, b: IResponseGetCompany) =>
        a.owner.localeCompare(b.owner),
      render: (text: string, record: IResponseGetCompany) => {
        return (
          <div className="w-full inline-flex justify-between items-center">
            <div className="inline-flex items-center">
              <span>{text}</span>
            </div>
            <PopoverActionButton
              nameModule="company"
              handleOkDelete={handleOkDeleteModal}
              isRecordSelected={isShowAction && recordIdSelected === record.id}
              isOpenPopover={isOpenPopover}
              handleOpenPopover={handleOpenPopover}
              handleEditBtn={handleEditBtn}
            />
          </div>
        );
      },
    },
  ];

  const onClickOnRowTable = (record: IResponseGetCompany) => {
    setRecordSelected(record);
  };

  const onMouseEnterOnRowTable = (record: IResponseGetCompany) => {
    setRecordIdSelected(record.id);
    setIsShowAction(true);
  };
  const onMouseLeaveOnRowTable = () => {
    setRecordIdSelected("");
    setIsShowAction(false);
    setIsOpenPopover(false);
  };

  return (
    <Fragment>
      <TSTable
        isLoading={isLoading || isLoadingDelete}
        dataSource={dataSource}
        columns={columns}
        onClickRow={onClickOnRowTable}
        onMouseEnterRow={onMouseEnterOnRowTable}
        onMouseLeaveRow={onMouseLeaveOnRowTable}
        rowSelection={{
          type: "radio",
          columnTitle: "SELECT",
          selectedRowKeys: selectedKeys,
          onChange: (selectedRowKeys: React.Key[]) => {
            // Replace element selectedKey if have new element in array selectedRowKeys
            const listSelectedKey = selectedRowKeys.filter((select) => {
              return !selectedKeys.includes(select);
            });
            // In case you can not unchecked the checkbox checked
            if (!listSelectedKey.length)
              return setSelectedKeys([...selectedKeys]);
            setSelectedKeys([...listSelectedKey]);
          },
          getCheckboxProps: (record: IResponseGetCompany) => ({
            name: record.name,
          }),
        }}
      />
      <ModalEdit
        recordSelected={recordSelected}
        renderListData={renderListData}
        handleCloseModal={handleCancelEdit}
        isOpen={showModalEdit}
      />
    </Fragment>
  );
};

export default ListCompany;
