import { LOGIN_PARAM } from "app/containers/Auth/Login/constant";
import { FailureCallback, SuccessCallback } from "types";
import { LOGIN_RESPONSE_DATA } from "./../app/containers/Auth/Login/constant";
import { axiosClient } from "./apiClient";

const authService = {
  isAuthenticated: false,
  async signin(
    param: FormData,
    success: SuccessCallback,
    failure: FailureCallback
  ) {
    try {
      const { data } = await axiosClient.post("/auth/login", param, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      localStorage.setItem(
        "user",
        JSON.stringify({
          username: param.get(LOGIN_PARAM.USERNAME),
          token: data[LOGIN_RESPONSE_DATA.ACCESS_TOKEN],
        })
      );
      authService.isAuthenticated = true;

      success(data);
    } catch (error) {
      console.log(error);
      failure(error);
    }
  },
  signout(callback: VoidFunction) {
    authService.isAuthenticated = false;
    callback();
  },
};

export { authService };
