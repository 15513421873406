const PlaySVG = () => (
  <svg
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.3672 25.5001L12.044 42.8236L12.044 8.17654L41.3672 25.5001Z"
      fill="#F8F8F8"
    />
    <path
      d="M38.3809 19.3623L18.6185 8.02845C17.5491 7.41094 16.3353 7.08745 15.1005 7.09085C13.8656 7.09424 12.6537 7.42439 11.5877 8.04776C10.5217 8.67114 9.63974 9.56553 9.0313 10.6401C8.42286 11.7147 8.10965 12.9311 8.12349 14.1659V36.9153C8.12349 38.771 8.86063 40.5506 10.1728 41.8627C11.4849 43.1748 13.2645 43.912 15.1201 43.912C16.3485 43.9099 17.5548 43.5854 18.6185 42.9709L38.3809 31.6371C39.4428 31.0225 40.3244 30.1395 40.9373 29.0767C41.5502 28.0138 41.8729 26.8084 41.8729 25.5815C41.8729 24.3546 41.5502 23.1492 40.9373 22.0864C40.3244 21.0235 39.4428 20.1405 38.3809 19.5259V19.3623ZM36.3351 27.9342L16.5727 39.4317C16.1297 39.6827 15.6293 39.8147 15.1201 39.8147C14.611 39.8147 14.1106 39.6827 13.6676 39.4317C13.2259 39.1767 12.8591 38.8098 12.6042 38.3681C12.3492 37.9264 12.215 37.4253 12.2151 36.9153V14.0841C12.215 13.574 12.3492 13.073 12.6042 12.6312C12.8591 12.1895 13.2259 11.8227 13.6676 11.5677C14.1124 11.3205 14.6113 11.187 15.1201 11.179C15.6286 11.1894 16.127 11.3228 16.5727 11.5677L36.3351 22.9833C36.777 23.2382 37.1439 23.605 37.3991 24.0467C37.6542 24.4885 37.7885 24.9896 37.7885 25.4997C37.7885 26.0098 37.6542 26.5109 37.3991 26.9526C37.1439 27.3944 36.777 27.7611 36.3351 28.016V27.9342Z"
      fill="#F8F8F8"
    />
  </svg>
);
export default PlaySVG;
