import { CheckOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row } from "antd";
import categoryApi from "api/category";
import TSButton from "app/components/TSButton";
import TSModal from "app/components/TSModal/TSModal";
import {
  NAME_FEATURE,
  RULE_FORM_ITEM_NAME,
  TYPE_NOTIFICATION,
} from "common/constants";
import { LIGHT_GRAY } from "common/constants/color.constant";
import { notificationError, notificationSuccess } from "common/helper";
import { IModalAction } from "interfaces";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IFormValueModalAdd } from "./ModalAdd.d";
import { listColor } from "./constant";

const ModalAdd = ({
  renderListData,
  handleCloseModal,
  isOpen,
}: IModalAction) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [colorSelected, setColorSelected] = useState<string>(
    listColor[0].color
  );
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setConfirmLoading(false);
    handleCloseModal();
    setColorSelected(listColor[0].color);
  };
  const onFinish = async (values: IFormValueModalAdd) => {
    const { nameCategory } = values;
    setConfirmLoading(!confirmLoading);
    try {
      await categoryApi.newCategory({
        name: nameCategory.trim(),
        color: colorSelected,
      });
      handleCloseModal();
      setConfirmLoading(false);
      form.resetFields();
      renderListData();
      setColorSelected(listColor[0].color);
      notificationSuccess(TYPE_NOTIFICATION.CREATE, NAME_FEATURE.CATEGORY);
    } catch (err: any) {
      const { status } = err.response;
      notificationError(status as string);
      setConfirmLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: unknown) => {
    console.log("Failed:", errorInfo);
  };

  const checkActiveColor = (color: string) => colorSelected === color;

  const onClickColor = (color: string) => {
    setColorSelected(color);
  };

  return (
    <TSModal
      title={t("Create new category")}
      open={isOpen}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
      >
        <Form.Item
          className="mt-5"
          label={t("Name")}
          name={t("nameCategory")}
          required
          rules={RULE_FORM_ITEM_NAME(t("Category"))}
        >
          <Input
            className={`w-full bg-[${LIGHT_GRAY}]`}
            placeholder={t("Category name")}
          />
        </Form.Item>
        <Form.Item
          className="mt-3"
          label={t("Choose a color")}
          name={t("color")}
        >
          <Row gutter={[8, 8]}>
            {listColor.map(({ name, color }) => (
              <Col
                key={name}
                className={`gutter-row rounded-2xl p-0 mx-1 h-8 max-w-[32px] cursor-pointer ${
                  checkActiveColor(color)
                    ? "active flex items-center justify-center"
                    : ""
                }`}
                onClick={() => onClickColor(color)}
                style={{
                  backgroundColor: color,
                }}
                span={3}
              >
                {checkActiveColor(color) && (
                  <CheckOutlined style={{ color: "white" }} />
                )}
              </Col>
            ))}
          </Row>
        </Form.Item>
        <Form.Item className="mt-8 mb-2 py-2">
          <TSButton
            type="primary"
            htmlType="submit"
            loading={confirmLoading}
            className="px-8 h-10 w-full text-base"
          >
            {t("Create category")}
          </TSButton>
        </Form.Item>
      </Form>
    </TSModal>
  );
};

export default ModalAdd;
