import { notification } from "antd";
import moduleApi from "api/module";
import PopoverActionButton from "app/components/PopoverActionButton/PopoverActionButton";
import NotificationErrorBtn from "app/components/TSNotification/NotificationErrorBtn";
import TSTable from "app/components/TSTable/TSTable";
import { NAME_FEATURE, TYPE_NOTIFICATION } from "common/constants";
import { convertMinutesToHour, notificationSuccess } from "common/helper";
import { IResponseGetCategory } from "interfaces";
import { IResponseGetModule } from "interfaces/IModule";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ListModule.css";
import { IListModule } from "./ListModule.d";
import { KEY_VALUE, TITLE_COLUMN } from "./constant";

const ListModule = ({ dataSource, isLoading, renderListData }: IListModule) => {
  const { t } = useTranslation();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isShowAction, setIsShowAction] = useState(false);
  const [recordIdSelected, setRecordIdSelected] = useState("");
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const handleOpenPopover = (newOpen: boolean) => {
    setIsOpenPopover(newOpen);
  };
  const handleDeleteModule = async (id: string) => {
    try {
      await moduleApi.deleteModule(id);
      renderListData();
      setIsLoadingDelete(false);
      notificationSuccess(TYPE_NOTIFICATION.DELETE, NAME_FEATURE.MODULE);
    } catch (err: any) {
      const { data } = err.response;
      notification.error({
        message: "Failed",
        description: data.message,
        btn: (
          <NotificationErrorBtn
            renderListData={renderListData}
            notification={notification}
          />
        ),
      });
      setIsLoadingDelete(false);
    }
  };

  const handleOkDeleteModal = () => {
    setIsLoadingDelete(true);
    handleDeleteModule(recordIdSelected);
  };

  const columns = [
    {
      title: t(TITLE_COLUMN.MODULE).toUpperCase(),
      dataIndex: KEY_VALUE.NAME_MODULE,
      key: KEY_VALUE.NAME_MODULE,
      sorter: (a: IResponseGetModule, b: IResponseGetModule) =>
        a.name.localeCompare(b.name),
      render: (text: string) => {
        return (
          <div className="w-full inline-flex justify-between items-center relative">
            <div className="inline-flex items-center">
              <div
                className="w-2 h-2 rounded mr-2 absolute"
                style={{
                  backgroundColor: "black",
                }}
              ></div>
              <span className="ml-4">{text}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: t(TITLE_COLUMN.CATEGORY).toUpperCase(),
      dataIndex: KEY_VALUE.NAME_CATEGORY,
      render: (_: IResponseGetCategory[], record: IResponseGetModule) => {
        return (
          <div className="w-full inline-flex justify-between items-center">
            <div className="flex flex-wrap font-medium">
              {record.categories.map((category) => (
                <div key={category.id} className="vertical-line px-3">
                  <span style={{ color: category.color }}>{category.name}</span>
                </div>
              ))}
            </div>
          </div>
        );
      },
      key: KEY_VALUE.NAME_CATEGORY,
    },
    {
      title: t(TITLE_COLUMN.TIME).toUpperCase(),
      dataIndex: KEY_VALUE.TIME,
      key: KEY_VALUE.TIME,
      sorter: (a: IResponseGetModule, b: IResponseGetModule) =>
        a.duration_in_minutes - b.duration_in_minutes,
      render: (text: number, record: IResponseGetModule) => {
        return (
          <div className="w-full inline-flex justify-between items-center">
            <div className="inline-flex items-center">
              <span>{convertMinutesToHour(text)}</span>
            </div>
            <PopoverActionButton
              nameModule="module"
              handleOkDelete={handleOkDeleteModal}
              isRecordSelected={isShowAction && recordIdSelected === record.id}
              isOpenPopover={isOpenPopover}
              handleOpenPopover={handleOpenPopover}
            />
          </div>
        );
      },
    },
  ];

  const onMouseEnterOnRowTable = (record: IResponseGetModule) => {
    setRecordIdSelected(record.id);
    setIsShowAction(true);
  };
  const onMouseLeaveOnRowTable = () => {
    setRecordIdSelected("");
    setIsShowAction(false);
    setIsOpenPopover(false);
  };

  return (
    <TSTable
      isLoading={isLoading || isLoadingDelete}
      dataSource={dataSource}
      columns={columns}
      onMouseEnterRow={onMouseEnterOnRowTable}
      onMouseLeaveRow={onMouseLeaveOnRowTable}
      locale={{
        triggerDesc: t("Descending"),
        triggerAsc: t("Ascending"),
        cancelSort: t("Cancel"),
      }}
    />
  );
};

export default ListModule;
