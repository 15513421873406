import { XIcon } from "app/icons/XIcon";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import WaveSurfer from "wavesurfer.js";
import Timeline from "wavesurfer.js/dist/plugins/timeline.esm.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { waveSurferStyle } from "./wavesurfer.style";
import { onWaveProgress, useVideoContext } from "context/VideoContext";

interface IAudioScores {
  start: number;
  end: number;
  color: string;
  content: string;
}

interface VoiceFrequencyChartProps {
  audioScores: IAudioScores[];
  containerWidth: number;
  containerHeight: number;
}
const VoiceFrequencyChart = ({
  audioScores,
  containerWidth,
  containerHeight,
}: VoiceFrequencyChartProps) => {
  const minPxPerSec = containerWidth / 30;
  const canvasHeight = containerHeight - 80;

  const { dispatch } = useVideoContext();

  const waveformRef = useRef<HTMLDivElement | null>(null);
  const [, setWaveform] = useState<WaveSurfer | null>(null);
  const { t } = useTranslation();
  const regions = useMemo(() => RegionsPlugin.create(), [[]]);
  const timeline = useMemo(
    () =>
      Timeline.create({
        formatTimeCallback: (seconds: number) => `${seconds}s`,
        style: {
          overflow: "unset",
          height: 50,
        },
      }),
    []
  );

  useEffect(() => {
    const waveSufer = WaveSurfer.create({
      container: waveformRef.current as any,
      waveColor: "white",
      progressColor: "white",
      height: canvasHeight,
      cursorWidth: 2,
      cursorColor: "white",
      barWidth: 3,
      minPxPerSec,
      plugins: [timeline, regions],
      media: document.querySelector("video") as HTMLMediaElement,
      // url: stateR.videoUrl,
    });

    setWaveform(waveSufer);

    waveSufer.on("ready", () => {
      // This ensures the progress bar is initialized properly
      // updateProgressBar();

      const shadowRoot = (waveformRef.current?.firstChild as HTMLDivElement)
        .shadowRoot;
      if (shadowRoot) {
        const style = document.createElement("style");
        style.textContent = waveSurferStyle(canvasHeight);
        shadowRoot.insertBefore(style, shadowRoot.firstChild);
      }
    });

    // const updateProgressBar = () => {
    //   if (progressBarRef.current && waveSufer) {
    //     const duration = waveSufer.getDuration();
    //     const currentTime = waveSufer.getCurrentTime();
    //     const progress = (currentTime / duration) * 100;

    //     progressBarRef.current.style.width = `${progress}%`;
    //   }
    // };

    // waveSufer.on("audioprocess", () => {
    //   updateProgressBar();
    // });

    waveSufer.on("decode", () => {
      audioScores.forEach((audio) => {
        const duration = audio.end - audio.start;
        const width = duration * minPxPerSec;
        regions.addRegion({
          start: audio.start,
          end: audio.end,
          content: getRenderAudioHTML(audio.content, width),
          // color: audio.color,
          drag: false,
          resize: false,
        });
      });
    });

    waveSufer.on("timeupdate", (currentTime) => {
      const duration = waveSufer.getDuration();
      const progress = (currentTime / duration) * 100;

      dispatch(onWaveProgress(progress));
    });

    // Clean up on unmount
    return () => {
      if (waveSufer) waveSufer.destroy();
    };
  }, [document.querySelector("video")]);

  const getRenderAudioScore = (type: string): JSX.Element => {
    switch (type) {
      case "Respiration":
        return (
          <div className="w-5 h-5 bg-[#FFD445] rounded-[50%] border-solid border-black border-[3px]"></div>
        );
      case "Perturbation":
        return (
          <div className="w-5 h-5 bg-[#E14545] rounded-[50%] border-solid border-black border-[3px] flex justify-center items-center text-black font-bold">
            <XIcon />
          </div>
        );
      case "Silence":
        return <div className="w-8 h-4 bg-[#63CF95] rounded-[0.5rem]"></div>;
      default:
        return <></>;
    }
  };
  const getRenderAudioHTML = (type: string, width: number): HTMLElement => {
    let element: HTMLElement;
    switch (type) {
      case "Respiration":
        element = document.createElement("div");
        element.className = "respiration";
        break;
      case "Perturbation":
        element = document.createElement("div");
        element.className = "perturbation";
        element.style.width = `${width}px`;

        // Create the inner SVG element for the XIcon
        const xIcon = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "svg"
        );
        xIcon.setAttribute("width", "8");
        xIcon.setAttribute("height", "9");
        xIcon.setAttribute("viewBox", "0 0 8 9");
        xIcon.setAttribute("fill", "none");
        xIcon.setAttribute("xmlns", "http://www.w3.org/2000/svg");

        const path = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path.setAttribute("d", "M7 1.5L1 7.5M1 1.5L7 7.5");
        path.setAttribute("stroke", "#111111");
        path.setAttribute("stroke-width", "1.5");
        path.setAttribute("stroke-linecap", "round");
        path.setAttribute("stroke-linejoin", "round");

        xIcon.appendChild(path);
        element.appendChild(xIcon);
        break;
      case "Silence":
        element = document.createElement("div");
        element.className = "silence";
        break;
      default:
        element = document.createElement("div");
    }
    if (element.parentElement) {
      element.parentElement.style.top = "36%!important";
    }
    return element;
  };

  return (
    <div className="bg-[#282932] rounded-lg text-white">
      <div ref={waveformRef}></div>

      <div className="flex justify-center mt-2 text-sm gap-8">
        <div className="flex flex-col items-center">
          {getRenderAudioScore("Respiration")}
          <p>{t("Respiration")}</p>
        </div>
        <div className="flex flex-col items-center">
          {getRenderAudioScore("Perturbation")}
          <p>{t("Perturbation")}</p>
        </div>
        <div className="flex flex-col items-center justify-evenly">
          {getRenderAudioScore("Silence")}
          <p>{t("Silence")}</p>
        </div>
      </div>
    </div>
  );
};

export default VoiceFrequencyChart;
