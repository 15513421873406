import { Table } from "antd";
import { ColumnType } from "antd/es/table";
import { DAY_FORMAT } from "common/constants";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IResponseGetMasterclass } from "interfaces/IMasterclass";

export interface ClassListProps {
  dataSource: IResponseGetMasterclass[];
  isLoading: boolean;
  renderListData: () => Promise<void>;
}

const ClassList = ({ dataSource, isLoading, renderListData }: ClassListProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const columns: ColumnType<IResponseGetMasterclass>[] = [
    {
      title: <span className="text-[12px] font-normal">{t("classes").toUpperCase()}</span>,
      dataIndex: "name",
      key: "name",
      width: "30%",
      sorter: (a: IResponseGetMasterclass, b: IResponseGetMasterclass) => a.name.localeCompare(b.name),
      render: (text: string) => {
        return (
          <div className="w-full inline-flex justify-between items-center relative">
            <div className="inline-flex items-center">
              <div
                className="w-2 h-2 rounded mr-2 absolute"
                style={{
                  backgroundColor: "black",
                }}
              ></div>
              <span className="ml-4">{text}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: <span className="text-[12px] font-normal">{t("Start Date").toUpperCase()}</span>,
      dataIndex: "starting_date",
      key: "starting_date",
      width: "30%",
      sorter: (a: IResponseGetMasterclass, b: IResponseGetMasterclass) =>
        new Date(a.starting_date).getTime() - new Date(b.starting_date).getTime(),
      render: (text: string) => {
        return (
          <div className="w-full inline-flex justify-between items-center">
            <div className="inline-flex items-center">
              <span>{dayjs(text).format(DAY_FORMAT)}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: <span className="text-[12px] font-normal">{t("Participants").toUpperCase()}</span>,
      dataIndex: "participant_ids",
      key: "participant_ids",
      width: "20%",
      sorter: (a: IResponseGetMasterclass, b: IResponseGetMasterclass) =>
        a.participant_ids?.length - b.participant_ids?.length,
      render: (value: string[]) => {
        return (
          <div className="w-full inline-flex justify-between items-center">
            <div className="flex w-[90%] items-center">
              <div className="inline-flex items-center mr-2 ">
                <div className="whitespace-nowrap">{value?.length}</div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className="min-h-[calc(100vh-96px)]"
        loading={isLoading}
        onRow={(record) => ({
          onClick: () => {
            navigate(record._id);
          },
        })}
        rowKey={(record) => record._id}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 12,
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export default ClassList;
