import { Layout } from "antd";
import { Outlet } from "react-router-dom";

export default function LayoutWithoutSidebar() {
  return (
    <Layout>
      <Layout className="duration-300 ease-out min-h-[100vh]">
        <Outlet />
      </Layout>
    </Layout>
  );
}
