import { axiosClient } from "./apiClient";

const userApi = {
  getUserInfo() {
    const url = `/users/user_info`;
    return axiosClient.get(url);
  },
};

export default userApi;
