import type { MenuProps } from "antd";
import { Button, Dropdown } from "antd";
import ArrowDownSVG from "app/icons/ArrowDown";
import { RefObject } from "react";
import ScsExportComponent from "./ScvExportComponent";

export interface ExportProps {
  downloadPdf?: (refNode: RefObject<HTMLDivElement>) => void;
  classDetailRef?: RefObject<HTMLDivElement> | undefined;
  dataClassDetail?: any[] | [];
}
const ExportButton = (props: ExportProps) => {
  const { downloadPdf, dataClassDetail, classDetailRef } = props;
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "pdf") {
      if (downloadPdf && classDetailRef) downloadPdf(classDetailRef);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: <Button type="text">Export PDF</Button>,
      key: "pdf",
    },
    {
      label: (
        <ScsExportComponent
          data={dataClassDetail || []}
          headers={[]}
          filename={"ClassDetail.csv"}
        />
      ),
      key: "scs",
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps}>
      <Button
        className="flex items-center"
        style={{ background: "#F1F1F1", paddingRight: 0 }}
      >
        Export
        <ArrowDownSVG />
      </Button>
    </Dropdown>
  );
};

export default ExportButton;
