import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import TSButton from "../TSButton";

const FilterComponent = ({ ...props }) => {
  const { filterItem } = props;
  const [isFocus, setIsFocus] = useState(false);
  const onFocusElement = () => {
    setIsFocus(!isFocus);
  };
  const onLeaveElement = () => {
    setIsFocus(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => onLeaveElement()}>
      <div className="relative" id="btn-search" onClick={() => onFocusElement()}>
        <TSButton
          key={filterItem.label}
          className={`flex items-center mx-2 text-sm font-medium`}
          type="ghost"
          icon={filterItem.icon}
        >
          {filterItem.label}
        </TSButton>
      </div>
      <div
        id="search-field"
        data-testid="search-field"
        className={`absolute ml-[9px] opacity-0 focus:opacity-100 z-10 transition-opacity ${
          isFocus ? "block opacity-100" : "hidden"
        }`}
      >
        <div className="bg-neutral-50 shadow-[4px_4px_20px_0_rgba(0,0,0,0.15)] p-1.5">
          <Input
            onChange={(event) => filterItem.filterMethod(event)}
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
            placeholder="Find ..."
            className="bg-[#EAEAEA]"
            id="input-affix-wrapper-filter"
          />
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default FilterComponent;
