import {
  IRequestNewParticipantToCompany,
  IResponseGetInfoParticipant,
} from "interfaces";
import { axiosClient } from "./apiClient";

const participantApi = {
  getListParticipant(companyId: string) {
    const url = `/companies/${companyId}/participants`;
    return axiosClient.get(url, {});
  },
  getParticipantInfo(userId: string) {
    const url = `/users/${userId}`;
    return axiosClient.get(url);
  },
  getInfoParticipant(
    email: string
  ): Promise<{ data: IResponseGetInfoParticipant }> {
    const url = "/users/email";
    return axiosClient.get(url, {
      params: {
        email,
      },
    });
  },
  deleteParticipant(userId: string) {
    const url = `/users/${userId}`;
    return axiosClient.delete(url);
  },
  newParticipantToCompany(
    body: IRequestNewParticipantToCompany,
    companyId: string
  ) {
    const url = "/users/create_and_add_participant";
    const params = {
      company_id: companyId,
    };
    return axiosClient.post(url, body, { params });
  },
};

export default participantApi;
