import { IFilterElement } from "interfaces";
import React from "react";
import { useTranslation } from "react-i18next";
import FilterComponent from "./FilterComponent";
import "./FilterGroup.css";
import { IFilterGroup } from "./FilterGroup.d";

const FilterGroup = ({ listFilter }: IFilterGroup) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center text-sm font-medium">
      <span>{t("Filters")}:</span>
      <div className="ml-5 inline-flex">
        {listFilter.map((filterItem: IFilterElement) => (
          <React.Fragment key={filterItem.label}>
            <FilterComponent filterItem={filterItem} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default FilterGroup;
