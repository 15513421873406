import { IScores } from "./type";

export function formatDate(date: Date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""}${month}`;
}

export function getDaysArray(start: Date, end: Date) {
  const daysArray = [];
  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    daysArray.push(formatDate(new Date(dt)));
  }
  return daysArray;
}

// Get today's date
export const today = formatDate(new Date());
// Get list of day
export function getListDays(numberOfDays: number) {
  const daysAgo = new Date();
  daysAgo.setDate(daysAgo.getDate() - numberOfDays + 1);
  const lastDays = getDaysArray(daysAgo, new Date());

  return lastDays;
}

export function convertScoresLength(scores: IScores, newLength: number) {
  const convertedScores: IScores = {
    voice: [],
    body: [],
    message: [],
    knowledge: [],
    "total impact": [],
  };
  const fistKey = Object.keys(scores)[0];
  if (!fistKey) return;
  const lengthObject = (scores as any)[fistKey.toString()].length;

  // Loop through each property (voice, body, message, knowledge)
  for (const key in scores) {
    if (scores.hasOwnProperty(key)) {
      const originalArray = scores[key as keyof IScores];

      // Use slice to create a new array with the specified length
      if (!originalArray) return;
      const newArray = originalArray.slice(
        lengthObject - newLength,
        lengthObject
      );
      // Assign the new array to the convertedScores object
      convertedScores[key as keyof IScores] = newArray;
    }
  }
  return convertedScores as IScores;
}
