import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SidebarProvider } from "context/SidebarContext";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RouterProvider } from "react-router-dom";
import routes from "routes";

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        <SidebarProvider>
          <RouterProvider router={routes} />
        </SidebarProvider>
      </DndProvider>
    </QueryClientProvider>
  );
};

export default App;
