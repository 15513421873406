import { useEffect } from "react";

function useAsyncEffect(effect: () => Promise<void>, dependencies: any[]) {
  useEffect(() => {
    let isMounted = true;

    const executeEffect = async () => {
      try {
        await effect();
      } catch (error) {
        if (isMounted) {
          console.error("useAsyncEffect error:", error);
        }
      }
    };

    executeEffect();

    return () => {
      isMounted = false;
    };
  }, dependencies);
}

export default useAsyncEffect;
