import { Form, Input } from "antd";
import participantApi from "api/participant";
import TSButton from "app/components/TSButton";
import TSModal from "app/components/TSModal/TSModal";
import {
  KEY_VALUES,
  LIMIT_LENGTH_INPUT,
  NAME_FEATURE,
  RULE_FORM_ITEM_NAME,
  TYPE_NOTIFICATION,
} from "common/constants";
import { LIGHT_GRAY } from "common/constants/color.constant";
import { notificationError, notificationSuccess } from "common/helper";
import { IModalAction, IRequestNewParticipantToCompany } from "interfaces";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ModalAdd = ({
  renderListData,
  handleCloseModal,
  isOpen,
}: IModalAction) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const companyIdLocal = localStorage.getItem(KEY_VALUES.COMPANY_ID) ?? "";

  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setConfirmLoading(false);
    handleCloseModal();
  };
  const onFinish = async (body: IRequestNewParticipantToCompany) => {
    setConfirmLoading(!confirmLoading);
    try {
      await participantApi.newParticipantToCompany(body, companyIdLocal);
      handleCloseModal();
      setConfirmLoading(false);
      form.resetFields();
      renderListData();
      notificationSuccess(TYPE_NOTIFICATION.CREATE, NAME_FEATURE.PARTICIPANT);
    } catch (err: any) {
      const { status } = err.response;
      notificationError(status as string);
      setConfirmLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: unknown) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <TSModal
      title={t("Add new participant")}
      open={isOpen}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      data-testid="modal-add"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        requiredMark={false}
      >
        <Form.Item
          className="mt-5"
          label={t("Email")}
          name={t("email")}
          required
          rules={[
            {
              required: true,
              message: t("Please input your email!"),
            },
            {
              max: LIMIT_LENGTH_INPUT.MAX,
              message: t("Name must be maximum 64 characters"),
            },
            {
              type: "email",
              message: t("Email is invalid"),
            },
          ]}
        >
          <Input
            className={`w-full bg-[${LIGHT_GRAY}]`}
            placeholder={t("Email")}
            type="email"
          />
        </Form.Item>
        <Form.Item
          className="mt-5"
          label={t("First name")}
          name="first_name"
          required
          rules={RULE_FORM_ITEM_NAME(t("First name"))}
        >
          <Input
            className={`w-full bg-[${LIGHT_GRAY}]`}
            placeholder={t("First name")}
          />
        </Form.Item>
        <Form.Item
          className="mt-5"
          label={t("Last name")}
          name="last_name"
          required
          rules={RULE_FORM_ITEM_NAME(t("Last name"))}
        >
          <Input
            className={`w-full bg-[${LIGHT_GRAY}]`}
            placeholder={t("Last name")}
          />
        </Form.Item>
        <Form.Item className="mt-8 mb-2 py-2">
          <TSButton
            type="primary"
            htmlType="submit"
            loading={confirmLoading}
            className="px-8 h-10 w-full text-base"
          >
            {t("Add new participant")}
          </TSButton>
        </Form.Item>
      </Form>
    </TSModal>
  );
};

export default ModalAdd;
