import { isEmpty, isNil } from "lodash";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  Area,
  AreaChart,
  ReferenceDot,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import "./index.css";

const CHART_COLOR = "rgba(255, 212, 69, 1)";
const CHART_COLOR_0 = "rgba(255, 212, 69, 0)";

const DELAY = 100;
const STEPS = Math.floor(1000 / DELAY);

const CHART_LENGTH = 30;

// Cubic ease-in-out easing function
const easeInOutCubic = (t: number): number => {
  return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
};

const CustomDot = (props: any) => {
  const { cx, cy, data, currentIndex } = props;

  return (
    <g>
      <foreignObject
        x={cx - 24}
        y={cy - 40}
        width={50}
        height={40}
        style={{
          transition: "all 0.1s linear",
        }}
      >
        <p className="bg-[#D9D9D9] text-black rounded-xl text-center font-bold text-base">
          {data[currentIndex]?.value?.toFixed(1)}
        </p>
      </foreignObject>
      <circle
        style={{
          transition: "all 0.1s linear",
        }}
        cx={cx}
        cy={cy}
        r={8}
        strokeWidth={5}
        fill="#FFD445"
      />
      <circle
        style={{
          transition: "all 0.1s linear",
        }}
        cx={cx}
        cy={cy}
        r={5}
        strokeWidth={5}
        fill="black"
      />
    </g>
  );
};

const getStartAndCurrentPosition = (
  internalCurrentIndex: number,
  videoDuration: number
) => {
  const chartLength = CHART_LENGTH * STEPS;
  const internalVideoDuration = videoDuration * STEPS;

  if (
    internalCurrentIndex - chartLength / 2 >= 0 &&
    internalCurrentIndex + chartLength <= internalVideoDuration
  ) {
    return {
      currentPosition: chartLength / 2,
      startPosition: internalCurrentIndex - chartLength / 2,
    };
  }

  if (
    internalVideoDuration < chartLength ||
    internalCurrentIndex - chartLength / 2 < 0
  ) {
    return { currentPosition: internalCurrentIndex, startPosition: 0 };
  }

  if (
    internalVideoDuration < chartLength ||
    internalCurrentIndex - chartLength / 2 < 0
  ) {
    return { currentPosition: internalCurrentIndex, startPosition: 0 };
  }

  return {
    currentPosition:
      chartLength - (internalVideoDuration - internalCurrentIndex),
    startPosition: internalVideoDuration - chartLength,
  };
};

const SpeedChart = ({
  data: dataProps,
  videoDuration,
  currentIndex,
}: {
  data: Array<{ name: number; value: number }>;
  videoDuration: number;
  currentIndex: number;
}) => {
  const currentStep = useRef<number>(0);

  const [internalCurrentIndex, setInternalCurrentIndex] = useState(
    currentIndex ?? 0
  );

  const { currentPosition, startPosition } = useMemo(
    () => getStartAndCurrentPosition(internalCurrentIndex, videoDuration),
    [internalCurrentIndex, videoDuration]
  );

  const interpolatedData = useMemo(() => {
    if (isEmpty(dataProps)) return [];
    let result: Array<{ name: number; value: number }> = [];
    for (let i = 0; i < dataProps.length - 2; i++) {
      for (let j = 0; j < STEPS; j++) {
        const progress = j / STEPS;
        const easedProgress = easeInOutCubic(progress);
        const interpolatedValue =
          dataProps[i]?.value +
          easedProgress * (dataProps[i + 1]?.value - dataProps[i]?.value);
        result.push({ name: j + i * STEPS, value: interpolatedValue });
      }
    }

    return result;
  }, [dataProps]);

  useEffect(() => {
    if (isNil(currentIndex)) {
      return;
    }
    const interval = setInterval(() => {
      if (currentStep.current < STEPS) {
        setInternalCurrentIndex(
          (prev) => currentIndex * STEPS + currentStep.current
        );

        currentStep.current += 1;
      } else {
        clearInterval(interval);
        currentStep.current = 0;
      }
    }, DELAY);

    return () => {
      currentStep.current = 0;
      clearInterval(interval);
    };
  }, [currentIndex]);

  const data = useMemo(
    () =>
      interpolatedData.slice(
        startPosition,
        startPosition + CHART_LENGTH * STEPS
      ),
    [interpolatedData, startPosition]
  );

  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{ top: 40, bottom: 10, left: 30, right: 30 }}
      >
        <defs>
          <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={CHART_COLOR} />
            <stop offset="100%" stopColor={CHART_COLOR_0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          stroke={CHART_COLOR}
          strokeWidth={5}
          fill="url(#gradientStroke)"
          isAnimationActive={false}
          animationDuration={100}
          animationEasing="linear"
          // animateNewValues={true}
        />
        <YAxis
          hide
          domain={[0, Math.max(...dataProps.map((item) => item.value))]}
        />

        <ReferenceDot
          x={currentPosition}
          y={data[currentPosition]?.value}
          r={5}
          fill="black"
          stroke="black"
          style={{
            transition: "all ease-in-out",
          }}
          shape={<CustomDot data={data} currentIndex={currentPosition} />}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default memo(SpeedChart);
