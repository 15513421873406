import {
  IRequestNewCompany,
  IResponseGetCompany,
  IResponseGetInfoCompany,
} from "interfaces";
import { axiosClient } from "./apiClient";

const companyApi = {
  getCompanies(): Promise<{
    data: IResponseGetCompany[];
  }> {
    const url = "/companies/list";
    return axiosClient.get(url);
  },
  getInfoCompany(companyId: string): Promise<{
    data: IResponseGetInfoCompany;
  }> {
    const url = `/companies/${companyId}`;
    return axiosClient.get(url);
  },
  newCompany(params: IRequestNewCompany) {
    const url = "/companies";
    return axiosClient.post(url, params);
  },
  editCompany(companyId: string, params: IRequestNewCompany) {
    const url = `/companies/${companyId}`;
    return axiosClient.put(url, params);
  },
  deleteCompany(id: string) {
    const url = `/companies/${id}`;
    return axiosClient.delete(url);
  },
};

export default companyApi;
