export enum KEY_VALUE {
  NAME_MODULE = "name",
  NAME_CATEGORY = "category_ids",
  TIME = "duration_in_minutes",
}

export enum TITLE_COLUMN {
  MODULE = "MODULE",
  CATEGORY = "CATEGORY",
  TIME = "TIME",
}
