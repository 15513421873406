import { Table } from "antd";
import { ITSTable } from "./TSTable.d";

const TSTable = ({
  isLoading,
  dataSource,
  columns,
  onClickRow = () => {},
  onMouseEnterRow = () => {},
  onMouseLeaveRow = () => {},
  defaultPageSize = 12,
  ...props
}: ITSTable) => {
  return (
    <Table
      loading={isLoading}
      rowKey={(record) => record.id}
      dataSource={dataSource}
      columns={columns}
      pagination={{
        // className: "absolute bottom-0 w-full flex justify-center",
        position: ["bottomCenter"],
        defaultPageSize: defaultPageSize,
        showSizeChanger: false,
      }}
      onRow={(record) => ({
        onClick: () => {
          onClickRow(record);
        },
        onMouseEnter: () => {
          onMouseEnterRow(record);
        },
        onMouseLeave: () => {
          onMouseLeaveRow();
        },
      })}
      {...props}
    />
  );
};

export default TSTable;
