const ReportIconSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path d="M4.88346 1.99778C4.88346 1.46794 5.09394 0.959793 5.4686 0.585136C5.84326 0.21048 6.3514 0 6.88124 0H9.101C9.63084 0 10.139 0.21048 10.5136 0.585136C10.8883 0.959793 11.0988 1.46794 11.0988 1.99778V3.56937H13.9845C14.5143 3.56937 15.0224 3.77985 15.3971 4.1545C15.7718 4.52916 15.9822 5.0373 15.9822 5.56715V15.3341C15.9822 15.5107 15.9121 15.6801 15.7872 15.805C15.6623 15.9298 15.4929 16 15.3163 16H0.665927C0.489312 16 0.319931 15.9298 0.195045 15.805C0.0701598 15.6801 0 15.5107 0 15.3341V8.23085C0 7.70101 0.21048 7.19287 0.585136 6.81821C0.959793 6.44355 1.46794 6.23307 1.99778 6.23307H4.88346V1.99778ZM6.21532 14.6681H9.76693V1.99778C9.76693 1.82117 9.69676 1.65178 9.57188 1.5269C9.44699 1.40201 9.27761 1.33185 9.101 1.33185H6.88124C6.70463 1.33185 6.53525 1.40201 6.41036 1.5269C6.28548 1.65178 6.21532 1.82117 6.21532 1.99778V14.6681ZM4.88346 7.56493H1.99778C1.82117 7.56493 1.65178 7.63509 1.5269 7.75997C1.40201 7.88486 1.33185 8.05424 1.33185 8.23085V14.6681H4.88346V7.56493ZM11.0988 14.6681H14.6504V5.56715C14.6504 5.39053 14.5802 5.22115 14.4553 5.09627C14.3305 4.97138 14.1611 4.90122 13.9845 4.90122H11.0988V14.6681Z" />
  </svg>
);
export default ReportIconSVG;
