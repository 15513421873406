import Icon, { FileTextOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";
import participantApi from "api/participant";
import ParticipationComponent from "app/components/Participation/ParticipationComponent";
import ProgressLineComponent from "app/components/ProgressLine/ProgressLineComponent";
import RadarComponent from "app/components/Radar/RadarComponent";
import RateComponent from "app/components/Rate/RateComponent";
import TSButton from "app/components/TSButton";
import BuildingIconSVG from "app/icons/BuildingIcon/BuildingIconSVG";
import GraduationCapSVG from "app/icons/GraduationCap/GraduationCapSVG";
import MeetingBoardSVG from "app/icons/MeetingBoard/MeetingBoardSVG";
import { AxiosResponse } from "axios";
import html2canvas from "html2canvas";
import { IResponseGetParticipantInfo } from "interfaces";
import jsPDF from "jspdf";
import { isEmpty, uniqBy } from "lodash";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PersonalInfo from "./PersonalInfo";

const Profile = () => {
  const { participantId } = useParams();
  const profileRef = useRef<any>();
  const { t } = useTranslation();

  const {
    data: participantDetail,
    isLoading,
    isFetching,
  } = useQuery<AxiosResponse<IResponseGetParticipantInfo>>(
    ["participant", participantId],
    () => participantApi.getParticipantInfo(participantId || ""),
    {
      enabled: !!participantId,
    }
  );
  if (isLoading || isFetching || !participantDetail?.data)
    return (
      <div className="min-h-[100vh] items-center flex justify-center">
        <Spin size="large" />
      </div>
    );
  const participant = participantDetail?.data;
  const totalImpact =
    (participant.score?.body +
      participant.score?.knowledge +
      participant.score?.message +
      participant.score?.voice || 0) / 4;

  const downloadPdf = () => {
    let input = profileRef.current;
    if (input) {
      html2canvas(input, {
        scrollX: 1000,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth * ratio, imgHeight * ratio);
        pdf.save("profileRef.pdf");
      });
    }
  };

  return (
    <>
      <div className="h-[88px] bg-white px-8 flex items-center justify-between shadow-[0_4px_20px_0_rgba(0,0,0,0.07)] z-10">
        <span className="font-bold text-lg">
          {participant?.first_name} {participant?.last_name}
        </span>
        <TSButton
          className="text-base font-medium"
          icon={<FileTextOutlined />}
          onClick={downloadPdf}
        >
          {t("Export PDF")}
        </TSButton>
      </div>
      <div className="flex ">
        <div
          className="bg-greyBg h-[calc(100vh-90px)] overflow-auto text-grey5 flex-1"
          ref={profileRef}
        >
          <div className="py-[29px] px-6 ">
            <PersonalInfo participant={participant} />
          </div>

          <div className="flex m-6 justify-start flex-wrap gap-6">
            <ParticipationComponent percent={participant.contribution} />
            <RateComponent total={10} current={participant.satisfaction} />
            <div className="w-[245px] h-[233px] flex flex-col justify-between">
              <ProgressLineComponent
                title="Total impact"
                total={9999}
                current={totalImpact}
                color={"#63CF95"}
              />
              <ProgressLineComponent
                title="Body"
                total={9999}
                current={participant.score?.body}
                color={"#DB708A"}
              />
              <ProgressLineComponent
                title="Knowledge"
                total={9999}
                current={participant.score?.knowledge}
                color={"#FFAE06"}
              />
              <ProgressLineComponent
                title="Message"
                total={9999}
                current={participant.score?.message}
                color={"#D08CC5"}
              />
              <ProgressLineComponent
                title="Voice"
                total={9999}
                current={participant.score?.voice}
                color={"#24C9D3"}
              />
            </div>
            <div className="bg-white w-[245px] h-[233px] flex justify-center border-solid border-greyBorder">
              <RadarComponent data={participant.colors} />
            </div>
          </div>
        </div>
        <div className="w-[211px] bg-white py-7 px-4 text-grey5 h-full">
          <div className="mb-4 flex items-center">
            <Icon component={BuildingIconSVG} />
            <span className="ml-1">Companies</span>
          </div>
          <div className="bg-grey6 p-4">{participant.company}</div>
          <div className="mb-4 mt-4 flex items-center">
            <Icon component={MeetingBoardSVG} />
            <span className="ml-1">Programs</span>
          </div>
          {
            uniqBy(participant.classes, "program").map(item => <div className="bg-grey6 p-4 mb-2">{item.program}</div>)
          }
          <div className="mb-4 mt-4 flex items-center">
            <Icon component={GraduationCapSVG} />
            <span className="ml-1">Classes</span>
          </div>
          {
            isEmpty(participant.classes) ? (
              <div className="bg-grey6 p-4 mb-2"></div>
            ) : (
              participant.classes.map(item => <div className="bg-grey6 p-4 mb-2">{item.name}</div>)
            )}
        </div>
      </div>
    </>
  );
};

export default Profile;
