import App from "app/containers/App";
import { LoginPage, RequireAuth } from "app/containers/Auth/Login";
import Category from "app/containers/Category";
import Class from "app/containers/Class";
import ClassDetail from "app/containers/Class/ClassDetail";
import Company from "app/containers/Company";
import ErrorPage from "app/containers/Error";
import Masterclass from "app/containers/Masterclass";
import Session from "app/containers/Masterclass/Session";
import SessionDetail from "app/containers/Masterclass/SessionDetail";
import Module from "app/containers/Module";
import Participant from "app/containers/Participant";
import ParticipantRecordingEnded from "app/containers/ParticipantRecordingEnded";
import Profile from "app/containers/Profile";
import Program from "app/containers/Program";
import ProgramDetail from "app/containers/Program/ProgramDetail";
import Report from "app/containers/Report";
import { createBrowserRouter } from "react-router-dom";
import Dashboard from "app/containers/Dashboard";
import ParticipantAnalysis from "app/containers/ParticipantAnalysis";
import { VideoProvider } from "context/VideoContext";
import LayoutWithoutSidebar from "app/components/LayoutWithoutSidebar";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <RequireAuth>
          <App />
        </RequireAuth>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Company />,
        },
        {
          path: "/company",
          element: <Company />,
        },
        {
          path: "/class",
          element: <Class />,
        },
        {
          path: "/program",
          element: <Program />,
        },
        {
          path: "/module",
          element: <Module />,
        },
        {
          path: "/category",
          element: <Category />,
        },
        {
          path: "/participant",
          element: <Participant />,
        },
        {
          path: "/participant/:participantId",
          element: <Profile />,
        },
        {
          path: "program/:programId",
          element: <ProgramDetail />,
        },
        {
          path: "class/:classId",
          element: <ClassDetail />,
        },
        {
          path: "program/new",
          element: <ProgramDetail isCreate />,
        },
        {
          path: "report",
          element: <Report />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "/masterclass/:classId",
          element: <Session />,
        },
        {
          path: "/masterclass",
          element: <Masterclass />,
        },
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "/masterclass/:classId/:sessionIndex",
      element: (
        <RequireAuth>
          <LayoutWithoutSidebar />
        </RequireAuth>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <SessionDetail />,
        },
        {
          path: "analysis",
          element: (
            <VideoProvider>
              <ParticipantAnalysis />
            </VideoProvider>
          ),
        },
        {
          path: ":participantIndex",
          element: <Dashboard />,
        },
        {
          path: ":participantIndex/recording-ended",
          element: <ParticipantRecordingEnded />,
        },
      ],
    },
  ],
  { basename: "/backoffice" }
);

export default router;
