import { Form, Input } from "antd";
import companyApi from "api/company";
import TSButton from "app/components/TSButton";
import TSModal from "app/components/TSModal/TSModal";
import {
  NAME_FEATURE,
  RULE_FORM_ITEM_NAME,
  TYPE_NOTIFICATION,
} from "common/constants";
import { notificationError, notificationSuccess } from "common/helper";
import { IModalAction } from "interfaces";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IFormValueModalAdd } from "./ModalAdd.d";

const ModalAdd = ({
  renderListData,
  handleCloseModal,
  isOpen,
}: IModalAction) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setConfirmLoading(false);
    handleCloseModal();
  };
  const onFinish = async (values: IFormValueModalAdd) => {
    const { nameCompany } = values;
    setConfirmLoading(!confirmLoading);
    try {
      await companyApi.newCompany({ name: nameCompany.trim() });
      handleCloseModal();
      setConfirmLoading(false);
      form.resetFields();
      renderListData();
      notificationSuccess(TYPE_NOTIFICATION.CREATE, NAME_FEATURE.COMPANY);
    } catch (err: any) {
      const { status } = err.response;
      notificationError(status as string);
      setConfirmLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: unknown) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <TSModal
      title={t("Add new company")}
      open={isOpen}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width="650px"
    >
      <Form
        form={form}
        layout="inline"
        requiredMark={true}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="justify-between"
      >
        <Form.Item
          label={t("Company name")}
          name={t("nameCompany")}
          required
          rules={RULE_FORM_ITEM_NAME(t("Company name"))}
        >
          <Input className="w-[250px]" placeholder={t("Company name")} />
        </Form.Item>
        <Form.Item>
          <TSButton
            type="primary"
            htmlType="submit"
            loading={confirmLoading}
            className="px-8"
          >
            {t("Add")}
          </TSButton>
        </Form.Item>
      </Form>
    </TSModal>
  );
};

export default ModalAdd;
