import { Space } from "antd";
import { useTranslation } from "react-i18next";
import TSButton from "../TSButton";
import { INotificationErrorBtn } from "./NotificationErrorBtn.d";

const NotificationErrorBtn = ({
  renderListData,
  notification,
}: INotificationErrorBtn) => {
  const { t } = useTranslation();
  return (
    <Space>
      <TSButton type="link" size="small" onClick={() => notification.destroy()}>
        {t("Close")}
      </TSButton>
      <TSButton
        type="primary"
        size="small"
        onClick={() => {
          renderListData();
          notification.destroy();
        }}
      >
        {t("Refresh data")}
      </TSButton>
    </Space>
  );
};

export default NotificationErrorBtn;
