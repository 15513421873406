import { NUMBER_OF_WEEKDAYS } from "./constant";

export const getColorByClassState = (
  classState: string | undefined,
  isRemote: boolean | undefined
) => {
  if (classState?.charAt(0) === "F" && isRemote)
    return { border: "#D7BF00", color: "#FFE304" };
  if (classState?.charAt(0) === "F")
    return { border: "#5AB568", color: "#6FD980" };

  return { border: "#C2C2C2", color: "#E1E1E1" };
};

export const getWeekList = (totalDays: number) => {
  return new Array(Math.ceil(totalDays / NUMBER_OF_WEEKDAYS))
    .fill(0)
    .map((_, index) => `Week ${index + 1}`);
};
