import { CHART_COLORS } from "./constant";

export const getDefaultColor = (index: number) => {
  if (index < 3) return CHART_COLORS[0];
  if (index < 6) return CHART_COLORS[1];
  return CHART_COLORS[4];
};

export const getDefaultType = (index: number) => {
  if (index % 2 === 0) return "line";

  return "bar";
};
