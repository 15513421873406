import { Rate } from "antd";
import { IRateProp } from "./RateComponent.d";

export default function RateComponent({ total, current }: IRateProp) {
  return (
    <div className="bg-white p-6 w-[245px] h-[233px] rounded-lg border-solid border-greyBorder">
      <div className="text-[18px] text-darkGrey2 font-bold text-center mb-[38px]">
        Satisfaction
      </div>
      <div className="flex justify-center">
        <Rate
          style={{ fontSize: 30, color: "#FFD445" }}
          allowHalf={true}
          value={(current ?? 0) / ((total ?? 10) / 5)}
          disabled={true}
        />
      </div>
      <p className="text-center text-[28px] font-extrabold pt-[4px] text-[#909090]">
        {current ?? 0}/{total ?? 10}
      </p>
    </div>
  );
}
