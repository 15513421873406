import categoryApi from "api/category";
import TSHeader from "app/components/TSHeader";
import { TagAlt } from "app/icons";
import { CONS_COLOR } from "common/constants";
import { filterDataSource } from "common/helper";
import { IResponseGetCategory } from "interfaces";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListCategory from "./ListCategory";
import ModalAdd from "./ModalAdd";
import "./index.css";

export default function Category() {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<IResponseGetCategory[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [valueInput, setValueInput] = useState("");
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [listCategory, setListCategory] = useState<IResponseGetCategory[]>([]);
  const getCategories = () =>
    categoryApi.getCategories().then((data) => {
      setListCategory(data.data);
      setIsLoading(false);
    });
  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    setDataSource([...listCategory]);
  }, [listCategory]);

  const filterNameCategory = (e: ChangeEvent<HTMLInputElement>) => {
    const valueInput = e.target.value;
    setValueInput(valueInput);
    if (!valueInput) return setIsFilter(false);
    setIsFilter(true);
  };
  const listFilter = [
    {
      label: t("Categories"),
      icon: (
        <TagAlt
          className="white-red align-text-middle"
          style={{ color: CONS_COLOR.TEXT_BLACK }}
        />
      ),
      filterMethod: filterNameCategory,
    },
  ];
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  return (
    <div className="relative h-full">
      <TSHeader
        titleHeader={t("Categories")}
        textLabelInput={t("New Category")}
        listFilter={listFilter}
        modalForm={
          <ModalAdd
            renderListData={getCategories}
            isOpen={isOpenModal}
            handleCloseModal={handleCloseModal}
          />
        }
        handleOpenModal={handleOpenModal}
      >
        {t("Reports")}
      </TSHeader>
      <ListCategory
        dataSource={
          isFilter
            ? filterDataSource<IResponseGetCategory>(dataSource, valueInput)
            : dataSource
        }
        isLoading={isLoading}
        renderListData={getCategories}
      />
    </div>
  );
}
