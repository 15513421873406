import i18next from "i18next";
import { REGEX_PATTERN_INPUT } from "./regexPattern.constant";

export enum LIMIT_LENGTH_INPUT {
  MIN = 3,
  MAX = 64,
}

export const RULE_FORM_ITEM_NAME = (name: string) => [
  {
    required: true,
    message: i18next.t("Please input your {name}!", {
      name,
    }),
  },
  {
    min: LIMIT_LENGTH_INPUT.MIN,
    message: i18next.t("{name} must be minimum 3 characters", {
      name,
    }),
  },
  {
    max: LIMIT_LENGTH_INPUT.MAX,
    message: i18next.t("{name} must be maximum 64 characters", {
      name,
    }),
  },
  {
    pattern: new RegExp(REGEX_PATTERN_INPUT),
    message: i18next.t("{name} is invalid", {
      name,
    }),
  },
];
