const AngleDoubleLeftSvg = () => (
  <svg
    width="25"
    height="15"
    viewBox="0 0 25 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.3222 1.00893C23.8929 0.582104 23.312 0.342529 22.7066 0.342529C22.1012 0.342529 21.5204 0.582104 21.091 1.00893L12.8639 9.12143L4.75141 1.00893C4.32203 0.582104 3.74121 0.342529 3.13578 0.342529C2.53036 0.342529 1.94953 0.582104 1.52016 1.00893C1.30536 1.22197 1.13487 1.47543 1.01853 1.75469C0.902185 2.03395 0.842285 2.33349 0.842285 2.63601C0.842285 2.93854 0.902185 3.23807 1.01853 3.51733C1.13487 3.79659 1.30536 4.05006 1.52016 4.2631L11.2368 13.9798C11.4499 14.1946 11.7033 14.365 11.9826 14.4814C12.2618 14.5977 12.5614 14.6576 12.8639 14.6576C13.1664 14.6576 13.466 14.5977 13.7452 14.4814C14.0245 14.365 14.2779 14.1946 14.491 13.9798L24.3222 4.2631C24.537 4.05006 24.7075 3.79659 24.8239 3.51733C24.9402 3.23807 25.0001 2.93854 25.0001 2.63601C25.0001 2.33349 24.9402 2.03395 24.8239 1.75469C24.7075 1.47543 24.537 1.22197 24.3222 1.00893Z"
      fill="#F8F8F8"
    />
  </svg>
);
export default AngleDoubleLeftSvg;
