import { DownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import type { ChartData, ChartOptions } from "chart.js";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Dispatch, SetStateAction } from "react";
import { Bar, Line } from "react-chartjs-2";
import { ChartState } from ".";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options: ChartOptions<"line"> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 5,
    },
  },
  scales: {
    x: {
      display: false, //this will remove all the x-axis grid lines
      grid: {
        display: false,
        drawOnChartArea: false,
        color: "transparent",
        lineWidth: 0,
      },
    },
    y: {
      min: 0,
      max: 10
    }
  },
};

export const barOptions: ChartOptions<"bar"> = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 5,
    },
  },
  scales: {
    x: {
      display: false, //this will remove all the x-axis grid lines
      grid: {
        display: false,
        drawOnChartArea: false,
        color: "transparent",
        lineWidth: 0,
      },
    },
    y: {
      min: 0,
      max: 10
    }
  },
};

export type TFirstChart = {
  index: number;
  data: number[];
  label?: string;
  low?: string;
  high?: string;
  labels?: string[];
  bar?: boolean;
  color?: string;
  setChartState: Dispatch<SetStateAction<ChartState[]>>;
  setModalOpen: Dispatch<SetStateAction<number | undefined>>;
};

export function SelfEvaluateChart({
  data,
  label,
  low,
  high,
  labels,
  bar,
  color = "#63CF95",
  setChartState,
  setModalOpen,
  index,
}: TFirstChart) {
  const chartData: ChartData<"line"> = {
    labels,
    datasets: [
      {
        label: label || "Dataset 1",
        data: data,
        borderColor: color,
        borderWidth: 5,
        backgroundColor: "white",
      },
    ],
  };
  const barChartData: ChartData<"bar"> = {
    labels,
    datasets: [
      {
        label: "",
        data: data,
        borderWidth: 0,
        backgroundColor: color,
      },
    ],
  };
  return (
    <div className="w-[245px] h-[245px] p-4 pr-2 bg-white">
      <div className="font-bold mb-1 text-[14px] text-darkGrey2">
        <div className="flex justify-between">
          <div>{label}</div>
          <Button
            onClick={() => setModalOpen(index)}
            type="text"
            icon={<DownOutlined />}
          ></Button>
        </div>
      </div>
      <div className="uppercase text-grey8 text-[10px] font-bold text-center">
        {low}
      </div>
      {bar ? (
        <Bar options={barOptions} data={barChartData} />
      ) : (
        <Line options={options} data={chartData} />
      )}
      <div className="uppercase text-grey8 text-[10px] font-bold text-center">
        {high}
      </div>
    </div>
  );
}
