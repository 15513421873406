export const CHART_LENGTH = 30;
export const STEPS = 16;

function arrayPadStart<T>(arr: T[], targetLength: number, padValue: T): T[] {
  const padCount = targetLength - arr.length;

  if (padCount > 0) {
    return new Array(padCount).fill(padValue).concat(arr);
  } else {
    return arr;
  }
}

export default arrayPadStart;
