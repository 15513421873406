import {
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  SearchOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Checkbox, Empty, Input, Modal } from "antd";
import programApi from "api/program";
import { AxiosResponse } from "axios";
import { IModuleItem } from "interfaces/IProgram";
import { useTranslation } from "react-i18next";
import ModuleItem from "./ModuleItem";

import TSButton from "app/components/TSButton";
import { useEffect, useMemo, useState } from "react";

import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import categoryApi from "api/category";
import classNames from "classnames";
import { IResponseGetCategory } from "interfaces";
import { isEmpty } from "lodash";
import "./modulePanel.css";

export default function ModulePanel() {
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [listFilterCategory, setListFilterCategory] = useState<IModuleItem[]>(
    []
  );
  const [isFilterCategory, setIsFilterCategory] = useState(false);
  const [searchCategoryInput, setSearchCategoryInput] = useState("");

  const [isExpand, setIsExpand] = useState(true);

  const [checkedList, setCheckedList] = useState<
    { value: CheckboxValueType; checked: boolean }[]
  >([]);

  const { data: modules } = useQuery<AxiosResponse<IModuleItem[]>>(
    ["modules"],
    programApi.getModules
  );

  const { data: categories } = useQuery<AxiosResponse<IResponseGetCategory[]>>(
    ["categories"],
    categoryApi.getCategories
  );

  const handleChangeSearchCategoryInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchCategoryInput(e.target.value);
  };

  const [moduleSearchText, setModuleSearchText] = useState("");

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModuleSearchText(e.target.value);
  };

  const handleToggleCategory = () => {
    setIsOpenModal((state) => !state);
  };

  const handleCloseCategory = () => {
    setIsOpenModal(false);
  };

  const filterModuleByCategory = () => {
    const checkedListFilter = checkedList
      .filter((item) => item.checked)
      .map((item) => item.value);
    return (
      modules?.data.filter((module) => {
        const filterModuleHaveCategoryChecked = module.categories?.filter(
          (category) => {
            return checkedListFilter.some((item) => item === category.id);
          }
        );
        const checkModuleHaveCategoryCheckedWithCheckedList =
          filterModuleHaveCategoryChecked?.length === checkedListFilter.length;
        return (
          isEmpty(checkedList) || checkModuleHaveCategoryCheckedWithCheckedList
        );
      }) ?? []
    );
  };

  const handleClickFilter = () => {
    if (!checkedList.length) {
      setIsFilterCategory(false);
    }
    setIsFilterCategory(true);
    setListFilterCategory(filterModuleByCategory());
  };

  const handleRenderListModuleByCategory = useMemo(() => {
    let listModule: IModuleItem[] = modules?.data ?? [];
    if (isFilterCategory) listModule = listFilterCategory;
    if (isFilterCategory && !listFilterCategory.length) return [];

    return listModule.filter((module) =>
      module.name.toLowerCase().includes(moduleSearchText.toLowerCase())
    );
  }, [modules, isFilterCategory, listFilterCategory, moduleSearchText]);

  const handleRenderListCategory =
    categories?.data?.filter((category) =>
      category.name.toLowerCase().includes(searchCategoryInput.toLowerCase())
    ) ?? [];

  const onChangeCheckbox = (id: string) => (e: CheckboxChangeEvent) => {
    setCheckedList(
      checkedList.map((item) => ({
        value: item.value,
        checked: item.value === id ? e.target.checked : item.checked,
      }))
    );
  };

  const moduleList = useMemo(() => {
    return (
      <div className="relative">
        <div
          className={
            "h-[calc(100vh-96px-104px-88px)] overflow-y-scroll p-[16px]"
          }
        >
          {handleRenderListModuleByCategory.length ? (
            handleRenderListModuleByCategory.map((module, index) => (
              <ModuleItem
                itemIndex={index}
                key={module.id}
                module={module}
                className="mb-[16px] text-ellipsis whitespace-nowrap h-[48px] py-[6px]  px-[10px] min-w-[80px]"
                durationClassName="bottom-[6px] right-[10px]"
                moduleNameClassName="mr-[28px] line-clamp-2"
                canDrag
              />
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
        <div className="w-full  p-[16px] border-t-grey2 border-solid border-b-0 border-x-0 border-t bg-white">
          <TSButton
            className="text-base font-medium w-full"
            icon={<PlusOutlined />}
          >
            {t("Create new module")}
          </TSButton>
        </div>
      </div>
    );
  }, [t, handleRenderListModuleByCategory]);

  useEffect(() => {
    if (isEmpty(categories)) return;

    setCheckedList(
      categories?.data.map?.((category) => ({
        value: category.id,
        checked: false,
      }))
    );
  }, [categories]);

  const handleExpandCollapse = () => {
    setIsExpand((state) => !state);
  };

  if (!isExpand)
    return (
      <div className="fixed right-0 top-[96px] min-h-[calc(100vh-120px)] z-[1] bg-white border-x-2 border-solid border-grey2 border-y-0 min-w-[24px]">
        <TSButton
          onClick={handleExpandCollapse}
          type="text"
          icon={<LeftOutlined />}
        />
      </div>
    );

  return (
    <>
      <div
        className={classNames(
          "fixed right-0 top-[96px] h-[calc(100vh-120px)] z-[1] w-[280px] "
        )}
      >
        <div className="bg-white p-3">
          <div>
            <TSButton
              onClick={handleExpandCollapse}
              type="text"
              icon={<RightOutlined />}
              className="mr-1"
            />
            <Input
              className="bg-white"
              prefix={<SearchOutlined />}
              placeholder={t("search")}
              allowClear
              onChange={onSearch}
              style={{ width: 200 }}
            />
          </div>
          <div>
            <TSButton
              type="default"
              className="text-grey5 font-medium w-[232px] mt-[16px] text-[12px] "
              icon={<TagOutlined />}
              onClick={handleToggleCategory}
            >
              {t("Category")}{" "}
              {checkedList.length > 0 &&
                `(${checkedList.filter((item) => item.checked).length})`}
            </TSButton>
          </div>
        </div>
        <div className="bg-white">{moduleList}</div>
      </div>
      <Modal
        title={
          <span className="flex justify-center items-center fs-[12px] font-normal">
            <span className="mr-2">
              <TagOutlined />
            </span>
            {t("Select category")}
          </span>
        }
        open={isOpenModal}
        onCancel={handleCloseCategory}
        width="360px"
        footer={
          <div className="w-full flex justify-between items-center">
            <TSButton
              type="text"
              className="text-white font-medium text-[12px] flex-1 bg-[#0f1827] uppercase"
              onClick={handleClickFilter}
            >
              {t("OK")}
            </TSButton>
          </div>
        }
      >
        <div className="h-[290px] overflow-auto">
          <div className="pr-2">
            <Input
              id="input-affix-wrapper-filter"
              placeholder="Find category"
              bordered={false}
              className="bg-[#EAEAEA] focus:bg-[#EAEAEA] hover:bg-[#EAEAEA] my-4"
              prefix={<SearchOutlined color="#949494" />}
              value={searchCategoryInput}
              onChange={handleChangeSearchCategoryInput}
            />
          </div>
          {handleRenderListCategory.length ? (
            handleRenderListCategory.map((item) => {
              return (
                <div className="block mb-[12px]" key={item.id}>
                  <Checkbox
                    checked={
                      checkedList.find(
                        (checkedItem) => checkedItem.value === item.id
                      )?.checked
                    }
                    onChange={onChangeCheckbox(item.id)}
                  >
                    {item.name}
                  </Checkbox>
                </div>
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Modal>
    </>
  );
}
