import type { MenuProps } from "antd";
import { Layout, Menu, Tooltip } from "antd";
import { AuthStatus } from "app/containers/Auth/Login";
import {
  BuildingIcon,
  GraduationCap,
  LayerGroup,
  MeetingBoard,
  SharpInsight,
  TagAlt,
  UserAlts,
  Vector,
} from "app/icons";
import { CONS_COLOR } from "common/constants";
import CompanyContext, { CompanyContextType } from "context/CompanyContext";
import i18n from "i18n";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./index.css";
import { useSidebarContext } from "context/SidebarContext";
import { AngleDoubleLeft } from "app/icons/AngleDoubleLeft";
import { useGetAccountInfoQuery } from "hooks/useGetAccountInfoQuery";

const { Sider } = Layout;

const items: MenuProps["items"] = [
  {
    key: "manage",
    label: "Manage",
    className: "mx-[12px]",
    type: "group",
    children: [
      {
        key: "1",
        icon: React.createElement(BuildingIcon),
        className: "text-white",
        label: <Link to={"company"}>{i18n.t("Companies")}</Link>,
      },
      {
        key: "2",
        className: "text-white",
        icon: React.createElement(GraduationCap),
        label: <Link to={"program"}>{i18n.t("Programs")}</Link>,
      },
      {
        key: "3",
        className: "text-white",
        icon: React.createElement(MeetingBoard),
        label: <Link to={"class"}>{i18n.t("Classes")}</Link>,
      },
      {
        key: "4",
        className: "text-white",
        icon: React.createElement(LayerGroup),
        label: <Link to={"module"}>{i18n.t("Modules")}</Link>,
      },
      {
        key: "5",
        className: "text-white",
        icon: React.createElement(TagAlt),
        label: <Link to={"category"}>{i18n.t("Categories")}</Link>,
      },
      {
        key: "6",
        className: "text-white",
        icon: React.createElement(UserAlts),
        label: <Link to={"participant"}>{i18n.t("Participants")}</Link>,
      },
    ],
  },
  {
    key: "analyze",
    label: "Analyze",
    type: "group",
    className: "mt-[24px] mx-[12px]",
    children: [
      {
        key: "7",
        className: "text-white",
        icon: React.createElement(Vector),
        label: <Link to={"report"}>{i18n.t("Reports")}</Link>,
      },
      {
        key: "8",
        className: "text-white",
        icon: React.createElement(SharpInsight),
        label: <Link to={"insight"}>{i18n.t("Insights")}</Link>,
      },
    ],
  },
  {
    key: "coaching",
    label: "Coaching",
    type: "group",
    className: "mt-[24px] mx-[12px]",
    children: [
      {
        key: "9",
        className: "text-white",
        icon: React.createElement(MeetingBoard),
        label: <Link to={"masterclass"}>{i18n.t("Masterclass")}</Link>,
      },
    ],
  },
];

const coachItems: MenuProps["items"] = [
  {
    key: "manage",
    label: "Manage",
    className: "mx-[12px]",
    type: "group",
    children: [
      {
        key: "1",
        icon: React.createElement(BuildingIcon),
        className: "text-white",
        label: <Link to={"company"}>{i18n.t("Companies")}</Link>,
      },
      {
        key: "3",
        className: "text-white",
        icon: React.createElement(MeetingBoard),
        label: <Link to={"class"}>{i18n.t("Classes")}</Link>,
      },
    ],
  },
  {
    key: "coaching",
    label: "Coaching",
    type: "group",
    className: "mt-[24px] mx-[12px]",
    children: [
      {
        key: "9",
        className: "text-white",
        icon: React.createElement(MeetingBoard),
        label: <Link to={"masterclass"}>{i18n.t("Masterclass")}</Link>,
      },
    ],
  },
];

export default function App() {
  const location = useLocation();
  const { t } = useTranslation();
  const { isAdmin, isLoading } = useGetAccountInfoQuery();
  const { companySelected } = useContext(CompanyContext) as CompanyContextType;
  const { isSiderVisible, isToggleIconDocked } = useSidebarContext();

  const handleSelectedKey = () => {
    const locationPathName = location.pathname;
    switch (true) {
      case locationPathName.includes("/company"): {
        return "1";
      }
      case locationPathName.includes("/program"): {
        return "2";
      }
      case locationPathName.includes("/class"): {
        return "3";
      }
      case locationPathName.includes("/module"): {
        return "4";
      }
      case locationPathName.includes("/category"): {
        return "5";
      }
      case locationPathName.includes("/participant"): {
        return "6";
      }
      case locationPathName.includes("/report"): {
        return "7";
      }
      case locationPathName.includes("/insight"): {
        return "8";
      }
      case locationPathName.includes("/masterclass"): {
        return "9";
      }
      default:
        return "1";
    }
  };

  const getUserItems = () => {
    if (isLoading) return [];

    if (!isAdmin) return coachItems;

    return items;
  };

  return (
    <Layout hasSider>
      <Sider
        width={isSiderVisible.value ? 224 : 0}
        style={{
          backgroundColor: CONS_COLOR.PRIMARY,
          minHeight: "100vh",
          transition: "all 0.3s ease-out",
        }}
        onMouseEnter={isSiderVisible.onTrue}
        onMouseLeave={() => {
          if (!isToggleIconDocked.value) {
            isSiderVisible.onFalse();
          }
        }}
      >
        <div className="flex flex-col h-full">
          {/* Menu section */}
          <div>
            <div className="p-[52px] relative">
              <div
                onClick={() => {
                  if (isToggleIconDocked.value) {
                    isSiderVisible.onFalse();
                  }
                  isToggleIconDocked.onToggle();
                }}
                className={`flex items-center justify-center cursor-pointer w-8 h-8 ease-out transform duration-300 absolute top-8 ${
                  !isToggleIconDocked.value ? "left-8" : "left-44 rotate-180"
                }`}
              >
                <AngleDoubleLeft className="absolute cursor-pointer" />
              </div>

              <img src="/images/logo_blanc 1.png" alt="logo" />
            </div>
            <div className="mx-5 mb-[20px] px-5 text-white text-md">
              <div className="text-[#ffffffa6] py-2 truncate">
                <span>{t("SELECTED COMPANY")}</span>
              </div>

              <Tooltip
                title={companySelected ? companySelected?.name : ""}
                overlayInnerStyle={{
                  background: "primary",
                  borderRadius: "2rem",
                  border: "2px solid white",
                  padding: ".6rem .6rem",
                }}
              >
                <div className="h-[40px] mt-[3px] flex items-center cursor-pointer">
                  <BuildingIcon />
                  <p className="text-ellipsis overflow-hidden w-[110px] whitespace-nowrap ml-[10px]">
                    {companySelected ? companySelected?.name : ""}
                  </p>
                </div>
              </Tooltip>
            </div>
            <Menu
              theme="dark"
              mode="inline"
              className="bg-primary max-h-[calc(100vh-410px)] overflow-y-auto scrollable-menu"
              selectedKeys={[handleSelectedKey()]}
              items={getUserItems()}
            />
          </div>

          {/* Auth status section */}
          <div className="text-grey bg-primary px-[20px] py-[16px] mt-auto">
            <div className="flex border-t border-0 pt-[16px] border-solid border-[#344055]">
              <AuthStatus />
            </div>
          </div>
        </div>
      </Sider>
      <Layout className="duration-300 ease-out min-h-[100vh]">
        <Outlet />
      </Layout>
    </Layout>
  );
}
