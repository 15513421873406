import type { ChartOptions } from "chart.js";
import {
  Chart as ChartJS,
  Filler,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { IRadarComponent } from "./RadarComponent.d";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip);

const options: ChartOptions<"radar"> = {
  scales: {
    r: {
      grid: {
        circular: true,
      },
      beginAtZero: true,
      ticks: {
        display: false, // Remove vertical numbers
      },
      pointLabels: {
        color: "#454545",
        font: {
          size: 12,
          weight: 600,
        },
        padding: 7,
      },
    },
    // angleLines: {
    //   display: false,
    // },
  },
  plugins: {
    legend: {
      display: false,
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 24,
          weight: 500,
        },
      },
    },
    subtitle: {
      font: {
        size: 24,
        weight: 500,
      },
    },
    colors: {
      enabled: true,
    },
    decimation: {},
    filler: {},
    title: {
      font: {
        size: 24,
        weight: 500,
      },
    },
  },
};

export default function RadarComponent({ data }: IRadarComponent) {
  return (
    <Radar
      data={{
        labels: ["Rouge", "Vert", "Jaune", "Bleu"],
        datasets: [
          {
            data: data,
            backgroundColor: "#ffae06b3",
            borderColor: "#FFAE06",
            borderWidth: 1,
            pointRadius: 0,
            tension: 0.2,
          },
        ],
      }}
      options={options}
    />
  );
}
