import { CloseOutlined } from "@ant-design/icons";
import { Button, Popover, Radio, RadioChangeEvent, Space } from "antd";
import ArrowDownSVG from "app/icons/ArrowDown";
import classNames from "classnames";
import { useState } from "react";

const CircleColor = ({
  name,
  isCompleted,
  onChange: onChangeProps,
}: {
  name: string,
  isCompleted: boolean;
  onChange: (value: boolean) => void;
}) => {
  //   const [value, setValue] = useState(true);
  const [clicked, setClicked] = useState(false);

  const handleClickChange = (open: boolean) => {
    setClicked(open);
  };

  const hide = () => {
    setClicked(false);
  };

  const onChange = (e: RadioChangeEvent) => {
    // setValue(e.target.value);
    onChangeProps(e.target.value);
  };

  const content = (
    <div className="">
      <Button
        className="absolute right-[8px] top-[8px]"
        onClick={hide}
        type="text"
        icon={<CloseOutlined />}
      ></Button>
      <Radio.Group onChange={onChange} value={isCompleted}>
        <Space direction="vertical">
          <Radio value={true}>Completed</Radio>
          <Radio value={false}>Not completed</Radio>
        </Space>
      </Radio.Group>
    </div>
  );

  return (
    <Popover
      overlayClassName="w-[200px] circle-popover"
      content={content}
      title={name}
      trigger="click"
      open={clicked}
      onOpenChange={handleClickChange}
    >
      <div
        className={classNames(
          "rounded-full w-[24px] h-[24px] text-grey5 flex items-center justify-center cursor-pointer border border-solid",
          isCompleted
            ? "bg-[#6FD980] border-[#5AB568]"
            : "bg-grey9 border-[#C2C2C2]"
        )}
      >
        <ArrowDownSVG />
        {/* <DownOutlined /> */}
      </div>
    </Popover>
  );
};

export default CircleColor;
