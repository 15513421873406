import { IRequestNewCategory } from "interfaces";
import { axiosClient } from "./apiClient";

const categoryApi = {
  getCategories() {
    const url = "/categories/list";
    return axiosClient.get(url);
  },
  newCategory(params: IRequestNewCategory) {
    const url = "/categories";
    return axiosClient.post(url, params);
  },
  deleteCategory(categoryId: string) {
    const url = `/categories/${categoryId}`;
    return axiosClient.delete(url);
  },
};

export default categoryApi;
