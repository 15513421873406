import { PlusOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Col, Layout, Row } from "antd";
import classApi from "api/class";
import FilterComponent from "app/components/FilterGroup/FilterComponent";
import TSButton from "app/components/TSButton";
import { AxiosResponse } from "axios";
import CompanyContext, { CompanyContextType } from "context/CompanyContext";
import { IResponseGetClass } from "interfaces/IClass";
import { ChangeEvent, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ClassList from "./ClassList";
import ModalAdd from "./ModalAdd";
import SidebarToggleAction from "app/components/SidebarToggleAction";
import { useGetAccountInfoQuery } from "hooks/useGetAccountInfoQuery";

export default function Class() {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const companyContext = useContext(CompanyContext) as CompanyContextType;
  const classPageRef = useRef<HTMLDivElement>(null);
  const { Header } = Layout;
  const { isAdmin } = useGetAccountInfoQuery();

  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const { data, isLoading } = useQuery<AxiosResponse<IResponseGetClass[]>>({
    queryKey: ["classes", companyContext?.companySelected?.id],
    queryFn: classApi.getClass,
  });

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const [searchValue, setSearchValue] = useState("");

  const filterCompany = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const filter = {
    label: t("Classes"),
    icon: <img src="/images/education.svg" alt="Classes" />,
    filterMethod: filterCompany,
  };

  return (
    <div className="">
      <Header className="px-7 py-8 h-fit leading-none shadow-[0_4px_20px_0_rgba(0,0,0,0.07)]">
        <Row align="stretch">
          <SidebarToggleAction containerType="col" />
          <Col span={4} className="text-lg font-bold">
            {t("Classes")}
          </Col>
          <Col span={11} offset={2} className="uppercase">
            <div className="flex items-center text-sm font-medium">
              <span>{t("Filters")}:</span>
              <div className="ml-5 inline-flex">
                <FilterComponent filterItem={filter} />
              </div>
            </div>
          </Col>

          {isAdmin && (
            <Col span={4} offset={2} className="flex flex-row justify-end">
              <TSButton className="text-base font-medium" icon={<PlusOutlined />} onClick={handleOpenModal}>
                {t("New Class")}
              </TSButton>
            </Col>
          )}
        </Row>
      </Header>
      <div ref={classPageRef}>
        <ClassList
          dataSource={data?.data.filter((item) => item.name.toLowerCase().includes(searchValue)) as IResponseGetClass[]}
          isLoading={isLoading}
          renderListData={() => queryClient.invalidateQueries({ queryKey: ["classes"] })}
        />
      </div>
      {isOpenModal && <ModalAdd handleCloseModal={handleCloseModal} isOpen={isOpenModal} />}
    </div>
  );
}
