import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Col, Layout, Row } from "antd";
import FilterComponent from "app/components/FilterGroup/FilterComponent";
import { AxiosResponse } from "axios";
import { ChangeEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ClassList from "./ClassList";
import coachApi from "api/coach";
import { IResponseGetMasterclass } from "interfaces/IMasterclass";
import "./index.css";
import SidebarToggleAction from "app/components/SidebarToggleAction";

export default function Masterclass() {
  const { t } = useTranslation();
  const classPageRef = useRef<HTMLDivElement>(null);
  const { Header } = Layout;
  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const { data, isLoading } = useQuery<AxiosResponse<IResponseGetMasterclass[]>>({
    queryKey: ["masterclasses"],
    queryFn: coachApi.getClass,
  });

  const [searchValue, setSearchValue] = useState("");

  const filterClassName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const filter = {
    label: t("Classes"),
    icon: <img src="/images/education.svg" alt="Classes" />,
    filterMethod: filterClassName,
  };

  return (
    <div className="h-screen">
      <Header className="px-7 py-8 h-fit leading-none shadow-[0_4px_20px_0_rgba(0,0,0,0.07)]">
        <Row align="stretch">
          <SidebarToggleAction containerType="col" colProps={{ span: 2 }} />
          <Col span={4} className="text-lg font-bold">
            {t("Masterclass")}
          </Col>
          <Col span={10} offset={2} className="uppercase">
            <div className="flex items-center text-sm font-medium">
              <span>{t("Filters")}:</span>
              <div className="ml-5 inline-flex">
                <FilterComponent filterItem={filter} />
              </div>
            </div>
          </Col>
        </Row>
      </Header>
      <div ref={classPageRef}>
        <ClassList
          dataSource={
            data?.data.filter((item) => item.name.toLowerCase().includes(searchValue)) as IResponseGetMasterclass[]
          }
          isLoading={isLoading}
          renderListData={() => queryClient.invalidateQueries({ queryKey: ["masterclasses"] })}
        />
      </div>
    </div>
  );
}
