import { ColumnType } from "antd/es/table";
import { DAY_FORMAT } from "common/constants";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import coachApi from "api/coach";
import { Modal, notification, Popover, Table } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { ThreeDot } from "app/icons/ThreeDot";
import TSButton from "app/components/TSButton";
import { SessionInfoWithIndex } from "./Session";

export interface SessionListProps {
  dataSource: SessionInfoWithIndex[];
  isLoading: boolean;
  renderListData: () => Promise<void>;
  classId: string;
  numberOfParticipants: number;
}

const SessionList = ({ dataSource, isLoading, renderListData, classId, numberOfParticipants }: SessionListProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { confirm } = Modal;

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isShowAction, setIsShowAction] = useState(false);
  const [recordIdSelected, setRecordIndexSelected] = useState("");
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const handleOpenPopover = (newOpen: boolean) => {
    setIsOpenPopover(newOpen);
  };

  const handleDeleteSession = async (index: number) => {
    try {
      await coachApi.deleteSession(classId, index);
      notification.success({
        message: "Success",
        description: "Delete Session Successfully"!,
      });
    } catch (err) {
      notification.error({
        message: "Failed",
        description: "Delete Session Failed"!,
      });
    }
    renderListData();
    setIsLoadingDelete(false);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: t("Delete the session"),
      icon: <ExclamationCircleFilled />,
      content: t("Are you sure to delete this session"),
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk() {
        setIsLoadingDelete(true);
        handleDeleteSession(Number(recordIdSelected));
      },
    });
  };

  const columns: ColumnType<SessionInfoWithIndex>[] = [
    {
      title: <span className="text-[12px] font-normal">{t("sessions").toUpperCase()}</span>,
      dataIndex: "name",
      key: "name",
      width: "30%",
      sorter: (a: SessionInfoWithIndex, b: SessionInfoWithIndex) => a.name.localeCompare(b.name),
      render: (text: any, record: SessionInfoWithIndex) => {
        return (
          <div className="w-full inline-flex justify-between items-center relative">
            <div className="inline-flex items-center">
              <div
                className="w-2 h-2 rounded mr-2 absolute"
                style={{
                  backgroundColor: "black",
                }}
              ></div>
              <span className="ml-4">{text}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: <span className="text-[12px] font-normal">{t("Creation Date").toUpperCase()}</span>,
      dataIndex: "date",
      key: "date",
      width: "30%",
      sorter: (a: SessionInfoWithIndex, b: SessionInfoWithIndex) =>
        new Date(a.date).getTime() - new Date(b.date).getTime(),
      render: (text: any, record: SessionInfoWithIndex) => {
        return (
          <div className="w-full inline-flex justify-between items-center">
            <div className="inline-flex items-center">
              <span>{dayjs(text).format(DAY_FORMAT)}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: <span className="text-[12px] font-normal">{t("Participants").toUpperCase()}</span>,
      dataIndex: "analysis",
      key: "analysis",
      width: "20%",
      render: (value: string[], record: SessionInfoWithIndex, index: number) => {
        return (
          <div className="w-full inline-flex justify-between items-center">
            <div className="flex w-[90%] items-center">
              <div className="inline-flex items-center mr-2 ">
                <div className="whitespace-nowrap">
                  {numberOfParticipants ? `${value?.filter(Boolean)?.length}/${numberOfParticipants}` : 0}
                </div>
              </div>
            </div>
            {isShowAction && Number(recordIdSelected) === index && (
              <Popover
                content={
                  <div className="inline-flex w-full justify-between">
                    <TSButton className="w-[45%]">{t("Edit")}</TSButton>
                    <TSButton className="w-[45%]" onClick={showDeleteConfirm}>
                      {t("Delete")}
                    </TSButton>
                  </div>
                }
                title={t("Action")}
                trigger="hover"
                open={isOpenPopover}
                onOpenChange={handleOpenPopover}
              >
                <ThreeDot data-testId="threedot" className="cursor-pointer" />
              </Popover>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        className="min-h-[calc(100vh-96px)]"
        loading={isLoading || isLoadingDelete}
        onRow={(record) => ({
          onClick: () => {
            if (!isOpenPopover) {
              navigate(record.id);
            }
          },
          onMouseEnter: () => {
            setRecordIndexSelected(record.id);
            setIsShowAction(true);
          },
          onMouseLeave: () => {
            setRecordIndexSelected("");
            setIsShowAction(false);
            setIsOpenPopover(false);
          },
        })}
        rowKey={(record) => record.id}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 10,
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export default SessionList;
