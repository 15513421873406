import { Form, Input } from "antd";
import companyApi from "api/company";
import TSButton from "app/components/TSButton";
import TSModal from "app/components/TSModal/TSModal";
import {
  NAME_FEATURE,
  RULE_FORM_ITEM_NAME,
  TYPE_NOTIFICATION,
} from "common/constants";
import { notificationError, notificationSuccess } from "common/helper";
import { IResponseGetInfoCompany } from "interfaces";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { IFormValueModalEdit, IModalEdit } from "./ModalEdit.d";

const ModalEdit = ({
  recordSelected,
  renderListData,
  handleCloseModal,
  isOpen,
}: IModalEdit) => {
  type CompanySelectedType = Omit<IResponseGetInfoCompany, "owner">;
  const { t } = useTranslation();
  const [companySelected, setCompanySelected] =
    useState<CompanySelectedType>(recordSelected);
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    setCompanySelected(recordSelected);
  }, [recordSelected]);

  const handleCancel = () => {
    form.resetFields();
    setConfirmLoading(false);
    handleCloseModal();
    setCompanySelected(recordSelected);
  };

  const onFinish = async (values: IFormValueModalEdit) => {
    const { name } = values;
    setConfirmLoading(!confirmLoading);
    try {
      await companyApi.editCompany(companySelected?.id || "", {
        name: name.trim(),
      });
      handleCloseModal();
      setConfirmLoading(false);
      form.resetFields();
      renderListData();
      notificationSuccess(TYPE_NOTIFICATION.UPDATE, NAME_FEATURE.COMPANY);
    } catch (err: any) {
      const { status } = err.response;
      notificationError(status as string);
      setConfirmLoading(false);
    }
  };

  return (
    <TSModal
      title={t("Edit company name")}
      open={isOpen}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width="650px"
    >
      <Form
        form={form}
        layout="inline"
        requiredMark={true}
        onFinish={onFinish}
        className="justify-between"
        fields={[
          {
            name: ["name"],
            value: companySelected?.name,
          },
        ]}
      >
        <Form.Item
          label={t("Company name")}
          name={t("name")}
          required
          rules={RULE_FORM_ITEM_NAME(t("Company name"))}
        >
          <Input
            className="w-[250px]"
            placeholder={t("Company name")}
            onChange={(e) =>
              setCompanySelected({ ...companySelected, name: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item>
          <TSButton
            type="primary"
            htmlType="submit"
            loading={confirmLoading}
            className="px-8"
          >
            {t("OK")}
          </TSButton>
        </Form.Item>
      </Form>
    </TSModal>
  );
};

export default ModalEdit;
