import { Button } from "antd";
import "./BackIconButton.css";
import { ArrowLeft } from "app/icons/ArrowLeft";

export interface IBackIconButtonProps {
  onClick: VoidFunction;
  className?: string;
}

const BackIconButton = ({ onClick, className }: IBackIconButtonProps) => {
  return (
    <Button
      type="ghost"
      shape="default"
      icon={<ArrowLeft />}
      className={`custom-back-btn flex justify-center ${className}`}
      onClick={onClick}
    />
  );
};

export default BackIconButton;
