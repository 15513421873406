import { useBoolean } from "hooks/useBoolean";
import useWindowSize from "hooks/WindowResize";
import { memo, useEffect, useRef, useState } from "react";
import {
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  PolarAngleAxis,
} from "recharts";

interface IChartData {
  name?: string;
  value?: number | string;
  rangeValue?: { min: number; max: number; median: number };
  fill?: string;
  minimum?: number;
  maximum?: number;
}

interface ISpeedMeterChartProps {
  hasRangeNeedle?: boolean;
  label?: string;
  innerRadius?: number;
  outerRadius?: number;
  needleColor?: string;
  data?: IChartData;
}

interface INeedleProps {
  cx: number;
  cy: number;
  outerRadius: number;
  innerRadius: number;
  color: string;
  strokeWidth: number;
}

const RADIAN = Math.PI / 180;

const calcAngle = (value: number) => {
  return 180.0 * (1 - value / 100);
};

const drawRangeNeedle = ({
  chartData,
  cx,
  cy,
  outerRadius,
  innerRadius,
  color,
  strokeWidth = 12,
}: {
  chartData?: IChartData & {
    rateValue: number;
  };
} & INeedleProps) => {
  const { rangeValue, maximum = 0 } = chartData || {};

  const { min = 0, max = 0 } = rangeValue || {};

  const startValue = (min / maximum) * 100;
  const endValue = (max / maximum) * 100;

  const startAngle = calcAngle(startValue);
  const endAngle = calcAngle(endValue);

  const lineLength = (outerRadius + innerRadius) / 3 - 8;
  const arcRadius = lineLength - 3;

  // Start point (x1, y1) is at the center of the circle
  const x1 = cx;
  const y1 = cy;

  // End points for the start and end lines
  const startX2 = x1 + lineLength * Math.cos(-RADIAN * startAngle);
  const startY2 = y1 + lineLength * Math.sin(-RADIAN * startAngle);

  const endX2 = x1 + lineLength * Math.cos(-RADIAN * endAngle);
  const endY2 = y1 + lineLength * Math.sin(-RADIAN * endAngle);

  // Start and end points for the arc, using a smaller radius
  const arcStartX = x1 + arcRadius * Math.cos(-RADIAN * startAngle);
  const arcStartY = y1 + arcRadius * Math.sin(-RADIAN * startAngle);

  const arcEndX = x1 + arcRadius * Math.cos(-RADIAN * endAngle);
  const arcEndY = y1 + arcRadius * Math.sin(-RADIAN * endAngle);

  // Large arc flag, should be 1 if the angle is more than 180 degrees
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  return (
    <>
      <path
        d={`
              M ${arcStartX},${arcStartY}
              L ${startX2},${startY2}
              A ${arcRadius},${arcRadius} 0 ${largeArcFlag},1 ${arcEndX},${arcEndY}
              L ${x1},${y1}
              Z
            `}
        fill="#F4C542"
        stroke="none"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <line
        x1={x1}
        y1={y1}
        x2={startX2}
        y2={startY2}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <line
        x1={x1}
        y1={y1}
        x2={endX2}
        y2={endY2}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </>
  );
};

const drawNeedle = ({
  chartData,
  cx,
  cy,
  outerRadius,
  innerRadius,
  color,
  strokeWidth,
}: {
  chartData: IChartData & {
    rateValue: number;
  };
} & INeedleProps) => {
  const value = chartData ? chartData.rateValue : 0;
  const angle = calcAngle(value);

  const length = (outerRadius + innerRadius) / 3 - 8;

  // Start point (x1, y1) is at the center of the circle
  const x1 = cx;
  const y1 = cy - 5;

  // End point (x2, y2) is calculated based on the angle and length
  const x2 = x1 + length * Math.cos(-RADIAN * angle);
  const y2 = y1 + length * Math.sin(-RADIAN * angle);

  return (
    <>
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </>
  );
};

const SpeedMeterChart = ({
  hasRangeNeedle,
  label,
  innerRadius = 50,
  outerRadius = 70,
  needleColor = "#f8f9fa",
  data,
}: ISpeedMeterChartProps) => {
  const radialBarRef = useRef<any>(null);
  const [cx, setCx] = useState(0);
  const [cy, setCy] = useState(0);
  const windowSize = useWindowSize();

  const delayShow = useBoolean(true);

  useEffect(() => {
    if (radialBarRef.current) {
      const rect = radialBarRef.current.container.getBoundingClientRect();
      setCx(rect.width / 2);
      setCy(rect.height / 2);
      delayShow.onFalse();
    }
  }, [radialBarRef.current, hasRangeNeedle, data, windowSize]);

  const { value, rangeValue, minimum = 0, maximum = 0 } = data || {};
  const { min = 0, max = 0 } = rangeValue || {};

  const chartData = {
    ...data,
    rateValue: ((value as number) / maximum) * 100,
  };

  // For Range needle chart
  const minRangeAxis = (min / maximum) * -100;
  const maxRangeAxis = 100 + minRangeAxis;

  const renderLabel = (
    <>
      <text
        x={cx}
        y={cy + 30}
        textAnchor="middle"
        fill="#fff"
        fontSize="28"
        fontWeight="bold"
      >
        {hasRangeNeedle ? `${min} - ${max}` : value}
      </text>
      <text x={cx} y={cy + 50} textAnchor="middle" fill="#fff" fontSize="16">
        {label}
      </text>
    </>
  );

  return (
    <>
      {data && (
        <ResponsiveContainer
          width="100%"
          height="100%"
          style={{
            opacity: delayShow.value ? 0 : 1,
          }}
        >
          <RadialBarChart
            ref={radialBarRef}
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            barSize={15}
            data={[chartData]}
            startAngle={180}
            endAngle={0}
          >
            <PolarAngleAxis
              type="number"
              domain={hasRangeNeedle ? [minRangeAxis, maxRangeAxis] : [0, 100]}
              angleAxisId={0}
              tick={false}
            />
            <RadialBar background dataKey="rateValue" cornerRadius={5} />
            {hasRangeNeedle
              ? drawRangeNeedle({
                  chartData,
                  cx,
                  cy,
                  outerRadius,
                  innerRadius,
                  color: needleColor,
                  strokeWidth: 6,
                })
              : drawNeedle({
                  chartData,
                  cx,
                  cy,
                  outerRadius,
                  innerRadius,
                  color: needleColor,
                  strokeWidth: 10,
                })}
            {renderLabel}
          </RadialBarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default memo(SpeedMeterChart);
