const SharpInsightSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M17.5 6.66675C16.2916 6.66675 15.6166 7.86675 15.8916 8.75841L12.9333 11.7251C12.6833 11.6501 12.3166 11.6501 12.0666 11.7251L9.94165 9.60008C10.225 8.70842 9.54998 7.50008 8.33331 7.50008C7.12498 7.50008 6.44165 8.70008 6.72498 9.60008L2.92498 13.3917C2.03331 13.1167 0.833313 13.7917 0.833313 15.0001C0.833313 15.9167 1.58331 16.6667 2.49998 16.6667C3.70831 16.6667 4.38331 15.4667 4.10831 14.5751L7.89998 10.7751C8.14998 10.8501 8.51665 10.8501 8.76665 10.7751L10.8916 12.9001C10.6083 13.7917 11.2833 15.0001 12.5 15.0001C13.7083 15.0001 14.3916 13.8001 14.1083 12.9001L17.075 9.94175C17.9666 10.2167 19.1666 9.54175 19.1666 8.33341C19.1666 7.41675 18.4166 6.66675 17.5 6.66675Z" />
    <path d="M12.5 7.5L13.2833 5.775L15 5L13.2833 4.225L12.5 2.5L11.7333 4.225L9.99998 5L11.7333 5.775L12.5 7.5ZM2.91665 9.16667L3.33331 7.5L4.99998 7.08333L3.33331 6.66667L2.91665 5L2.49998 6.66667L0.833313 7.08333L2.49998 7.5L2.91665 9.16667Z" />
  </svg>
);
export default SharpInsightSVG;
