import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  DatePicker,
  Form,
  Input,
  Select,
  SelectProps,
  notification,
} from "antd";
import classApi from "api/class";
import programApi from "api/program";
import TSButton from "app/components/TSButton";
import TSModal from "app/components/TSModal/TSModal";
import { AxiosResponse } from "axios";
import { DAY_FORMAT, RULE_FORM_ITEM_NAME } from "common/constants";
import { LIGHT_GRAY } from "common/constants/color.constant";
import CompanyContext, { CompanyContextType } from "context/CompanyContext";
import dayjs from "dayjs";
import { NewParticipantsInfo } from "interfaces/IClass";
import { IResponseGetProgram } from "interfaces/IProgram";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalAddExistingParticipant from "./ModalAddExistingParticipant";
import ModalAddNewParticipant from "./ModalAddNewParticipant";
import ModalUploadParticipant from "./ModalUploadParticipant";

export interface IModalAdd {
  handleCloseModal: () => void;
  isOpen: boolean;
}

export interface FormData {
  name: string;
  programId: string;
  startDate: string;
}

const ModalAdd: React.FC<IModalAdd> = ({ handleCloseModal, isOpen }) => {
  const { t } = useTranslation();
  const companyContext = useContext(CompanyContext) as CompanyContextType;
  const [isOpenNewParticipantModal, setIsOpenNewParticipantModal] =
    useState(false);
  const [isOpenExistingParticipantModal, setIsOpenExistingParticipantModal] =
    useState(false);
  const [isOpenUploadParticipantModal, setIsOpenUploadParticipantModal] =
    useState(false);

  const [newParticipants, setNewParticipants] = useState<NewParticipantsInfo[]>(
    []
  );
  const [existingParticipants, setExistingParticipants] = useState<string[]>(
    []
  );

  const [form] = Form.useForm<FormData>();

  const { data: programs, isLoading } = useQuery<
    AxiosResponse<IResponseGetProgram[]>
  >({
    queryKey: ["programs", companyContext?.companySelected?.id],
    queryFn: programApi.getPrograms,
  });

  const programOptions: SelectProps["options"] = programs?.data.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: classApi.createClass,
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: t("Create Class Successfully!"),
      });
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["classes"] });
      handleCloseModal();
    },
    onError: () => {
      notification.error({
        message: "Failed",
        description: "Create Class Failed"!,
      });
    },
  });

  const handleCreate = useCallback(
    (values: FormData) => {
      createMutation.mutate({
        _class: {
          company_id: companyContext?.companySelected?.id,
          program_id: values.programId,
          starting_date: dayjs(values.startDate).format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
          name: values.name,
          participant_ids: existingParticipants,
        },
        new_participants_info: newParticipants,
      });
    },
    [
      companyContext?.companySelected?.id,
      createMutation,
      existingParticipants,
      newParticipants,
    ]
  );

  const handleOpenAddExistingParticipant = () => {
    setIsOpenExistingParticipantModal(true);
  };

  const handleCloseAddExistingParticipant = () => {
    setIsOpenExistingParticipantModal(false);
  };

  const handleAddExistingParticipants = (data: string[]) => {
    setExistingParticipants(data);
  };

  const handleOpenAddNewParticipant = () => {
    setIsOpenNewParticipantModal(true);
  };

  const handleCloseAddNewParticipant = () => {
    setIsOpenNewParticipantModal(false);
  };

  const handleAddNewParticipants = (data: NewParticipantsInfo[]) => {
    setNewParticipants((prevState) => [...prevState, ...data]);
  };

  const handleOpenUploadParticipant = () => {
    setIsOpenUploadParticipantModal(true);
  };

  const handleCloseUploadParticipant = () => {
    setIsOpenUploadParticipantModal(false);
  };

  const handleCancel = () => {
    form.resetFields();
    handleCloseModal();
  };

  const totalParticipants =
    (existingParticipants.length || 0) + (newParticipants?.length || 0);

  return (
    <>
      <TSModal
        title={t("Create new class")}
        open={isOpen}
        confirmLoading={false}
        onCancel={handleCancel}
      >
        <Form
          validateTrigger="onChange"
          form={form}
          layout="vertical"
          onFinish={handleCreate}
          requiredMark={false}
        >
          <Form.Item
            className="mt-5"
            label={t("Name")}
            name="name"
            required
            rules={RULE_FORM_ITEM_NAME(t("Class name"))}
          >
            <Input
              className={`w-full bg-[${LIGHT_GRAY}]`}
              placeholder={t("Class name")}
            />
          </Form.Item>
          <Form.Item
            className="mt-5"
            name="programId"
            label={t("Program")}
            rules={[{ required: true, message: "Program is required" }]}
          >
            <Select
              loading={isLoading}
              placeholder="Select program"
              options={programOptions}
            ></Select>
          </Form.Item>
          <div className="inline-block">
            {totalParticipants > 0 && (
              <div>
                {t("Total {totalParticipants} participants", {
                  count: totalParticipants,
                })}
              </div>
            )}
            <div className="mt-2">{t("Add participants")}</div>
            {}
            <TSButton
              type="text"
              className="mt-2 text-[12px] leading-[16px] px-2 font-medium bg-grey6 text-grey5 mr-1"
              icon={<PlusOutlined />}
              onClick={handleOpenAddExistingParticipant}
            >
              {t("Existing participants")}
            </TSButton>
            <TSButton
              type="text"
              className="mt-2 text-[12px] leading-[16px] px-2 font-medium bg-grey6 text-grey5 mr-1"
              icon={<PlusOutlined />}
              onClick={handleOpenAddNewParticipant}
            >
              {t("New participants")}
            </TSButton>
            <TSButton
              type="text"
              className="mt-2 text-[12px] leading-[16px] px-2 font-medium bg-grey6 text-grey5"
              icon={<DownloadOutlined />}
              onClick={handleOpenUploadParticipant}
            >
              {t("Import from CSV")}
            </TSButton>
          </div>

          <Form.Item
            name="startDate"
            className="mt-6"
            label={t("Starting date")}
          >
            <DatePicker defaultValue={dayjs(new Date())} format={DAY_FORMAT} />
          </Form.Item>
          <Form.Item className="mt-8 mb-2 py-2">
            <TSButton
              type="primary"
              htmlType="submit"
              loading={createMutation.isLoading}
              className="px-8 h-10 w-full text-base"
            >
              {t("Create class")}
            </TSButton>
          </Form.Item>
        </Form>
      </TSModal>
      <ModalAddExistingParticipant
        isOpen={isOpenExistingParticipantModal}
        handleCloseModal={handleCloseAddExistingParticipant}
        onOk={handleAddExistingParticipants}
      />
      {isOpenNewParticipantModal && (
        <ModalAddNewParticipant
          isOpen={isOpenNewParticipantModal}
          handleCloseModal={handleCloseAddNewParticipant}
          onOk={handleAddNewParticipants}
        />
      )}
      {isOpenUploadParticipantModal && (
        <ModalUploadParticipant
          isOpen={isOpenUploadParticipantModal}
          handleCloseModal={handleCloseUploadParticipant}
          onOk={handleAddNewParticipants}
        />
      )}
    </>
  );
};

export default ModalAdd;
