import axios from "axios";
import { getUserToken } from "common/helper";

export const axiosClient = axios.create({
  baseURL: `https://dev.talk.swiss/api`,
  // baseURL: `https://minhnguyen.free.mockoapp.net/v3/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const user = getUserToken();

    config.headers.Authorization = user?.token ? `Bearer ${user.token}` : "";
    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient?.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);