import { IParamsCreateClass, IParamsUpdateClass } from "interfaces/IClass";
import { axiosClient } from "./apiClient";

const classApi = {
  getClass({ queryKey }: any) {
    const [, companyIdLocal] = queryKey;
    const url = `/classes/list?company_id=${companyIdLocal}`;
    return axiosClient.get(url);
  },
  getClassDetail(id: string) {
    const url = `/classes/${id}`;
    return axiosClient.get(url);
  },
  getClassState() {
    const url = `/classes/available-participant-states`;
    return axiosClient.get(url);
  },
  createClass(params: IParamsCreateClass) {
    const url = `/classes/`;
    return axiosClient.post(url, params);
  },
  deleteClass(id: string) {
    const url = `/classes/${id}`;
    return axiosClient.delete(url);
  },
  updateClass(params: IParamsUpdateClass) {
    const url = `/classes/${params.id}`;
    return axiosClient.put(url, params.body);
  },
};

export default classApi;
