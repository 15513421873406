import { PlusOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Col, Layout, Row } from "antd";
import programApi from "api/program";
import FilterComponent from "app/components/FilterGroup/FilterComponent";
import TSButton from "app/components/TSButton";
import { AxiosResponse } from "axios";
import CompanyContext, { CompanyContextType } from "context/CompanyContext";
import { IResponseGetProgram } from "interfaces/IProgram";
import { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProgramList from "./ProgramList";
import SidebarToggleAction from "app/components/SidebarToggleAction";

export default function Program() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { Header } = Layout;

  // Access the client
  const queryClient = useQueryClient();
  const companyContext = useContext(CompanyContext) as CompanyContextType;

  // Queries
  const { data, isLoading } = useQuery<AxiosResponse<IResponseGetProgram[]>>({
    queryKey: ["programs", companyContext?.companySelected?.id],
    queryFn: programApi.getPrograms,
  });

  const handleClick = () => {
    nav("/program/new");
  };

  const [searchValue, setSearchValue] = useState("");

  const filterCompany = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const filter = {
    label: t("Programs"),
    icon: <img src="/images/meeting-board.svg" alt="Programs" />,
    filterMethod: filterCompany,
  };

  return (
    <div className="">
      <Header className="px-7 py-8 h-fit leading-none shadow-[0_4px_20px_0_rgba(0,0,0,0.07)]">
        <Row align="middle">
          <SidebarToggleAction colProps={{ span: 1 }} containerType="col" />
          <Col span={4} className="text-lg font-bold">
            {t("Programs")}
          </Col>

          <Col span={11} offset={2} className="uppercase">
            <div className="flex items-center text-sm font-medium">
              <span>{t("Filters")}:</span>
              <div className="ml-5 inline-flex">
                <FilterComponent filterItem={filter} />
              </div>
            </div>
          </Col>
          <Col span={4} offset={2} className="flex flex-row-reverse">
            <TSButton
              className="text-base font-medium"
              icon={<PlusOutlined />}
              onClick={handleClick}
            >
              {t("New Program")}
            </TSButton>
          </Col>
        </Row>
      </Header>
      <div className="">
        <ProgramList
          dataSource={
            data?.data.filter((item) =>
              item.name?.toLowerCase().includes(searchValue)
            ) as IResponseGetProgram[]
          }
          isLoading={isLoading}
          renderListData={() =>
            queryClient.invalidateQueries({ queryKey: ["programs"] })
          }
        />
      </div>
    </div>
  );
}
