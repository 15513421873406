import dayjs from "dayjs";
import { IResponseGetParticipantInfo } from "interfaces";

const PersonalInfo = ({
  participant,
}: {
  participant: IResponseGetParticipantInfo;
}) => {
  return (
    <div className="flex justify-between bg-white p-6">
      <div className="grid grid-cols-[auto_auto] gap-x-7 gap-y-[6px] bg-white">
        <div className="text-grey7">First name</div>
        <div className="text-darkGrey2">{participant.first_name}</div>
        <div className="text-grey7">Last name</div>
        <div className="text-darkGrey2">{participant.last_name}</div>
        <div className="text-grey7">Email</div>
        <div className="text-darkGrey2">{participant.email}</div>
        <div className="text-grey7">Comply with term?</div>
        <div className="text-darkGrey2">
          {participant.comply_with_terms ? "Yes" : "No"}
        </div>
        <div className="text-grey7">Comply with notifications?</div>
        <div className="text-darkGrey2">
          {participant.comply_with_notifications ? "Yes" : "No"}
        </div>
        <div className="text-grey7">Active</div>
        <div className="text-darkGrey2">
          {participant.active ? "Yes" : "No"}
        </div>
        <div className="text-grey7">Last login</div>
        <div className="text-darkGrey2">
          {dayjs(participant.last_login).format("MM/DD/YYYY")}
        </div>
        <div className="text-grey7">Registered at</div>
        <div className="text-darkGrey2">
          {dayjs(participant.registered_at).format("MM/DD/YYYY")}
        </div>
        <div className="text-grey7">Confirmed at</div>
        <div className="text-darkGrey2">
          {participant.confirmed_at
            ? dayjs(participant.confirmed_at).format("MM/DD/YYYY")
            : null}
        </div>
      </div>
      {/* <div className="grid grid-cols-[auto_auto] gap-x-7 gap-y-[6px] bg-white">
        <div className="text-grey7">Nº personnel</div>
        <div className="text-darkGrey2">91723463</div>
        <div className="text-grey7">Nº AVS</div>
        <div className="text-darkGrey2">7561000000000</div>
        <div className="text-grey7">CCh</div>
        <div className="text-darkGrey2">57 045</div>
        <div className="text-grey7">Office de paiement</div>
        <div className="text-darkGrey2">Syna Neuchâtel</div>
        <div className="text-grey7">Lieu</div>
        <div className="text-darkGrey2">Olten 1 Fächer</div>
        <div className="text-grey7">Téléphone</div>
        <div className="text-darkGrey2">+41 848 002 560</div>
        <div className="text-grey7">Du</div>
        <div className="text-darkGrey2">20/09/2023</div>
        <div className="text-grey7">Au</div>
        <div className="text-darkGrey2">31/10/2023</div>
        <div className="text-grey7">Bailleur de fonds</div>
        <div className="text-darkGrey2">+Assurance-chômage</div>
        <div className="text-grey7">E-mail CP</div>
        <div className="text-darkGrey2">jean_claude_duss@ne.ch</div>
      </div> */}
    </div>
  );
};

export default PersonalInfo;
