import { useQueries } from "@tanstack/react-query";
import coachApi from "api/coach";
import {
  IAnalysis,
  IAudioAnnotations,
  IAudioAnnotationsStatistics,
  IAudioScore,
  IEmotionItem,
  ISilenceData,
} from "interfaces/IAnalysis";
import {
  IResponseGetSessionDetail,
  ParticipantInfoWithStatus,
} from "interfaces/IMasterclass";
import { meanBy } from "lodash";
import React, { useMemo } from "react";
import { EMOTION_TEMPLATE, OUTPUT_TO_EMOTION } from "./index.const";

export const secs2min = (seconds: number) => {
  let mins =
    seconds / 60 <= 9
      ? "0" + Math.floor(seconds / 60).toString()
      : (seconds / 60).toPrecision(2).toString();
  let secs =
    seconds % 60 <= 9
      ? "0" + Math.round(seconds % 60).toString()
      : (seconds % 60).toPrecision(2).toString();
  return mins + ":" + secs;
};

export const average = (array: number[]) => {
  if (array.length === 0) {
    return 0;
  }
  return array.reduce((a, b) => a + b) / array.length;
};

function getMedian(data: number[]): number {
  const mid = Math.floor(data.length / 2);
  const nums = [...data].sort((a, b) => a - b);

  return data.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
}

function getMin(data: number[]): number {
  return Math.min(...data);
}

function getMax(data: number[]): number {
  return Math.max(...data);
}

export const useGetEmotionData = (analysisData?: IAnalysis) => {
  const EMOTIONS_MAPPING: Record<string, string> = {
    Joie: "happy",
    Colère: "angry",
    Surprise: "surprise",
    Peur: "fear",
    Tristesse: "sad",
    Dégout: "disgust",
  };

  const faceMarkerData =
    analysisData?.results.face_markers.data ||
    analysisData?.results.face_emotions?.data;

  const dataAvailable =
    faceMarkerData && Object.values(faceMarkerData)[0]?.emotions !== undefined;

  if (!dataAvailable) return [];

  const faceMarkerArray = Object.values(faceMarkerData).map(
    (item) => item.emotions
  );

  const finalEmotionData: IEmotionItem[] = EMOTION_TEMPLATE.map((val) => ({
    ...val,
    value: meanBy(faceMarkerArray, EMOTIONS_MAPPING[val.name]) * 100,
  }));

  return finalEmotionData;
};

export const useGetVoiceAnalysisData = (analysisData?: IAnalysis) => {
  if (!analysisData?.results.audio_annotations) {
    return {};
  }
  const disturbance = analysisData?.results.audio_annotations.data
    .statistics as IAudioAnnotationsStatistics;

  const respirations = disturbance?.inspiration?.rate_per_minute;

  const silences = disturbance.silences && disturbance.silences.total_num;

  const speaking = disturbance?.speaking && disturbance.speaking.total_seconds;

  const perturbations =
    disturbance.accident?.rate_per_minute +
    disturbance.begaiement?.rate_per_minute;

  const pitchData = analysisData?.results.pitch.data;

  const hertz = {
    min: Math.round(getMin(pitchData.pitch)),
    median: Math.round(getMedian(pitchData.pitch)),
    max: Math.round(getMax(pitchData.pitch)),
  };

  return {
    respirationsData: {
      name: "Respirations",
      value: respirations?.toFixed(2) || 0,
      fill: "#F4C542",
      minimum: 0,
      maximum: 24,
    },
    silencesData: {
      name: "Silences",
      value: silences?.toFixed(2) || 0,
      fill: "#F4C542",
      minimum: 0,
      maximum: 12,
    },
    speakingData: {
      name: "Parlé",
      value: speaking?.toFixed(2) || 0,
      fill: "#F4C542",
      minimum: 0, //Fix me
      maximum: 100, //Fix me
    },
    perturbationsData: {
      name: "Perturbations",
      value: perturbations?.toFixed(2) || 0,
      fill: "#F4C542",
      minimum: 0,
      maximum: 10,
    },
    hertzData: {
      name: "Hertz",
      value: hertz.max - hertz.min,
      rangeValue: hertz || {
        min: 0,
        max: 0,
        median: 0,
      },
      fill: "#F4C542",
      minimum: 0,
      maximum: 600,
    },
  };
};

export const useGetViteseeData = (analysisData?: IAnalysis) => {
  const speechRateData = analysisData?.results.speechrate.data;

  const videoDuration = analysisData?.metadata.duration;

  const viteseeData = React.useMemo(() => {
    let result = [];
    if (!videoDuration || !speechRateData) return [];
    for (let index = 0; index < videoDuration; index++) {
      result.push({
        name: index,
        value: speechRateData?.[0].data?.speechrates?.[index] ?? 0,
      });
    }

    return result;
  }, [videoDuration]);

  if (!speechRateData || speechRateData?.length === 0) {
    return {};
  }

  return {
    viteseeData,
    time: speechRateData[0].data?.time,
    speechrates: speechRateData[0].data?.speechrates,
    videoDuration: videoDuration ? Math.floor(videoDuration) : 0,
  };
};

export const useGetTranscripts = (analysisData?: IAnalysis) => {
  return analysisData?.results.transcription.data
    .map((trans) => trans.transcript)
    .join(" ");
};
export const useGetAudioAnnotationScore = (
  audioData?: IAudioAnnotations,
  silencesData?: ISilenceData
) => {
  if (!audioData || !audioData.data) {
    return [];
  }

  const allowedAudio = ["Respiration", "Perturbation", "Silence"];
  const audioMap: { [key: string]: { color: string; content: string } } = {
    inspiration: { color: "#ffd445", content: "Respiration" },
    accident: { color: "#e14545", content: "Perturbation" },
    silence: { color: "#63cf95", content: "Silence" },
    // speaking: { color: "#7209B7", content: "Parlé" },
  };

  // "accident" =>  "Interférence"
  // "inspiration" => "Respirations"
  // "silence"=>  "silence"
  // "speaking" => "Parlé"

  const audioScores = Object.keys(audioData.data)
    .filter((key) => key !== "statistics")
    .flatMap((key) =>
      (audioData.data[key] as IAudioScore[]).map((obj) => ({
        start: obj.start,
        end: obj.end,
        color: audioMap[obj.vocaltype]?.color || "#3B3C3D",
        content: audioMap[obj.vocaltype]?.content || "",
      }))
    )
    .filter((audio) => allowedAudio.includes(audio.content));

  silencesData?.data.forEach((silence) => {
    const start = silence[0];
    const end = silence[1];
    if (end - start >= 2) {
      audioScores.push({
        start,
        end,
        color: "#63cf95",
        content: "Silence",
      });
    }
  });

  return audioScores;
};

export const useGetParticipantsWithAnalysis = (
  data?: IResponseGetSessionDetail
): ParticipantInfoWithStatus[] => {
  if (data === undefined) {
    return [];
  }
  const { participants, session } = data;

  const notNullIndices = session.analysis
    .map((item, index) => (item !== null ? index : -1))
    .filter((index) => index !== -1);

  const participantsResult = notNullIndices
    .map((index) => {
      return { ...participants[index], analysis: session.analysis[index] };
    })
    .filter((participant) => participant !== undefined);

  return participantsResult;
};

export const useAllAnalysisProgresses = (
  participants: ParticipantInfoWithStatus[]
) => {
  return useQueries({
    queries: participants
      .filter((participant) => participant.analysis)
      .map((participant) => ({
        queryKey: ["analysisProgress", participant.analysis],
        queryFn: () => coachApi.getAnalysisProgress(participant.analysis!),
        enabled: !!participant.analysis,
      })),
  });
};
