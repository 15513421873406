import { IParamsUpdateClass } from "interfaces/IClass";
import { axiosClient } from "./apiClient";
import { IParamsCreateSession, IRequestAddAnalysis } from "interfaces/IMasterclass";
import qs from "qs";

const coachApi = {
  getClass() {
    const url = `/coaches/classes`;
    return axiosClient.get(url);
  },
  getClassSession({ queryKey }: any) {
    const [, classId] = queryKey;
    const url = `/coaches/class/${classId}`;
    return axiosClient.get(url);
  },
  getSessionDetail(classId: string, sessionIndex: number) {
    const url = `/coaches/class/${classId}/${sessionIndex}`;
    return axiosClient.get(url);
  },
  createSession(params: IParamsCreateSession) {
    const url = `/coaches/class/${params.classId}`;
    return axiosClient.post(url, qs.stringify({ session_name: params.sessionName }), {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
  },
  deleteSession(classId: string, sessionIndex: number) {
    const url = `/coaches/class/${classId}/${sessionIndex}`;
    return axiosClient.delete(url);
  },
  //TODO: update when API ready
  updateClass(params: IParamsUpdateClass) {
    const url = `/classes/${params.id}`;
    return axiosClient.put(url, params.body);
  },
  getAnalysisDetail(analysisId: string) {
    const url = `/analysis/${analysisId}`;
    return axiosClient.get(url);
  },
  getAnalysisProgress(analysisId: string) {
    const url = `/analysis/${analysisId}/progress`;
    return axiosClient.get(url);
  },
  addAnalysisToSession(params: IRequestAddAnalysis) {
    const post_url = `/coaches/class/${params.classId}/${params.sessionIndex}/${params.participantIndex}`;

    const form_data = new FormData();
    form_data.append("title", params.title);
    form_data.append("languageCode", "fr-FR");
    form_data.append("video", params.video, "acetest.webm");

    return axiosClient.post(post_url, form_data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};

export default coachApi;
