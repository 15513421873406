import userApi from "api/user";
import { IResponseGetUserInfo } from "interfaces/IUser";
import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { USER_ROLES } from "common/constants/userRole.constant";

export function useGetAccountInfoQuery() {
  const { data, isLoading } = useQuery<AxiosResponse<IResponseGetUserInfo>>({
    queryKey: ["userInfo"],
    queryFn: userApi.getUserInfo,
  });
  const isAdmin = data?.data?.role === USER_ROLES.ADMIN;

  return {
    isAdmin,
    isLoading,
  };
}
