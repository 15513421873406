import React from "react";
import { IVideoBackgroundProps } from "./VideoBackground.d";

const VideoBackground = ({
  videoSource,
  children,
  containerClass,
}: IVideoBackgroundProps) => {
  return (
    <div
      className={`relative w-full h-screen overflow-hidden ${containerClass}`}
    >
      <video
        className="absolute top-1/2 left-1/2 w-full h-full object-cover transform -translate-x-1/2 -translate-y-1/2 z-[0]"
        autoPlay
        loop
        muted
      >
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="h-full w-full flex items-center justify-center absolute z-[1] bg-[rgba(51,55,65,0.43)] drop-shadow-[0_4px_4px_0]">
        {children}
      </div>
    </div>
  );
};

export default VideoBackground;
