import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form, Input, notification } from "antd";
import coachApi from "api/coach";
import TSButton from "app/components/TSButton";
import TSModal from "app/components/TSModal/TSModal";
import { RULE_FORM_ITEM_NAME } from "common/constants";
import { LIGHT_GRAY } from "common/constants/color.constant";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export interface IModalAdd {
  handleCloseModal: () => void;
  isOpen: boolean;
  classId: string;
}

export interface FormData {
  name: string;
}

const ModalAdd: React.FC<IModalAdd> = ({ handleCloseModal, isOpen, classId }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<FormData>();

  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: coachApi.createSession,
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: t("Create Session Successfully!"),
      });
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["class"] });
      handleCloseModal();
    },
    onError: () => {
      notification.error({
        message: "Failed",
        description: t("Create Session Failed!"),
      });
    },
  });

  const handleCreate = useCallback(
    (values: FormData) => {
      createMutation.mutate({
        classId: classId,
        sessionName: values?.name,
      });
    },
    [classId, createMutation]
  );

  const handleCancel = () => {
    form.resetFields();
    handleCloseModal();
  };

  return (
    <>
      <TSModal title={t("Create new session")} open={isOpen} confirmLoading={false} onCancel={handleCancel}>
        <Form validateTrigger="onChange" form={form} layout="vertical" onFinish={handleCreate} requiredMark={false}>
          <Form.Item
            className="mt-5"
            label={t("Name")}
            name="name"
            required
            rules={RULE_FORM_ITEM_NAME(t("Session name"))}
          >
            <Input className={`w-full bg-[${LIGHT_GRAY}]`} placeholder={t("Session name")} />
          </Form.Item>

          <Form.Item className="mt-8 mb-2 py-2">
            <TSButton
              type="primary"
              htmlType="submit"
              loading={createMutation.isLoading}
              className="px-8 h-10 w-full text-base"
            >
              {t("Create session")}
            </TSButton>
          </Form.Item>
        </Form>
      </TSModal>
    </>
  );
};

export default ModalAdd;
