export * as CONS_COLOR from "./color.constant";
export * from "./localStorage.constant";
export * from "./regexPattern.constant";
export * from "./ruleValidation.constant";

export const API_KEY_NAME = "name";

export const DAY_FORMAT = "DD.MM.YY";

export enum TYPE_NOTIFICATION {
  DELETE = "Delete",
  CREATE = "Create",
  UPDATE = "Update",
}

export enum NAME_FEATURE {
  CATEGORY = "Category",
  PARTICIPANT = "Participant",
  MODULE = "Module",
  COMPANY = "Company",
}
