import { CloseOutlined } from "@ant-design/icons";
import TSButton from "app/components/TSButton";
import classNames from "classnames";
import { IModuleItem } from "interfaces/IProgram";
import { isUndefined } from "lodash";
import { useRef, useState } from "react";
import { XYCoord, useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";

export interface ModuleItemProps {
  module: IModuleItem;
  className?: string;
  durationClassName?: string;
  moduleNameClassName?: string;
  columnIndex?: number;
  itemIndex: number;
  onDeleteModule?: (columnIndex: number, itemIndex: number) => void;
  onMoveModule?: (
    dragIndex: number,
    hoverIndex: number,
    columnIndex: number
  ) => void;
  canDrag?: boolean;
}

function ModuleItem({
  module,
  className,
  durationClassName,
  moduleNameClassName,
  columnIndex,
  itemIndex,
  onMoveModule,
  onDeleteModule,
  canDrag,
}: ModuleItemProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "Box",
      canDrag,
      item: {
        ...module,
        columnIndex: columnIndex,
        itemIndex: itemIndex,
      },

      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [canDrag]
  );

  const [{ handlerId }, drop] = useDrop<any, void, { handlerId: any | null }>({
    accept: "Box",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.itemIndex;
      const hoverIndex = itemIndex;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      if (isUndefined(item.columnIndex)) return;

      if (item.columnIndex !== columnIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onMoveModule?.(dragIndex, hoverIndex, columnIndex!);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.itemIndex = hoverIndex;
    },
  });

  const [showCloseBtn, setShowCloseBtn] = useState(false);
  const handleShowCloseBtn = () => {
    if (isUndefined(columnIndex)) return;

    setShowCloseBtn(() => true);
  };
  const handleHideCloseBtn = () => {
    if (isUndefined(columnIndex)) return;

    setShowCloseBtn(() => false);
  };

  const handleDeleteModule = () => {
    if (isUndefined(columnIndex) || isUndefined(onDeleteModule)) return;

    onDeleteModule(columnIndex, itemIndex);
  };

  drag(drop(ref));

  if (!module) return null;

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={{ opacity: isDragging ? 0 : 1 }}
      className={classNames(
        "leading-[16.34px] text-[12px] border-grey3 border border-solid rounded-[5px] relative",
        canDrag && "cursor-move",
        className
      )}
      key={module.id}
      onMouseEnter={handleShowCloseBtn}
      onMouseLeave={handleHideCloseBtn}
    >
      <p className={classNames("whitespace-break-spaces", moduleNameClassName)}>
        {module?.name}
      </p>
      <div className={classNames("absolute", durationClassName)}>
        {module?.duration_in_minutes}:00
      </div>
      {showCloseBtn && !isDragging && canDrag && (
        <div className="absolute top-0 right-0">
          <TSButton
            title={t("Remove")}
            type="text"
            icon={<CloseOutlined />}
            onClick={handleDeleteModule}
          />
        </div>
      )}
    </div>
  );
}

export default ModuleItem;
