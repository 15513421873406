import { useQuery } from "@tanstack/react-query";
import { Modal, Spin } from "antd";
import reportsApi from "api/reports";
import CheckIconSVG from "app/icons/CheckIcon";
import LineChartSVG from "app/icons/LineChartIcon";
import ReportIconSVG from "app/icons/Report";
import { AxiosResponse } from "axios";
import update from "immutability-helper";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelfEvaluateChart } from "./FirstChart";
import { CHART_COLORS } from "./constant";
import { getDefaultColor, getDefaultType } from "./helper";
import { IReportQuery } from "interfaces/IReport";

export type TResponseGetSelfEvaluation = {
  label: string;
  low: string;
  high: string;
  values: number[];
};

export type ChartState = {
  color: string;
  type: string;
};

const SelfEvaluation = ({
  classIDs,
  participantIDs,
}: IReportQuery) => {
  const [modalOpen, setModalOpen] = useState<number | undefined>(undefined);
  const [chartState, setChartState] = useState<ChartState[]>([]);

  const { t } = useTranslation();
  const handleCancel = () => {
    setModalOpen(undefined);
  };

  const handleSelectChartType = (type: string) => () => {
    setChartState((state) =>
      update(state, {
        [modalOpen || 0]: {
          $set: { ...state[modalOpen || 0], type },
        },
      })
    );
  };

  const handleSelectColor = (color: string) => () => {
    setChartState((state) =>
      update(state, {
        [modalOpen || 0]: {
          $set: { ...state[modalOpen || 0], color },
        },
      })
    );
  };

  const { data, isLoading, isSuccess } = useQuery<
    AxiosResponse<TResponseGetSelfEvaluation[]>
  >({
    queryKey: ["self-evaluation", classIDs, participantIDs],
    retry:0, 
    queryFn: () =>
      reportsApi.getSelfEvaluation({
        classIDs,
        participantIDs,
      }),
  });
  useEffect(() => {
    if (!data) return;
    setChartState(
      data.data?.map((_, index) => ({
        color: getDefaultColor(index),
        type: getDefaultType(index),
      }))
    );
  }, [isSuccess, data]);

  if (isLoading)
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-96px)] w-full">
        <Spin size="large" />
      </div>
    );

    if (!isSuccess) {
      return (
        <div className="min-h-[100vh] items-center flex justify-center text-lg font-bold">
          {t("nothing_selected_self_evaluation")}
        </div>
      );
    }

  return (
    <>
      <div className="">
        <div className="bg-greyBg text-grey5 flex-1">
          <div className="bg-greyBg py-[29px] px-6 ">
            <div className="grid grid-cols-3 gap-y-6">
              {data?.data?.map((item, index) => (
                <SelfEvaluateChart
                  index={index}
                  key={index}
                  data={item.values}
                  labels={item.values.map((item) => "")}
                  low={item.low}
                  high={item.high}
                  label={item.label}
                  setChartState={setChartState}
                  setModalOpen={setModalOpen}
                  color={chartState[index]?.color}
                  bar={chartState[index]?.type === "bar"}
                />
              ))}
            </div>
          </div>
        </div>
        <Modal
          open={!isUndefined(modalOpen)}
          title={t("Edit Chart")}
          // onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={245}
        >
          <div className="text-xs font-medium">
            <p className="mb-2 font-medium">Select chart type</p>
            <div
              onClick={handleSelectChartType("line")}
              className="bg-greyBg rounded-md mb-2 w-[140px] h-[32px] px-2 flex justify-between items-center text-xs"
            >
              <span className="text-grey5">
                <span className="mr-2">
                  <LineChartSVG />
                </span>
                Line chart
              </span>
              {chartState[modalOpen || 0]?.type === "line" && (
                <span className="ml-2">
                  <CheckIconSVG />
                </span>
              )}
            </div>
            <div
              onClick={handleSelectChartType("bar")}
              className="bg-greyBg rounded-md mb-2 w-[140px] h-[32px] px-2 flex justify-between items-center text-xs"
            >
              <span className="text-grey5">
                <span className="mr-2">
                  <ReportIconSVG />
                </span>
                Bar chart
              </span>
              {chartState[modalOpen || 0]?.type === "bar" && (
                <span className="ml-2">
                  <CheckIconSVG />
                </span>
              )}
            </div>
            <div className="mt-5 mb-4 text-xs font-medium">Choose a color</div>
            <div className="flex flex-wrap gap-2 mb-4">
              {CHART_COLORS.map((chartColor) => (
                <div
                  key={chartColor}
                  className="rounded-full w-8 h-8 text-white flex justify-center items-center cursor-pointer"
                  style={{ background: chartColor }}
                  onClick={handleSelectColor(chartColor)}
                >
                  {chartState[modalOpen || 0]?.color === chartColor && (
                    <CheckIconSVG />
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SelfEvaluation;
