const MedalSVG = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.575117 6.88079C0.622711 7.00354 0.694077 7.11569 0.785118 7.21079L5.78512 12.2108C5.87836 12.304 5.98905 12.378 6.11087 12.4285C6.23269 12.4789 6.36326 12.5049 6.49512 12.5049C6.76142 12.5049 7.01681 12.3991 7.20512 12.2108C7.29836 12.1176 7.37232 12.0069 7.42278 11.885C7.47324 11.7632 7.49921 11.6327 7.49921 11.5008C7.49921 11.2345 7.39342 10.9791 7.20512 10.7908L3.90512 7.50079H11.4951C11.7603 7.50079 12.0147 7.39543 12.2022 7.2079C12.3898 7.02036 12.4951 6.76601 12.4951 6.50079C12.4951 6.23557 12.3898 5.98122 12.2022 5.79368C12.0147 5.60615 11.7603 5.50079 11.4951 5.50079H3.90512L7.20512 2.21079C7.29885 2.11783 7.37324 2.00723 7.42401 1.88537C7.47478 1.76351 7.50092 1.6328 7.50092 1.50079C7.50092 1.36878 7.47478 1.23807 7.42401 1.11621C7.37324 0.994355 7.29885 0.883756 7.20512 0.790792C7.11215 0.697064 7.00155 0.622668 6.87969 0.571899C6.75783 0.521131 6.62713 0.494993 6.49512 0.494993C6.36311 0.494993 6.2324 0.521131 6.11054 0.571899C5.98868 0.622668 5.87808 0.697064 5.78512 0.790792L0.785118 5.79079C0.694077 5.88589 0.622711 5.99804 0.575117 6.12079C0.4751 6.36425 0.4751 6.63733 0.575117 6.88079Z"
      fill="#F8F8F8"
    />
  </svg>
);
export default MedalSVG;
