import { CloseOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Popover, Select } from "antd";
import classApi from "api/class";
import ArrowDownSVG from "app/icons/ArrowDown";
import CheckIconSVG from "app/icons/CheckIcon";
import { AxiosResponse } from "axios";
import { IResponseGetClassState } from "interfaces/IClass";
import { useState } from "react";
import { getColorByClassState } from "./helper";

const DropdownColor = ({
  classState,
  isRemote,
  onChange: onChangeProps,
  title,
}: {
  classState: string | undefined;
  isRemote: boolean | undefined;
  onChange: (value: string | undefined, isRemote: boolean | undefined) => void;
  title: string;
}) => {
  const { data } = useQuery<AxiosResponse<IResponseGetClassState>>(
    ["classState"],
    classApi.getClassState
  );
  const items = data?.data.map((item) => ({ label: item, value: item }));

  const [clicked, setClicked] = useState(false);

  const handleClickChange = (open: boolean) => {
    setClicked(open);
  };

  const hide = () => {
    setClicked(false);
  };

  const handleChangeClassState = (value: string) => {
    onChangeProps(value, isRemote);
  };
  const handleChangeIsRemote = (value: boolean) => () => {
    onChangeProps(classState, value);
  };

  const content = (
    <div className="">
      <Button
        className="absolute right-[8px] top-[8px]"
        onClick={hide}
        type="text"
        icon={<CloseOutlined />}
      ></Button>
      <div>
        <Select
          placeholder="Select letter"
          value={classState}
          style={{ width: 160 }}
          onChange={handleChangeClassState}
          options={items}
          className="week-select"
          removeIcon={() => <ArrowDownSVG />}
          suffixIcon={<ArrowDownSVG />}
        />
        <div className="mt-4 flex gap-1">
          <div
            className="rounded-full w-8 h-8 text-white flex justify-center items-center cursor-pointer bg-[#FFE304]"
            onClick={handleChangeIsRemote(true)}
          >
            {isRemote && <CheckIconSVG />}
          </div>
          <div
            className="rounded-full w-8 h-8 text-white flex justify-center items-center cursor-pointer bg-[#6FD980]"
            onClick={handleChangeIsRemote(false)}
          >
            {!isRemote && <CheckIconSVG />}
          </div>
        </div>
      </div>
    </div>
  );

  const { color, border } = getColorByClassState(classState, isRemote);

  return (
    <Popover
      overlayClassName="w-[200px] circle-popover"
      content={content}
      title={title}
      trigger="click"
      open={clicked}
      onOpenChange={handleClickChange}
    >
      <Button
        className={"rounded-full w-[84px] h-6 pr-0 pl-2"}
        style={{ background: color, borderColor: border }}
      >
        <div className="flex justify-between mt-[-5px] items-center">
          <span className="text-xs font-bold ">{classState?.charAt?.(0)}</span>
          <ArrowDownSVG />
        </div>
      </Button>
    </Popover>
  );
};

export default DropdownColor;
