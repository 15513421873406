import React, { memo, useState } from "react";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, ResponsiveContainer, PolarRadiusAxis } from "recharts";
import Anger from "assets/img/Anger.svg";
import Disgust from "assets/img/Disgust.svg";
import Fear from "assets/img/Fear.svg";
import Joy from "assets/img/Joy.svg";
import Neutral from "assets/img/Neutral.svg";
import Sadness from "assets/img/Sadness.svg";
import Surprise from "assets/img/Surprise.svg";

import { IEmotionItem } from "interfaces/IAnalysis";
import { max, maxBy, sumBy } from "lodash";
import { useBoolean } from "hooks/useBoolean";
import { Tooltip } from "antd";
import CustomRadarShape from "./CustomRadarShape";

interface IEmotionChartProps {
  data: {
    label: string;
    value: number;
  }[];
}

const icons = [Joy, Surprise, Fear, Sadness, Disgust, Anger];

const CustomLabel = ({ x, y, cx, cy, payload }: any) => {
  const offset = 10;

  const angle = Math.atan2(y - cy, x - cx);

  const newX = x + offset * Math.cos(angle);
  const newY = y + offset * Math.sin(angle);

  return (
    <foreignObject x={newX - 25} y={newY - 20} width={50} height={40}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          borderRadius: "5px",
          color: "#fff",
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        <Tooltip placement="top" title={payload.value}>
          {<img src={icons[payload.index]} alt={payload.value} />}
        </Tooltip>
      </div>
    </foreignObject>
  );
};

const colorPalette = ["#9ea0a0", "#a8a9aa", "#aaacac", "#aeb0b0"];

const EmotionChart = ({ data }: IEmotionChartProps) => {
  const transformedData = data.map((item) => ({
    ...item,
    value: item.value < 10 ? 10 : item.value,
    polygon1: 25,
    polygon2: 50,
    polygon3: 75,
    polygon4: 100,
    fullMark: 100,
  }));
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={transformedData} margin={{ left: 0, bottom: 80 }}>
        <PolarGrid strokeWidth={1} stroke="rgba(123,123,123,0.2)" />
        <PolarAngleAxis dataKey="label" tick={CustomLabel} />
        {/* This for background color */}
        {[1, 2, 3, 4].map((item) => (
          <Radar
            dataKey={`polygon${item}`}
            fill="rgba(197, 197, 197,0.2)"
            stroke="rgba(197, 197, 197,0.3)"
            // isAnimationActive={false}
          />
        ))}

        {/* Main radar chart data */}
        {/* <Radar name="Mike" dataKey="A" stroke="#8884d8" fillOpacity={0.6} /> */}
        <Radar
          name="Mike"
          dataKey="value"
          stroke="rgba(255,212,69,1)"
          strokeWidth={4}
          fill="rgb(255,212,69)"
          fillOpacity={0.2}
          isAnimationActive={true}
          animationBegin={0}
          animationDuration={200}
          animationEasing="linear"
          shape={<CustomRadarShape />}
        />
      </RadarChart>
    </ResponsiveContainer>
    // <ResponsiveContainer width="100%" height={400}>
    //   <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
    //     <PolarGrid />
    //     <PolarAngleAxis
    //       dataKey="subject"
    //       tick={(props) => <CustomLabel {...props} />}
    //     />
    //     {/* <PolarRadiusAxis angle={30} domain={[0, 150]} /> */}
    //     <Radar
    //       name="Mike"
    //       dataKey="A"
    //       stroke="#8884d8"
    //       fill="#8884d8"
    //       fillOpacity={0.6}
    //     />
    //   </RadarChart>
    // </ResponsiveContainer>
  );
};

export default memo(EmotionChart);
