import { FileTextOutlined, SearchOutlined } from "@ant-design/icons";
import { Checkbox, Col, Empty, Input, Layout, Modal, Row, Tabs } from "antd";
import TSButton from "app/components/TSButton";
import ReportIconSVG from "app/icons/Report";
import SelfEvaluationSVG from "app/icons/SelfEvaluation";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SelfEvaluation from "../SelfEvaluation";
import AllReports from "./AllReports";
import { MeetingBoard } from "app/icons";
import { IClassItem } from "interfaces/IClass";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import classApi from "api/class";
import CompanyContext, { CompanyContextType } from "context/CompanyContext";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { isEmpty } from "lodash";
import SidebarToggleAction from "app/components/SidebarToggleAction";
const { Header } = Layout;

function Report() {
  const { t } = useTranslation();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("1");
  const allReportRef = useRef<any>();
  const selfEvaluationRef = useRef<any>();
  const companyContext = useContext(CompanyContext) as CompanyContextType;

  const { data: classes } = useQuery<AxiosResponse<IClassItem[]>>({
    queryKey: ["classes", companyContext?.companySelected?.id],
    queryFn: classApi.getClass,
  });

  const [listFilteredClassIDs, setListFilteredClassIDs] = useState<string[]>([]);

  const [checkedList, setCheckedList] = useState<{ value: string; checked: boolean }[]>([]);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [classSearchValue, setClassSearchValue] = useState("");

  useEffect(() => {
    if (isEmpty(classes)) return;

    setCheckedList(
      classes?.data.map?.((class_) => ({
        value: class_.id,
        checked: false,
      }))
    );
  }, [classes]);

  const handleClick = () => {
    let input = undefined;
    if (activeKey === "1") {
      input = allReportRef.current;
    } else {
      input = selfEvaluationRef.current;
    }
    input.classList.remove("h-[calc(100vh-96px)]");

    if (input) {
      html2canvas(input, {
        scrollX: 1000,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth * ratio, imgHeight * ratio);
        pdf.save(activeKey === "1" ? "all-reports.pdf" : "self-evaluation.pdf");
      });
      input.classList.add("h-[calc(100vh-96px)]");
    }
  };

  const handleFilterClass = (e: ChangeEvent<HTMLInputElement>) => {
    setClassSearchValue(e.target.value.toLowerCase());
  };

  const menuItems = [
    {
      key: "1",
      label: (
        <div className="">
          <span className="mr-4">
            <ReportIconSVG />
          </span>
          {t("All Reports")}
        </div>
      ),
      children: (
        <div ref={allReportRef} className="h-[calc(100vh-96px)] overflow-y-auto scrollbar-w-5px">
          <AllReports classIDs={listFilteredClassIDs} participantIDs={[]} />
        </div>
      ),
      active: location.pathname.includes("/report"),
    },
    {
      key: "2",
      label: (
        <div>
          <span className="mr-4 ">
            <SelfEvaluationSVG />
          </span>
          {t("Self-evaluation")}
        </div>
      ),
      children: (
        <div ref={selfEvaluationRef} className="h-[calc(100vh-96px)] overflow-y-auto scrollbar-w-5px">
          <SelfEvaluation classIDs={listFilteredClassIDs} participantIDs={[]} />
        </div>
      ),
      active: location.pathname.includes("/self-evaluation"),
    },
  ];

  const onTabsChange = (activeKey: string) => {
    setActiveKey(activeKey);
  };

  const handleToggleClassSelection = () => {
    setIsOpenModal((state) => !state);
  };

  const handleCloseClassSelection = () => {
    setCheckedList(
      checkedList.map((item) => ({
        value: item.value,
        checked: listFilteredClassIDs.find((id) => id === item.value) !== undefined,
      }))
    );
    setIsOpenModal(false);
  };

  const onChangeCheckbox = (id: string) => (e: CheckboxChangeEvent) => {
    setCheckedList(
      checkedList.map((item) => ({
        value: item.value,
        checked: item.value === id ? e.target.checked : item.checked,
      }))
    );
  };

  const handleClickFilter = () => {
    if (!checkedList.length) {
      return;
    }

    const checkedListFilter = checkedList.filter((item) => item.checked).map((item) => item.value);

    setListFilteredClassIDs(checkedListFilter);
    setIsOpenModal(false);
  };

  const handleRenderListClasses = useMemo(() => {
    let listClasses: IClassItem[] = classes?.data ?? [];

    return listClasses.filter((class_) => class_.name.toLowerCase().includes(classSearchValue.toLowerCase()));
  }, [classes, classSearchValue]);

  return (
    <>
      <div className="h-[100vh] report-and-self">
        <Header className="px-7 py-8 h-fit z-10 relative leading-none shadow-[0_4px_20px_0_rgba(0,0,0,0.07)]">
          <Row align="middle">
            <SidebarToggleAction containerType="col" />
            <Col span={4} className="text-lg font-bold">
              {activeKey === "1" ? t("Reports") : t("Self-evaluation")}
            </Col>

            <Col span={11} offset={2} className="uppercase">
              <div className="flex items-center text-sm font-medium">
                <span>{t("Filters")}:</span>
                <TSButton
                  type="default"
                  className="flex items-center text-grey5 ms-5 font-medium  text-[12px]"
                  icon={<MeetingBoard />}
                  onClick={handleToggleClassSelection}
                >
                  {t("Classes")} {`(${listFilteredClassIDs.length})`}
                </TSButton>
              </div>
            </Col>
            <Col span={4} offset={2} className="flex flex-row justify-end">
              <TSButton className="text-base font-medium" icon={<FileTextOutlined />} onClick={handleClick}>
                {t("Export PDF")}
              </TSButton>
            </Col>
          </Row>
        </Header>
        <Tabs style={{ background: "white" }} tabPosition={"right"} items={menuItems} onChange={onTabsChange} />
      </div>
      <Modal
        title={
          <span className="flex justify-center items-center fs-[12px] font-normal">
            <span className="mr-2">
              <MeetingBoard />
            </span>
            {t("Select class")}
          </span>
        }
        open={isOpenModal}
        onCancel={handleCloseClassSelection}
        width="360px"
        footer={
          <div className="w-full flex justify-between items-center">
            <TSButton
              type="text"
              className="text-white font-medium text-[12px] flex-1 bg-[#0f1827] uppercase"
              onClick={handleClickFilter}
            >
              {t("OK")}
            </TSButton>
          </div>
        }
      >
        <div className="pr-2">
          <Input
            id="input-affix-wrapper-filter"
            placeholder="Find class"
            bordered={false}
            className="bg-[#EAEAEA] focus:bg-[#EAEAEA] hover:bg-[#EAEAEA] my-4"
            prefix={<SearchOutlined color="#949494" />}
            value={classSearchValue}
            onChange={handleFilterClass}
          />
        </div>
        <div className="h-[290px] overflow-auto">
          {handleRenderListClasses.length ? (
            handleRenderListClasses.map((item) => {
              return (
                <div className="block mb-[12px]" key={item.id}>
                  <Checkbox
                    checked={checkedList.find((checkedItem) => checkedItem.value === item.id)?.checked}
                    onChange={onChangeCheckbox(item.id)}
                  >
                    {item.name}
                  </Checkbox>
                </div>
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Modal>
    </>
  );
}
export default Report;
