import Sider from "antd/es/layout/Sider";
import { AuthStatus } from "app/containers/Auth/Login";
import { memo } from "react";

const TransientSider = () => {
  return (
    <div className="w-[224px] min-w-[224px] 3xl:w-[448px] 3xl:min-w-[448px] min-h-screen">
      <Sider
        width={"100%"}
        style={{
          height: "100%",
          minHeight: "100vh",
          backgroundColor: "transparent",
          transition: "all 0.3s ease-out",
        }}
        className="w-[224px] min-w-[224px] 3xl:w-[448px] 3xl:min-w-[448px] flex flex-col"
      >
        <div className="flex flex-col h-full min-h-screen w-[224px] 3xl:w-[448px] 3xl:min-w-[448px]">
          <div className="flex justify-center">
            <div className="w-[120px] py-[32px] 3xl:py-[64px] 3xl:w-[240px]">
              <img src="/images/logo_blanc 1.png" alt="logo" width={"100%"} />
            </div>
          </div>

          <div className="text-grey bg-transparent px-[20px] py-[16px] mt-auto">
            <AuthStatus />
          </div>
        </div>
      </Sider>
    </div>
  );
};

export default memo(TransientSider);
