import type { ThemeConfig } from "antd";
import { CONS_COLOR } from "common/constants";
import components from "./components";

const themeConfig: ThemeConfig = {
  token: {
    colorPrimary: CONS_COLOR.PRIMARY,
    fontFamily: "Open Sans, sans-serif",
  },
  components,
};
export default themeConfig;
