import { Col } from "antd";
import { ThreeDot } from "app/icons/ThreeDot";
import { useSidebarContext } from "context/SidebarContext";
import React, { useEffect } from "react";
import { useBoolean } from "hooks/useBoolean";
import ISidebarToggleActionProps from "./SidebarToggleAction";

const SidebarToggleAction = ({
  containerType,
  colProps,
}: ISidebarToggleActionProps) => {
  const shouldDisplayThreeDot = useBoolean();

  const { isSiderVisible } = useSidebarContext();

  useEffect(() => {
    setTimeout(() => {
      shouldDisplayThreeDot.setValue(!isSiderVisible.value);
    }, 200);
  }, [isSiderVisible.value]);

  const isRenderAsCol = containerType === "col";

  const containerClassName = `text-lg font-bold ${
    !isSiderVisible.value && shouldDisplayThreeDot.value ? "block" : "hidden"
  }`;

  const renderIcon = (
    <div
      className="cursor-pointer w-8 h-8 ease-out duration-300"
      onMouseEnter={isSiderVisible.onTrue}
    >
      <ThreeDot className="p-2" />
    </div>
  );

  return (
    <React.Fragment>
      {isRenderAsCol ? (
        <Col className={containerClassName} span={1} {...colProps}>
          {renderIcon}
        </Col>
      ) : (
        <div className={containerClassName}>{renderIcon}</div>
      )}
    </React.Fragment>
  );
};

export default SidebarToggleAction;
