import { notification } from "antd";
import participantApi from "api/participant";
import PopoverActionButton from "app/components/PopoverActionButton/PopoverActionButton";
import NotificationErrorBtn from "app/components/TSNotification/NotificationErrorBtn";
import TSTable from "app/components/TSTable/TSTable";
import { NAME_FEATURE, TYPE_NOTIFICATION } from "common/constants";
import { notificationSuccess } from "common/helper";
import { IClass, IResponseGetParticipants } from "interfaces";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IListParticipant } from "./ListParticipant.d";
import { KEY_VALUE, TITLE_COLUMN } from "./constant";

const ListParticipant = ({
  dataSource,
  isLoading,
  renderListData,
}: IListParticipant) => {
  const { t } = useTranslation();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isShowAction, setIsShowAction] = useState(false);
  const [recordIdSelected, setRecordIdSelected] = useState("");
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const navigate = useNavigate();
  const handleOpenPopover = (newOpen: boolean) => {
    setIsOpenPopover(newOpen);
  };
  const handleDeleteParticipant = async (id: string) => {
    try {
      await participantApi.deleteParticipant(id);
      renderListData();
      setIsLoadingDelete(false);
      notificationSuccess(TYPE_NOTIFICATION.DELETE, NAME_FEATURE.PARTICIPANT);
    } catch (err: any) {
      const { data } = err.response;
      notification.error({
        message: "Failed",
        description: data.message,
        btn: (
          <NotificationErrorBtn
            renderListData={renderListData}
            notification={notification}
          />
        ),
      });
      setIsLoadingDelete(false);
    }
  };
  const handleOkDeleteModal = () => {
    setIsLoadingDelete(true);
    handleDeleteParticipant(recordIdSelected);
  };

  const columns = [
    {
      title: t(TITLE_COLUMN.FIRST_NAME).toUpperCase(),
      dataIndex: KEY_VALUE.FIRST_NAME,
      key: KEY_VALUE.FIRST_NAME,
      sorter: (a: IResponseGetParticipants, b: IResponseGetParticipants) =>
        a.first_name.localeCompare(b.first_name),
      render: (text: string) => {
        return (
          <div className="w-full inline-flex justify-between items-center relative">
            <div className="inline-flex items-center">
              <div
                className="w-2 h-2 rounded mr-2 absolute"
                style={{
                  backgroundColor: "black",
                }}
              ></div>
              <span className="ml-4">{text}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: t(TITLE_COLUMN.LAST_NAME).toUpperCase(),
      dataIndex: KEY_VALUE.LAST_NAME,
      key: KEY_VALUE.LAST_NAME,
      sorter: (a: IResponseGetParticipants, b: IResponseGetParticipants) =>
        a.last_name.localeCompare(b.last_name),
    },
    {
      title: t(TITLE_COLUMN.EMAIL).toUpperCase(),
      dataIndex: KEY_VALUE.EMAIL,
      key: KEY_VALUE.EMAIL,
      sorter: (a: IResponseGetParticipants, b: IResponseGetParticipants) =>
        a.email.localeCompare(b.email),
    },
    {
      title: t(TITLE_COLUMN.CLASS).toUpperCase(),
      dataIndex: KEY_VALUE.CLASS,
      key: KEY_VALUE.CLASS,
      render: (itemColumn: IClass[], record: IResponseGetParticipants) => {
        return (
          <div className="w-full inline-flex justify-between items-center">
            <div className="flex flex-wrap font-medium">
              {itemColumn?.map((item) => (
                <div key={item.id} className="vertical-line px-3">
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
            <PopoverActionButton
              nameModule="participant"
              handleOkDelete={handleOkDeleteModal}
              isRecordSelected={isShowAction && recordIdSelected === record.id}
              isOpenPopover={isOpenPopover}
              handleOpenPopover={handleOpenPopover}
            />
          </div>
        );
      },
    },
  ];

  const onClickOnRowTable = (record: IResponseGetParticipants) => {
    // const encodeEmailUrl = encodeURIComponent(record.email);
    if (!isOpenPopover) navigate(`/participant/${record.id}`);
  };

  const onMouseEnterOnRowTable = (record: IResponseGetParticipants) => {
    setRecordIdSelected(record.id);
    setIsShowAction(true);
  };
  const onMouseLeaveOnRowTable = () => {
    setRecordIdSelected("");
    setIsShowAction(false);
    setIsOpenPopover(false);
  };

  return (
    <TSTable
      isLoading={isLoading || isLoadingDelete}
      dataSource={dataSource}
      columns={columns}
      onClickRow={onClickOnRowTable}
      onMouseEnterRow={onMouseEnterOnRowTable}
      onMouseLeaveRow={onMouseLeaveOnRowTable}
    />
  );
};

export default ListParticipant;
