import moduleApi from "api/module";
import TSHeader from "app/components/TSHeader";
import { LayerGroup, TagAlt } from "app/icons";
import { CONS_COLOR, KEY_VALUES } from "common/constants";
import { removeItemIsNotExistInDataSource } from "common/helper";
import { IResponseGetModule } from "interfaces/IModule";
import { filter, flatten, includes, uniq } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListModule from "./ListModule";
import { KEY_VALUE } from "./constant";
import "./index.css";

export default function Module() {
  const { t } = useTranslation();
  const [listModule, setListModule] = useState<IResponseGetModule[]>([]);
  const [dataSource, setDataSource] = useState<IResponseGetModule[]>([]);
  const [valueFilterModule, setValueFilterModule] = useState<string>("");
  const [valueFilterCategory, setValueFilterCategory] = useState<string>("");

  const [isLoading, setIsLoading] = useState(true);
  const getModules = async () => {
    const companyId = localStorage.getItem(KEY_VALUES.COMPANY_ID) ?? "";

    return moduleApi
      .getModulesByCompany(companyId)
      .then((data) => {
        setListModule(data.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getModules();
  }, []);

  useEffect(() => {
    setDataSource([...listModule]);
  }, [listModule]);

  const filterModule = (e: ChangeEvent<HTMLInputElement>) => {
    const valueInput = e.target.value;
    setValueFilterModule(valueInput);
  };

  const filterCategory = (e: ChangeEvent<HTMLInputElement>) => {
    const valueInput = e.target.value;
    setValueFilterCategory(valueInput);
  };

  const filterValueInDataSource = () => {
    const findDataIsNotMatchWithFilterModule = listModule.filter(
      (item) =>
        !item[KEY_VALUE.NAME_MODULE]
          ?.toLowerCase()
          .includes(valueFilterModule.toLowerCase())
    );
    const findDataIsNotMatchWithFilterCategory = filter(
      listModule,
      (module) => {
        let moduleExistByCategory = false;
        const { categories } = module;
        categories.forEach(({ name }) => {
          if (
            includes(
              name.toLocaleLowerCase(),
              valueFilterCategory.toLocaleLowerCase()
            )
          )
            moduleExistByCategory = true;
        });
        const checkFilterModuleByCategory = () => {
          if (!valueFilterCategory) return false;
          return !moduleExistByCategory;
        };
        return checkFilterModuleByCategory();
      }
    );

    const listDataNotMatchWithFilterGroup = uniq(
      flatten([
        ...findDataIsNotMatchWithFilterModule,
        ...findDataIsNotMatchWithFilterCategory,
      ])
    );
    const dataFilterByRemoveDataNotMatch = filter(
      listModule,
      (module) => !includes(listDataNotMatchWithFilterGroup, module)
    );
    const dataIsDeleted = removeItemIsNotExistInDataSource(
      dataFilterByRemoveDataNotMatch,
      dataSource
    );

    if (dataIsDeleted.length)
      return removeItemIsNotExistInDataSource(
        dataFilterByRemoveDataNotMatch,
        dataIsDeleted
      );

    return dataFilterByRemoveDataNotMatch;
  };

  const listFilter = [
    {
      label: t("Module"),
      icon: (
        <LayerGroup
          className="white-red align-text-middle"
          style={{ color: CONS_COLOR.TEXT_BLACK }}
        />
      ),
      filterMethod: filterModule,
    },
    {
      label: t("Category"),
      icon: (
        <TagAlt
          className="white-red align-text-middle"
          style={{ color: CONS_COLOR.TEXT_BLACK }}
        />
      ),
      filterMethod: filterCategory,
    },
  ];

  return (
    <div className="relative h-full">
      <TSHeader
        titleHeader={t("Modules")}
        textLabelInput={t("New Module")}
        listFilter={listFilter}
      >
        {t("Reports")}
      </TSHeader>
      <ListModule
        dataSource={
          valueFilterCategory || valueFilterModule
            ? filterValueInDataSource()
            : dataSource
        }
        isLoading={isLoading}
        renderListData={getModules}
      />
    </div>
  );
}
