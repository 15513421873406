import { axiosClient } from "./apiClient";

const analysisApi = {
  getAnalysis(analysisId: string) {
    const url = `/analysis/${analysisId}`;
    return axiosClient.get(url);
  },

  fetchVideo(analysisId: string) {
    const endpoint = `/analysis/${analysisId}/download_video`;
    return axiosClient.get(endpoint, {
      responseType: "blob",
    });
  },
};

export default analysisApi;
