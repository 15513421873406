import { useQuery } from "@tanstack/react-query";
import { Form, Table } from "antd";
import { ColumnType } from "antd/es/table";
import participantApi from "api/participant";
import TSButton from "app/components/TSButton";
import TSModal from "app/components/TSModal/TSModal";
import { AxiosResponse } from "axios";
import { KEY_VALUES } from "common/constants";
import { IResponseGetParticipants } from "interfaces";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { KEY_VALUE, TITLE_COLUMN } from "../Participant/constant";

export interface IModalAdd {
  handleCloseModal: () => void;
  isOpen: boolean;
  onOk: (data: string[]) => void;
}

export interface FormData {
  name: string;
  programId: string;
  startDate: string;
}

const ModalAddExistingParticipant: React.FC<IModalAdd> = ({
  handleCloseModal,
  isOpen,
  onOk,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormData>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const companyIdLocal = localStorage.getItem(KEY_VALUES.COMPANY_ID) ?? "";
  const { data: participants, isLoading } = useQuery<
    AxiosResponse<IResponseGetParticipants[]>
  >({
    queryKey: ["participants"],
    queryFn: () => participantApi.getListParticipant(companyIdLocal),
  });

  const handleAdd = useCallback(() => {
    onOk(selectedRowKeys);
    handleCloseModal();
  }, [selectedRowKeys, onOk, handleCloseModal]);

  const handleCancel = () => {
    form.resetFields();
    handleCloseModal();
  };

  const columns: ColumnType<IResponseGetParticipants>[] = [
    {
      title: t(TITLE_COLUMN.FIRST_NAME).toUpperCase(),
      className:
        "text-ellipsis whitespace-nowrap max-w-[150px] overflow-hidden",
      dataIndex: KEY_VALUE.FIRST_NAME,
      key: KEY_VALUE.FIRST_NAME,
      sorter: (a: IResponseGetParticipants, b: IResponseGetParticipants) =>
        a.first_name.localeCompare(b.first_name),
    },
    {
      title: t(TITLE_COLUMN.LAST_NAME).toUpperCase(),
      dataIndex: KEY_VALUE.LAST_NAME,
      className:
        "text-ellipsis whitespace-nowrap max-w-[150px] overflow-hidden",
      key: KEY_VALUE.LAST_NAME,
      sorter: (a: IResponseGetParticipants, b: IResponseGetParticipants) =>
        a.last_name.localeCompare(b.last_name),
    },
    {
      title: t(TITLE_COLUMN.EMAIL).toUpperCase(),
      className:
        "text-ellipsis whitespace-nowrap max-w-[300px] overflow-hidden",
      dataIndex: KEY_VALUE.EMAIL,
      key: KEY_VALUE.EMAIL,
      sorter: (a: IResponseGetParticipants, b: IResponseGetParticipants) =>
        a.email.localeCompare(b.email),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys as string[]);
  };

  return (
    <TSModal
      title={t("Select existing participants")}
      open={isOpen}
      confirmLoading={false}
      onCancel={handleCancel}
      width="844px"
    >
      <Table
        loading={isLoading}
        className="modal-classes"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        rowKey={(record) => record.id}
        dataSource={participants?.data}
        columns={columns}
        pagination={{
          // className: "absolute bottom-0 w-full flex justify-center",
          position: ["bottomCenter"],
          defaultPageSize: 7,
          showSizeChanger: false,
        }}
      />
      <div className="flex justify-end">
        <TSButton
          type="text"
          htmlType="submit"
          loading={false}
          className="px-8 h-10 text-base bg-[#EAEAEA] focus:bg-[#EAEAEA] hover:bg-[#EAEAEA]"
          onClick={handleCloseModal}
        >
          {t("Cancel")}
        </TSButton>
        <TSButton
          type="primary"
          htmlType="submit"
          loading={false}
          className="px-8 h-10 text-base ml-4"
          onClick={handleAdd}
        >
          {t("Add")}
        </TSButton>
      </div>
    </TSModal>
  );
};

export default ModalAddExistingParticipant;
