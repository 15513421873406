const VectorSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M5.5 2.25C5.5 1.65326 5.73705 1.08097 6.15901 0.65901C6.58097 0.237053 7.15326 0 7.75 0H10.25C10.8467 0 11.419 0.237053 11.841 0.65901C12.2629 1.08097 12.5 1.65326 12.5 2.25V4.02H15.75C16.3467 4.02 16.919 4.25705 17.341 4.67901C17.7629 5.10097 18 5.67326 18 6.27V17.27C18 17.4689 17.921 17.6597 17.7803 17.8003C17.6397 17.941 17.4489 18.02 17.25 18.02H0.75C0.551088 18.02 0.360322 17.941 0.21967 17.8003C0.0790175 17.6597 0 17.4689 0 17.27V9.27C0 8.67326 0.237053 8.10097 0.65901 7.67901C1.08097 7.25705 1.65326 7.02 2.25 7.02H5.5V2.25ZM7 16.52H11V2.25C11 2.05109 10.921 1.86032 10.7803 1.71967C10.6397 1.57902 10.4489 1.5 10.25 1.5H7.75C7.55109 1.5 7.36032 1.57902 7.21967 1.71967C7.07902 1.86032 7 2.05109 7 2.25V16.52ZM5.5 8.52H2.25C2.05109 8.52 1.86032 8.59902 1.71967 8.73967C1.57902 8.88032 1.5 9.07109 1.5 9.27V16.52H5.5V8.52ZM12.5 16.52H16.5V6.27C16.5 6.07109 16.421 5.88032 16.2803 5.73967C16.1397 5.59902 15.9489 5.52 15.75 5.52H12.5V16.52Z" />
  </svg>
);
export default VectorSVG;
