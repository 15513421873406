import { KEY_VALUES } from "common/constants";
import { IUseBooleanReturnType, useBoolean } from "hooks/useBoolean";
import { createContext, ReactNode, useContext, useEffect } from "react";

type SidebarContextType = {
  isSiderVisible: IUseBooleanReturnType;
  isToggleIconDocked: IUseBooleanReturnType;
  turnOffSidebar: VoidFunction;
};

export const SidebarContext = createContext({} as SidebarContextType);

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const sidebarState = localStorage.getItem(KEY_VALUES.SIDEBAR_STATE);
  const { isVisible, isDocked } = JSON.parse(sidebarState || "{}");

  const isSiderVisible = useBoolean(isVisible);
  const isToggleIconDocked = useBoolean(isDocked);

  /**
   * When change state sidebar, set config value to localstorage
   */
  useEffect(() => {
    localStorage.setItem(
      KEY_VALUES.SIDEBAR_STATE,
      JSON.stringify({
        isVisible: isSiderVisible.value,
        isDocked: isToggleIconDocked.value,
      })
    );
  }, [isSiderVisible.value, isToggleIconDocked.value]);

  const turnOffSidebar = () => {
    isSiderVisible.onFalse();
    isToggleIconDocked.onFalse();
  };

  return (
    <SidebarContext.Provider
      value={{
        isSiderVisible,
        isToggleIconDocked,
        turnOffSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  const sidebarContext = useContext(SidebarContext);
  if (!sidebarContext) {
    throw new Error("SidebarContext must be used within a SidebarProvider");
  }
  return sidebarContext;
};
