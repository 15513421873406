// CsvExportComponent.tsx

import { Button } from "antd";
import React from "react";
import { CSVLink } from "react-csv";

interface CsvExportProps {
  data: any[]; // Your data array to export
  headers: string[]; // Array of column headers
  filename: string; // CSV file name
}

const CsvExportComponent: React.FC<CsvExportProps> = ({
  data,
  headers,
  filename,
}) => {
  return (
    <CSVLink data={data} headers={headers} filename={filename}>
      <Button type="text">Export CSV</Button>
    </CSVLink>
  );
};

export default CsvExportComponent;
