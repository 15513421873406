import { Input, Checkbox, Button } from "antd";
import TSButton from "app/components/TSButton";
import { useTranslation } from "react-i18next";
import { LOGIN_PARAM } from "./constant";

const LoginForm = ({ loading }: { loading: boolean }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <label>
          <Input
            className="px-4 py-3 bg-primary text-white border-white border placeholder:text-white focus:border-white hover:border-white"
            name={LOGIN_PARAM.USERNAME}
            type="text"
            placeholder={t("Username")}
          />
        </label>
      </div>
      <div className="mt-4">
        <label>
          <Input
            className="px-4 py-3 bg-primary text-white border-white border placeholder:text-white focus:border-white hover:border-white"
            name={LOGIN_PARAM.PASSWORD}
            type="password"
            placeholder={t("Password")}
          />
        </label>
      </div>
      <div className="flex mt-4 justify-between items-center">
        <Checkbox>
          <span className="text-white text-[12px]">{t("Remember me")}</span>
        </Checkbox>
        <Button
          className="text-white px-0 hover:!text-white text-[12px]"
          type="text"
        >
          {t("Forgot password?")}
        </Button>
      </div>
      <div className="mt-4">
        <TSButton
          className="w-full h-[46px] py-[12px] px-[16px] bg-white text-black border rounded-[5px] hover:!bg-slate-400 hover:!text-primary"
          htmlType="submit"
          type="primary"
          loading={loading}
        >
          {t("Login")}
        </TSButton>
      </div>
      <div className="mt-4">
        <Button
          className="border-white border rounded-[5px] w-full h-[46px] py-[12px] px-[16px]"
          type="primary"
        >
          {t("Sign up")}
        </Button>
      </div>
    </>
  );
};

export default LoginForm;
