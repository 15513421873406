import React from "react";

const MedalSVG: React.FC = () => (
  <svg
    width="86"
    height="87"
    viewBox="0 0 86 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Medal Icon"
    role="img"
  >
    <path
      d="M76.6117 21.1404C76.4894 20.6829 76.2775 20.2541 75.9884 19.8789C75.6994 19.5037 75.3388 19.1896 74.9275 18.9546L56.2942 8.20461C55.8873 7.9662 55.4375 7.81043 54.9703 7.74624C54.5031 7.68206 54.0279 7.7107 53.5718 7.83055C53.1158 7.95039 52.6878 8.15907 52.3126 8.44462C51.9373 8.73018 51.6221 9.08699 51.385 9.4946L43 24.0429L34.615 9.4946C34.3779 9.08699 34.0627 8.73018 33.6875 8.44462C33.3122 8.15907 32.8843 7.95039 32.4282 7.83055C31.9722 7.7107 31.4969 7.68206 31.0298 7.74624C30.5626 7.81043 30.1127 7.9662 29.7059 8.20461L11.0725 18.9546C10.6613 19.1896 10.3007 19.5037 10.0116 19.8789C9.72253 20.2541 9.5107 20.6829 9.38836 21.1404C9.15121 22.0486 9.27987 23.0136 9.7467 23.8279L24.08 48.3021C22.481 51.2316 21.5969 54.4976 21.5 57.8337C21.4915 61.0884 22.222 64.3026 23.6365 67.2338C25.0509 70.1651 27.1125 72.737 29.6656 74.7556C32.2187 76.7741 35.1968 78.1867 38.3753 78.8868C41.5538 79.5869 44.8498 79.5562 48.0147 78.7971C51.1796 78.0379 54.1309 76.5702 56.646 74.5044C59.1611 72.4387 61.1743 69.8289 62.534 66.8718C63.8937 63.9147 64.5642 60.6875 64.4951 57.4335C64.426 54.1796 63.619 50.9838 62.135 48.0871L76.11 23.8638C76.3701 23.4658 76.5484 23.0201 76.6346 22.5525C76.7207 22.085 76.7129 21.6049 76.6117 21.1404ZM17.9167 23.3621L30.315 16.1954L41.8534 36.3338C36.9349 36.6376 32.2743 38.6386 28.6667 41.9954L17.9167 23.3621ZM43 72.1671C39.1986 72.1671 35.5529 70.657 32.8648 67.9689C30.1768 65.2809 28.6667 61.6352 28.6667 57.8337C28.6667 54.0323 30.1768 50.3866 32.8648 47.6986C35.5529 45.0105 39.1986 43.5004 43 43.5004C46.8015 43.5004 50.4472 45.0105 53.1352 47.6986C55.8232 50.3866 57.3334 54.0323 57.3334 57.8337C57.3334 61.6352 55.8232 65.2809 53.1352 67.9689C50.4472 70.657 46.8015 72.1671 43 72.1671ZM57.3334 41.8879C55.3879 40.111 53.1274 38.7134 50.6684 37.7671L47.1567 31.2096L55.8284 16.1954L68.2267 23.3621L57.3334 41.8879Z"
      fill="#F8F8F8"
    />
    <g clipPath="url(#clip0_368_16884)">
      <path
        d="M49.4534 52.9609L41.57 60.8443L37.9867 57.2609"
        stroke="#F8F8F8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_368_16884">
        <rect
          width="17.2"
          height="17.2"
          fill="white"
          transform="translate(35.12 48.6602)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default MedalSVG;
