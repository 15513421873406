import { PlusOutlined } from "@ant-design/icons";
import { Col, Layout, Row } from "antd";
import FilterGroup from "../FilterGroup";
import TSButton from "../TSButton";
import { ITSHeader } from "./TSHeader.d";
import SidebarToggleAction from "../SidebarToggleAction";

const TSHeader = ({
  titleHeader,
  textLabelInput,
  children,
  listFilter,
  modalForm,
  handleOpenModal,
  ...props
}: ITSHeader) => {
  const { Header } = Layout;
  return (
    <Header className="px-7 py-8 h-fit leading-none shadow-[0_4px_20px_0_rgba(0,0,0,0.07)]" {...props}>
      <Row align="middle">
        <SidebarToggleAction containerType="col" />

        <Col span={4} className="text-lg font-bold">
          {titleHeader}
        </Col>
        <Col span={11} offset={2} className="uppercase">
          <FilterGroup listFilter={listFilter} />
        </Col>
        {textLabelInput && (
          <Col span={4} offset={2} className="flex flex-row-reverse">
            <TSButton className="text-base font-medium" icon={<PlusOutlined />} onClick={handleOpenModal}>
              {textLabelInput}
            </TSButton>

            {modalForm}
          </Col>
        )}
      </Row>
    </Header>
  );
};

export default TSHeader;
