import Anger from "assets/img/Anger.svg";
import Disgust from "assets/img/Disgust.svg";
import Fear from "assets/img/Fear.svg";
import Joy from "assets/img/Joy.svg";
import Neutral from "assets/img/Neutral.svg";
import Sadness from "assets/img/Sadness.svg";
import Surprise from "assets/img/Surprise.svg";

import { IEmotionItem } from "interfaces/IAnalysis";

export const SVG_ICONS = [
  Joy,
  Sadness,
  Neutral,
  Surprise,
  Anger,
  Disgust,
  Fear,
];

export const EMOTION_COLORS: { [key: string]: string } = {
  Joy: "#EFA00B",
  Sadness: "#4361EE",
  Neutral: "#9a8c98",
  Surprise: "#F72585",
  Anger: "#F8333C",
  Disgust: "#08A045",
  Fear: "#7209B7",
};
export const EMOTION_TEMPLATE: Array<IEmotionItem> = [
  {
    name: "Joie",
    value: 10,
    icon: Joy,
    position: 0,
    output: 3,
    color: EMOTION_COLORS["Joy"],
  },
  {
    name: "Surprise",
    value: 10,
    icon: Surprise,
    position: 1,
    output: 5,
    color: EMOTION_COLORS["Surprise"],
  },
  {
    name: "Peur",
    value: 10,
    icon: Fear,
    position: 2,
    output: 2,
    color: EMOTION_COLORS["Fear"],
  },
  {
    name: "Tristesse",
    value: 10,
    icon: Sadness,
    position: 5,
    output: 4,
    color: EMOTION_COLORS["Sadness"],
  },
  {
    name: "Dégout",
    value: 10,
    icon: Disgust,
    position: 4,
    output: 1,
    color: EMOTION_COLORS["Disgust"],
  },

  {
    name: "Colère",
    value: 10,
    icon: Anger,
    position: 6,
    output: 0,
    color: EMOTION_COLORS["Anger"],
  },
];

export const OUTPUT_TO_EMOTION: { [key: number]: { name: string } } = {
  0: { name: "Colère" },
  1: { name: "Dégout" },
  2: { name: "Peur" },
  3: { name: "Joie" },
  4: { name: "Tristesse" },
  5: { name: "Surprise" },
  6: { name: "Neutre" },
};
