const PauseIconSVG = () => (
  <svg
    width="21"
    height="30"
    viewBox="0 0 21 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 0C15.3065 0 14.1619 0.474106 13.318 1.31802C12.4741 2.16193 12 3.30653 12 4.5V25.5C12 26.6935 12.4741 27.8381 13.318 28.682C14.1619 29.5259 15.3065 30 16.5 30C17.6935 30 18.8381 29.5259 19.682 28.682C20.5259 27.8381 21 26.6935 21 25.5V4.5C21 3.30653 20.5259 2.16193 19.682 1.31802C18.8381 0.474106 17.6935 0 16.5 0ZM18 25.5C18 25.8978 17.842 26.2794 17.5607 26.5607C17.2794 26.842 16.8978 27 16.5 27C16.1022 27 15.7206 26.842 15.4393 26.5607C15.158 26.2794 15 25.8978 15 25.5V4.5C15 4.10218 15.158 3.72064 15.4393 3.43934C15.7206 3.15804 16.1022 3 16.5 3C16.8978 3 17.2794 3.15804 17.5607 3.43934C17.842 3.72064 18 4.10218 18 4.5V25.5ZM4.5 0C3.30653 0 2.16193 0.474106 1.31802 1.31802C0.474106 2.16193 0 3.30653 0 4.5V25.5C0 26.6935 0.474106 27.8381 1.31802 28.682C2.16193 29.5259 3.30653 30 4.5 30C5.69348 30 6.83807 29.5259 7.68198 28.682C8.5259 27.8381 9 26.6935 9 25.5V4.5C9 3.30653 8.5259 2.16193 7.68198 1.31802C6.83807 0.474106 5.69348 0 4.5 0ZM6 25.5C6 25.8978 5.84197 26.2794 5.56066 26.5607C5.27936 26.842 4.89783 27 4.5 27C4.10218 27 3.72065 26.842 3.43934 26.5607C3.15804 26.2794 3 25.8978 3 25.5V4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72065 3.15804 4.10218 3 4.5 3C4.89783 3 5.27936 3.15804 5.56066 3.43934C5.84197 3.72064 6 4.10218 6 4.5V25.5Z"
      fill="#F8F8F8"
    />
  </svg>
);
export default PauseIconSVG;
