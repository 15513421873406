import React, { useEffect, useRef, useState } from "react";
import { Area, AreaChart, ReferenceDot, ResponsiveContainer, XAxis } from "recharts";
import arrayPadStart, { CHART_LENGTH } from "./helper";

interface DataPoint {
  time: number;
  value: number;
}

const STEPS = 16;

// Cubic ease-in-out easing function
const easeInOutCubic = (t: number): number => {
  return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
};

const CustomDot = (props: any) => {
  const { cx, cy, data } = props;

  return (
    <g>
      <foreignObject
        x={cx - 20}
        y={cy - 40}
        width={50}
        height={40}
        style={{
          transition: "all 0.2s linear",
        }}
      >
        <p className="bg-[#D9D9D9] text-black rounded-xl text-center font-bold text-base">
          {data[data.length - 1].value}
        </p>
      </foreignObject>
      <circle
        style={{
          transition: "all 0.2s linear",
        }}
        cx={cx}
        cy={cy}
        r={8}
        strokeWidth={5}
        fill="#FFD445"
      />
      <circle
        style={{
          transition: "all 0.2s linear",
        }}
        cx={cx}
        cy={cy}
        r={5}
        strokeWidth={5}
        fill="black"
      />
    </g>
  );
};

const SyllablesChart: React.FC<{ data: DataPoint[] }> = ({ data }) => {
  const [interpolatedData, setInterpolatedData] = useState<DataPoint[]>([]);
  const lastOriginalData = useRef<DataPoint | null>(null);
  const currentStep = useRef<number>(0);

  useEffect(() => {
    if (data.length > 0) {
      const newData = data[data.length - 1];
      const lastData = lastOriginalData.current || newData;

      const deltaTime = 1000; // 1 second between new data points

      const interval = setInterval(() => {
        if (currentStep.current < STEPS) {
          const progress = currentStep.current / STEPS;
          const easedProgress = easeInOutCubic(progress);
          const interpolatedValue = lastData.value + easedProgress * (newData.value - lastData.value);
          const interpolatedTime = lastData.time + progress * 1;

          setInterpolatedData((prevData) =>
            [
              ...arrayPadStart(prevData, STEPS * CHART_LENGTH, {
                time: 0,
                value: 0,
              }),
              { time: interpolatedTime, value: interpolatedValue },
            ].slice(-(STEPS * CHART_LENGTH))
          );

          currentStep.current += 1;
        } else {
          clearInterval(interval);
          lastOriginalData.current = newData;
          currentStep.current = 0;
        }
      }, STEPS); // Every 16ms

      return () => clearInterval(interval);
    }
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="90%">
      <AreaChart data={interpolatedData} margin={{ top: 60, right: 30, left: 0, bottom: 10 }}>
        <defs>
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="60%" stopColor="#FFD445" stopOpacity={0.6} />
              <stop offset="100%" stopColor="#FFD445" stopOpacity={0} />
            </linearGradient>
          </defs>
        </defs>
        {/* <Tooltip /> */}
        <Area
          type="monotone"
          dataKey="value"
          stroke="#FFD445"
          fill="url(#colorValue)"
          isAnimationActive={true}
          animationDuration={900}
          animationEasing="linear"
          animateNewValues={true}
          // dot={<CustomDot data={data}/>}
        />

        <ReferenceDot
          x={interpolatedData.length - 1}
          y={data[data.length - 1]?.value}
          r={5}
          fill="black"
          stroke="black"
          style={{
            transition: "all 1s ease-in-out",
          }}
          shape={<CustomDot data={data} />}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default React.memo(SyllablesChart);
