import TSButton from "app/components/TSButton";
import VideoBackground from "app/components/VideoBackground/VideoBackground";
import { MedalIcon } from "app/icons/MedalIcon";
import "./index.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BackIconButton from "app/components/BackIconButton/BackIconButton";
import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import {
  IResponseGetSessionDetail,
  ParticipantInfo,
} from "interfaces/IMasterclass";
import coachApi from "api/coach";
import { useEffect, useState } from "react";
import TransientSider from "app/components/TransientSider";

const ParticipantRecordingEnded = () => {
  const { t } = useTranslation();
  const { participantIndex, classId, sessionIndex } = useParams();
  const navigate = useNavigate();

  const [noAnalysisParticipants, setNoAnalysisParticipant] = useState<
    ParticipantInfo[]
  >([]);

  const { data: sessionData } = useQuery<
    AxiosResponse<IResponseGetSessionDetail>
  >(["classId", classId, "sessionIndex", sessionIndex], () =>
    coachApi.getSessionDetail(classId as string, Number(sessionIndex))
  );

  const { first_name = "", last_name = "" } =
    sessionData?.data?.participants[Number(participantIndex)] || {};
  const participantName = `${first_name} ${last_name}`;

  function getParticipantsForNullAnalysis(
    data: IResponseGetSessionDetail
  ): ParticipantInfo[] {
    const { participants, session } = data;

    const nullIndices = session.analysis
      .map((item, index) => (item === null ? index : -1))
      .filter((index) => index !== -1);

    const result = nullIndices
      .map((index) => participants[index])
      .filter((participant) => participant !== undefined);

    return result;
  }

  useEffect(() => {
    if (sessionData) {
      const noAnalysisParticipants = getParticipantsForNullAnalysis(
        sessionData.data
      );
      setNoAnalysisParticipant(noAnalysisParticipants);
    }
  }, [sessionData]);

  // Event handler
  const handleClickBack = () => {
    navigate(`/masterclass/${classId}/${sessionIndex}`);
  };
  const handleReturnParticipantList = () => {
    navigate(`/masterclass/${classId}/${sessionIndex}`);
  };
  const handleNextParticipant = () => {
    const nextParticipantIndex = sessionData?.data.participants
      .findIndex((item) => item.id === noAnalysisParticipants[0].id)
      .toString();
    navigate(`/masterclass/${classId}/${sessionIndex}/${nextParticipantIndex}`);
  };

  return (
    <VideoBackground videoSource="/videos/theatre-audience-clapping.mp4">
      <div className="flex flex-row w-full h-full">
        <TransientSider />

        <div className="flex-1 flex flex-col items-center justify-center text-white relative">
          <div className="absolute top-8 left-5">
            <BackIconButton onClick={handleClickBack} />
          </div>
          <div className="mb-5">
            <MedalIcon />
          </div>

          <div className="mb-12">
            <p className="font-bold text-6xl">
              {t("Bravo")} {participantName}!
            </p>
          </div>

          <div className="mb-8 max-w-[696px] text-center text-2xl">
            La vidéo est enregistrée et est en cours d’analyse. Les résultats
            seront disponibles dans quelques instants depuis la liste des
            participants.
          </div>

          <div className="flex flex-row gap-3 white-btn-group">
            <TSButton
              onClick={handleReturnParticipantList}
              className="flex justify-center items-center text-xl"
            >
              {t("Return to participant list")}
            </TSButton>
            {noAnalysisParticipants.length > 0 && (
              <TSButton
                onClick={handleNextParticipant}
                className="flex justify-center items-center text-xl"
              >
                {t("Next participant")}
              </TSButton>
            )}
          </div>
        </div>
      </div>
    </VideoBackground>
  );
};

export default ParticipantRecordingEnded;
