import { Modal, ModalProps } from "antd";

const TSModal = ({ ...props }: ModalProps) => {
  const { children } = props;
  return (
    <Modal footer={[]} width="360px" {...props}>
      {children}
    </Modal>
  );
};

export default TSModal;
