import { CONS_COLOR } from "common/constants";

const PaginationConfig = {
  Pagination: {
    itemActiveBg: CONS_COLOR.PRIMARY,
    itemBg: CONS_COLOR.PRIMARY,
    borderRadius: 30,
  },
};
export default PaginationConfig;
