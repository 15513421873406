import React, { Fragment, useCallback, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ReactPlayer from "react-player/file";
import { VideoSeekSlider } from "react-video-seek-slider";
// import "./replay.scss";
import "react-video-seek-slider/styles.css";
import "./index.css";

import { Button } from "antd";
import { PauseIcon } from "app/icons/PauseIcon";
import { PlayIcon } from "app/icons/PlayIcon";
import { SeekBack10s } from "app/icons/SeekBack10s";
import { SeekForward10s } from "app/icons/SeekForward10s";
import { VolumeOff } from "app/icons/VolumeOff";
import { VolumeOn } from "app/icons/VolumeOn";
import {
  isSliding,
  onEnded,
  onPlay,
  setCurrentViteseeIndex,
  setGDuration,
  useVideoContext,
  videoPosition,
} from "context/VideoContext";
import { useBoolean } from "hooks/useBoolean";

export default function VideoPlayer({
  viteseeDataLength,
  totalDuration,
}: {
  viteseeDataLength: number;
  totalDuration: number;
}) {
  const [duration, setDuration] = useState<number>(0);
  const [playedSeconds, setPlayedSeconds] = useState<number>(0);
  const vidPlayer = useRef<ReactPlayer | null>(null);
  const handle = useFullScreenHandle();

  const isPlaying = useBoolean(false);
  const isMuted = useBoolean(false);
  const isShowControl = useBoolean(false);
  // const isFullScreen = useBoolean(true);

  const { state: stateR, dispatch } = useVideoContext();

  const togglePlay = useCallback(() => {
    isPlaying.onToggle();
    dispatch(onPlay(!isPlaying.value));
    if (stateR.ended) {
      dispatch(onEnded(false));
      dispatch(setCurrentViteseeIndex(0));
    }
  }, [isPlaying, dispatch]);

  window.onkeyup = (e) => {
    if (e.code === "Space") togglePlay();
  };

  const seekForeward = (seconds: number) => {
    if (vidPlayer.current) {
      const currentTime = vidPlayer.current.getCurrentTime();
      vidPlayer.current.seekTo(currentTime + seconds, "seconds");
      dispatch(isSliding(true));
      updateViteseeIndex(currentTime + seconds);
    }
  };

  const seekBackward = (seconds: number) => {
    if (vidPlayer.current) {
      const currentTime = vidPlayer.current.getCurrentTime();
      vidPlayer.current.seekTo(currentTime - seconds, "seconds");
      dispatch(isSliding(true));
      updateViteseeIndex(currentTime - seconds);
    }
  };

  const slideTo = (miliseconds: number) => {
    const seconds = Math.round(miliseconds / 1000);
    if (vidPlayer.current) {
      vidPlayer.current.seekTo(seconds);
      dispatch(isSliding(true));
      updateViteseeIndex(seconds);
    }
  };

  const moveSlider = (sliderSelector: string, fraction: number) => {
    const slider = document.querySelector(sliderSelector) as HTMLElement;
    if (slider) {
      slider.style.transform = `scalex(${fraction.toString()})`;
    }
  };

  const moveThumb = (
    thumbSelector: string,
    timePlayed: number,
    duration: number
  ) => {
    const thumb = document.querySelector(thumbSelector) as HTMLElement;
    if (thumb) {
      thumb.style.left = `${(timePlayed / duration) * 98}%`;
    }
  };

  const autoStop = useCallback(
    (
      duration: number,
      ref: React.RefObject<ReactPlayer>,
      setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      if (ref.current) {
        const currentTime = ref.current.getCurrentTime();
        if (currentTime === duration) {
          setIsPlaying(false);
          dispatch(onPlay(false));
        }
      }
    },
    [dispatch]
  );

  const updateViteseeIndex = (time: number) => {
    const index = Math.floor((time / duration) * (viteseeDataLength - 1));

    let finalIndex = 0;
    if (index < 0) {
      finalIndex = 0;
    } else if (index > viteseeDataLength) {
      finalIndex = viteseeDataLength - 1;
    } else {
      finalIndex = index;
    }

    dispatch(setCurrentViteseeIndex(finalIndex));
  };

  const renderControl = (
    <div
      className={`absolute z-[2] bottom-0 w-full h-[160px] bg-[rgba(162,162,162,0.25)] backdrop-blur-xl transition-opacity rounded-bl-[20px] rounded-br-[20px] ${
        isShowControl.value ? "opacity-1" : "opacity-0"
      }`}
    >
      <div className="relative top-[-6px]">
        <VideoSeekSlider
          max={duration * 1000}
          currentTime={playedSeconds * 1000}
          onChange={(time: number) => {
            slideTo(time);
          }}
          offset={0}
          secondsPrefix="00:00:"
          minutesPrefix="00:"
        />
      </div>

      <div className="video-controls flex flex-row justify-center items-center h-full">
        <Button
          icon={<SeekBack10s />}
          className="p-1 !w-[40px] h-[40px] !border-0 flex items-center justify-center"
          style={{
            background: "none",
          }}
          shape="circle"
          onClick={() => seekBackward(10)}
        />

        <Button
          icon={isPlaying.value ? <PauseIcon /> : <PlayIcon />}
          className="p-1 !w-[40px] h-[40px] !border-0 flex items-center justify-center mx-16"
          style={{
            background: "none",
          }}
          shape="circle"
          onClick={togglePlay}
        />
        <Button
          icon={<SeekForward10s />}
          className="p-1 !w-[40px] h-[40px] !border-0 flex items-center justify-center"
          style={{
            background: "none",
          }}
          shape="circle"
          onClick={() => seekForeward(10)}
        />
        {/* <div>
        <FullScreenTogglers
          isFullScreen={isFullScreen.value}
          setIsFullScreen={isFullScreen.setValue}
          handle={handle}
        />
      </div> */}
      </div>
    </div>
  );

  return (
    <Fragment>
      <FullScreen handle={handle} className="h-full w-full">
        <div
          className="video-wrapper relative h-full w-full"
          onMouseEnter={isShowControl.onTrue}
          onMouseLeave={isShowControl.onFalse}
        >
          <Button
            icon={isMuted.value ? <VolumeOff /> : <VolumeOn />}
            className="absolute right-8 top-8 z-[2] p-1 !w-[40px] h-[40px] bg-[#7C7C7C80] !border-0 flex items-center justify-center "
            shape="circle"
            onClick={isMuted.onToggle}
          />
          <div className="video-auto-width">
            <ReactPlayer
              ref={vidPlayer}
              onProgress={(e) => {
                dispatch(videoPosition(e.playedSeconds));
                setPlayedSeconds(e.playedSeconds);
                dispatch(
                  setCurrentViteseeIndex(
                    Math.floor(
                      (e.playedSeconds / totalDuration) *
                        (viteseeDataLength - 1)
                    )
                  )
                );
                moveSlider(".connect", e.played);
                moveThumb(".thumb", e.playedSeconds, duration);
                autoStop(duration, vidPlayer, isPlaying.setValue);
              }}
              onDuration={(duration) => {
                setDuration(duration);
                setGDuration(duration);
              }}
              onEnded={() => {
                isPlaying.onFalse();
                dispatch(onEnded(true));
                dispatch(setCurrentViteseeIndex(viteseeDataLength - 1));
              }}
              style={{ width: 10 }}
              // width={isFullScreen.value ? "100%" : "1020px"}
              // height="100%"
              url={stateR.videoUrl}
              controls={false}
              playing={isPlaying.value}
              // volume={1}
              muted={isMuted.value}
            />
          </div>
          {renderControl}
        </div>
      </FullScreen>
      {/* <ChartsPanel isPlaying={isPlaying} /> */}
    </Fragment>
  );
}
