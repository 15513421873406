import { Upload, UploadProps } from "antd";
import TSButton from "app/components/TSButton";
import TSModal from "app/components/TSModal/TSModal";
import { NewParticipantsInfo } from "interfaces/IClass";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePapaParse } from "react-papaparse";

const { Dragger } = Upload;

export interface IModalAdd {
  handleCloseModal: () => void;
  isOpen: boolean;
  onOk: (data: NewParticipantsInfo[]) => void;
}

const ModalUploadParticipant: React.FC<IModalAdd> = ({
  handleCloseModal,
  isOpen,
  onOk,
}) => {
  const { readString } = usePapaParse();
  const { t } = useTranslation();
  const [data, setData] = useState<NewParticipantsInfo[]>([]);

  const handleCreate = () => {
    onOk(data);
    handleCloseModal();
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".csv",
    /* istanbul ignore next */
    beforeUpload(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        readString(e?.target?.result as string, {
          worker: true,
          delimiter: ",",
          skipEmptyLines: true,
          complete: (results) => {
            const data = results.data as string[][];
            const filterEmpty = data.filter((_, index) => index > 0);
            setData(
              filterEmpty.map((item: string[]) => ({
                email: item[0],
                first_name: item[1],
                last_name: item[2],
              }))
            );
          },
        });
      };
      reader.readAsText(file);

      // Prevent upload
      return false;
    },
  };

  return (
    <TSModal
      title={t("Upload CSV")}
      open={isOpen}
      confirmLoading={false}
      onCancel={handleCloseModal}
      width="744px"
    >
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <img src="/images/mingcute_upload-3-line.svg" alt="Upload" />
        </p>
        <p className="ant-upload-text">Select a CSV file to upload</p>
        <p className="ant-upload-hint">or drag and drop it here</p>
      </Dragger>
      <div className="flex justify-end mt-6">
        <TSButton
          type="text"
          htmlType="submit"
          loading={false}
          className="px-8 h-10 text-base bg-[#EAEAEA] focus:bg-[#EAEAEA] hover:bg-[#EAEAEA]"
          onClick={handleCloseModal}
        >
          {t("Cancel")}
        </TSButton>
        <TSButton
          type="primary"
          htmlType="submit"
          loading={false}
          className="px-8 h-10 text-base ml-4"
          onClick={handleCreate}
        >
          {t("Add")}
        </TSButton>
      </div>
    </TSModal>
  );
};

export default ModalUploadParticipant;
