const VolumeOffSVG = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5976 0.304702C20.0026 -0.100176 20.6481 -0.102558 21.0685 0.303511C21.477 0.711961 21.477 1.37167 21.0697 1.77893L1.77736 21.0702C1.58561 21.2619 1.30931 21.375 1.04015 21.375C0.776952 21.375 0.517323 21.2678 0.307715 21.0737C-0.101974 20.6486 -0.101974 20.002 0.304142 19.5959L3.484 16.4164H3.45184C1.86668 16.4164 0.71264 15.2971 0.498268 13.5585C0.258886 11.8199 0.306524 8.84285 0.498268 7.25907C0.723359 5.61574 1.93933 4.48566 3.45184 4.48566H5.51101L9.44116 1.27045C9.91874 0.865576 10.7643 0.483324 11.4182 0.472607C12.6091 0.472607 13.7048 1.30499 14.0978 2.6387C14.2526 3.19958 14.3134 3.75807 14.361 4.29513L14.4551 5.05606C14.4706 5.17276 14.4849 5.28469 14.4968 5.40378L19.5976 0.304702ZM13.3642 12.0727C13.525 11.9155 13.8847 11.8024 14.0466 11.8441C14.4837 11.9548 14.5695 12.5824 14.5623 13.0754C14.5421 14.5068 14.4944 15.5023 14.4182 16.1179L14.3646 16.6252L14.3637 16.6346C14.3128 17.1449 14.2601 17.673 14.1086 18.2364C13.712 19.5677 12.6485 20.4323 11.4396 20.4323C11.4003 20.4323 11.361 20.4323 11.3205 20.4311C10.6524 20.4311 9.92831 20.0309 9.51742 19.682L8.05374 18.5496C7.49756 18.1364 7.66191 17.4766 7.97394 17.0944C8.20696 16.8098 11.0076 14.2389 12.4785 12.8886C12.9773 12.4307 13.3232 12.1131 13.3642 12.0727Z"
      fill="white"
    />
  </svg>
);
export default VolumeOffSVG;
