import React from "react";
import { line, curveCardinalClosed } from "d3-shape";

const CustomRadarShape = (props: any) => {
  const { cx, cy, points, fill, stroke, fillOpacity } = props;

  // Create the curve generator using d3-shape
  const curveGenerator = line()
    .x((d: any) => d.x)
    .y((d: any) => d.y)
    .curve(curveCardinalClosed);

  // Generate the path using the points
  const path = curveGenerator(points) ?? "";

  return (
    <g>
      <path d={path} fill={fill} stroke={stroke} fillOpacity={fillOpacity} />
    </g>
  );
};

export default CustomRadarShape;
