import { KEY_VALUES } from "common/constants";
import {
  IRequestCreateProgram,
  IRequestUpdateProgram,
} from "interfaces/IProgram";
import { axiosClient } from "./apiClient";

const programApi = {
  getPrograms({ queryKey }: any) {
    const [, companyIdLocal] = queryKey;
    const url = `/programs/list?company_id=${companyIdLocal}`;
    return axiosClient.get(url);
  },
  getModules() {
    const companyIdLocal = localStorage.getItem(KEY_VALUES.COMPANY_ID) ?? "";

    const url = "/modules/list";
    return axiosClient.get(url, {
      params: {
        company_id: companyIdLocal,
      },
    });
  },
  getProgram(id: string) {
    const url = `/programs/${id}`;
    return axiosClient.get(url);
  },
  createProgram(params: IRequestCreateProgram) {
    const url = "/programs";
    return axiosClient.post(url, params);
  },
  updateProgram(params: IRequestUpdateProgram) {
    const url = `/programs/${params.id}`;
    return axiosClient.put(url, {
      name: params.name,
      module_ids: params.module_ids,
    });
  },
  deleteProgram(id: string) {
    const url = `/programs/${id}`;
    return axiosClient.delete(url);
  },
};

export default programApi;
