import React, { createContext, useReducer, useContext, ReactNode } from "react";

// Action types as a constants object
export const ActionTypes = {
  WAVE_READY: "WAVE_READY",
  PLAY: "PLAY",
  ENDED: "ENDED",
  WAVE_PROGRESS: "WAVE_PROGRESS",
  DATA_FETCHED: "DATA_FETCHED",
  GET_VIDEO: "GET_VIDEO",
  GET_VIDEO_BLOB: "GET_VIDEO_BLOB",
  VIDEO_POSITION: "VIDEO_POSITION",
  DURATION: "DURATION",
  SLIDING: "SLIDING",
  CURRENT_VITESEE_INDEX: "CURRENT_VITESEE_INDEX",
  INCREASE_CURRENT_VITESEE_INDEX: "INCREASE_CURRENT_VITESEE_INDEX",
  IS_IN_INDIVIDUAL: "IS_IN_INDIVIDUAL",
  RESET_STATE: "RESET_STATE",
};

interface State {
  waveProgress: number;
  waveReady: boolean;
  data: any;
  videoUrl: string;
  videoBlob: Blob | null;
  play: boolean;
  ended: boolean;
  videoPosition: number;
  duration: number;
  currentViteseeIndex: number;
  isSliding: boolean;
  isInIndividual: boolean;
}

interface Action {
  type: string;
  payload?: any;
}

// Initial State
const initialState: State = {
  waveProgress: 0,
  waveReady: false,
  data: null,
  videoUrl: "",
  videoBlob: null,
  play: false,
  ended: false,
  videoPosition: 0,
  currentViteseeIndex: 0,
  duration: 0,
  isSliding: false,
  isInIndividual: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.WAVE_READY:
      return { ...state, waveReady: false };

    case ActionTypes.PLAY:
      return { ...state, play: action.payload };

    case ActionTypes.ENDED:
      return {
        ...state,
        ended: action.payload,
        play: false,
        currentViteseeIndex: 0,
      };

    case ActionTypes.WAVE_PROGRESS:
      return { ...state, waveProgress: action.payload };

    case ActionTypes.DATA_FETCHED:
      return { ...state, data: action.payload };

    case ActionTypes.GET_VIDEO:
      return { ...state, videoUrl: action.payload };

    case ActionTypes.GET_VIDEO_BLOB:
      return { ...state, videoBlob: action.payload };

    case ActionTypes.VIDEO_POSITION:
      return { ...state, videoPosition: action.payload };

    case ActionTypes.DURATION:
      return { ...state, duration: action.payload };

    case ActionTypes.SLIDING:
      return { ...state, isSliding: action.payload };

    case ActionTypes.CURRENT_VITESEE_INDEX:
      return { ...state, currentViteseeIndex: action.payload };

    case ActionTypes.INCREASE_CURRENT_VITESEE_INDEX:
      return { ...state, currentViteseeIndex: state.currentViteseeIndex + 1 };

    case ActionTypes.IS_IN_INDIVIDUAL:
      return { ...state, isInIndividual: action.payload };

    case ActionTypes.RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

// Action creators using the constants object
export const onWaveReady = (value: boolean): Action => ({
  type: ActionTypes.WAVE_READY,
  payload: value,
});
export const onPlay = (value: boolean): Action => ({
  type: ActionTypes.PLAY,
  payload: value,
});
export const onEnded = (value: boolean): Action => ({
  type: ActionTypes.ENDED,
  payload: value,
});
export const onWaveProgress = (value: number): Action => ({
  type: ActionTypes.WAVE_PROGRESS,
  payload: value,
});
export const onDataFetched = (value: any): Action => ({
  type: ActionTypes.DATA_FETCHED,
  payload: value,
});
export const onGetVideo = (value: string): Action => ({
  type: ActionTypes.GET_VIDEO,
  payload: value,
});
export const onGetVideoBlob = (value: Blob): Action => ({
  type: ActionTypes.GET_VIDEO_BLOB,
  payload: value,
});
export const videoPosition = (value: number): Action => ({
  type: ActionTypes.VIDEO_POSITION,
  payload: value,
});
export const setGDuration = (value: number): Action => ({
  type: ActionTypes.DURATION,
  payload: value,
});
export const setCurrentViteseeIndex = (value: number): Action => ({
  type: ActionTypes.CURRENT_VITESEE_INDEX,
  payload: value,
});
export const onIncreaseCurrentViteseeIndex = (): Action => ({
  type: ActionTypes.INCREASE_CURRENT_VITESEE_INDEX,
});
export const isSliding = (value: boolean): Action => ({
  type: ActionTypes.SLIDING,
  payload: value,
});
export const setIsInIndividual = (value: boolean): Action => ({
  type: ActionTypes.IS_IN_INDIVIDUAL,
  payload: value,
});
export const resetState = (): Action => ({
  type: ActionTypes.RESET_STATE,
});

const VideoContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const VideoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <VideoContext.Provider value={{ state, dispatch }}>
      {children}
    </VideoContext.Provider>
  );
};

export const useVideoContext = () => {
  const videoContext = useContext(VideoContext);
  if (!videoContext) {
    throw new Error("VideoContext must be used within a VideoProvider");
  }
  return videoContext;
};
