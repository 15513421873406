export enum KEY_VALUE {
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  EMAIL = "email",
  CLASS = "classes",
  REGISTER_DATE = "registered_at",
  VALIDATION_DATE = "confirmed_at",
  ACCEPTED_TERM = "comply_with_terms",
  MARKETING_NOTIFICATION = "comply_with_notifications",
}

export enum TITLE_COLUMN {
  FIRST_NAME = "NAME",
  LAST_NAME = "SURNAME",
  EMAIL = "EMAIL",
  CLASS = "CLASS",
  REGISTER_DATE = "REGISTER DATE",
  VALIDATION_DATE = "VALIDATION DATE",
  ACCEPTED_TERM = "ACCEPTED TERM",
  MARKETING_NOTIFICATION = "MARKETING_NOTIFICATION",
}
