import {
  EditFilled,
  MinusOutlined,
  PlusOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Spin,
  notification,
} from "antd";
import programApi from "api/program";
import TSButton from "app/components/TSButton";
import { AxiosResponse } from "axios";
import {
  KEY_VALUES,
  LIMIT_LENGTH_INPUT,
  RULE_FORM_ITEM_NAME,
} from "common/constants";
import update from "immutability-helper";
import { IResponseGetProgramDetail } from "interfaces/IProgram";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DayColumn from "./DayColumn";
import ModulePanel from "./ModulePanel";
import { DEFAULT_NEW_PROGRAM_DAYS, MAX_NEW_PROGRAM_DAYS } from "./constant";

export default function ProgramDetail({ isCreate }: { isCreate?: boolean }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Header } = Layout;
  const { programId } = useParams();
  const location = useLocation();
  const CONS_KEY_UP_ENTER = "Enter";
  const KEY_FIELD_ID_NAME = "name";
  const [isEditing, setIsEditing] = useState(
    Boolean(isCreate) || !!location.state?.isEdit
  );
  const [form] = Form.useForm();
  const [editingData, setEditingData] = useState<IResponseGetProgramDetail>({
    id: "1",
    name: "New Program",
    modules: new Array(DEFAULT_NEW_PROGRAM_DAYS).fill([]),
  } as IResponseGetProgramDetail);
  const [value, setValue] = useState(editingData?.modules?.length);

  const {
    data: program,
    isLoading,
    isFetching,
  } = useQuery<AxiosResponse<IResponseGetProgramDetail>>(
    ["program", programId],
    () => programApi.getProgram(programId || ""),
    {
      enabled: !!programId,
    }
  );

  useEffect(() => {
    if (!location.state?.isEdit) return;

    program?.data && setEditingData(program?.data);
  }, [setEditingData, program, location]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangeInputValueDay, setIsChangeInputValueDay] = useState(false);

  const dataToRender = isEditing ? editingData : program?.data;

  const queryClient = useQueryClient();

  // Mutations
  const mutation = useMutation({
    mutationFn: programApi.updateProgram,
    onSuccess: () => {
      // Invalidate and refetch
      notification.success({
        message: "Success",
        description: "Update Program Successfully"!,
      });
      queryClient.invalidateQueries({ queryKey: ["program"] });

      handleToggleEdit();
    },
    onError: () => {
      notification.error({
        message: "Failed",
        description: "Update Program Failed"!,
      });
    },
  });

  const createMutation = useMutation({
    mutationFn: programApi.createProgram,
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: "Create Program Successfully"!,
      });
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["programs"] });
      navigate("/program");
      handleToggleEdit();
    },
    onError: () => {
      notification.error({
        message: "Failed",
        description: "Data already exists"!,
      });
    },
  });

  const showModalConfirmChangeInput = () => {
    if (isChangeInputValueDay && value !== editingData.modules.length)
      setIsModalOpen(true);
  };

  const handleConfirmModal = () => {
    setIsChangeInputValueDay(false);
    handleChangeNumberOfDays(Number(value))();
    setIsModalOpen(false);
  };

  const handleCancelModal = () => {
    setValue(editingData?.modules?.length);
    setIsChangeInputValueDay(false);
    setIsModalOpen(false);
  };

  const handleToggleEdit = () => {
    setIsEditing((state) => !state);

    program?.data && setEditingData(program?.data);
    program?.data && setValue(program?.data?.modules?.length);
  };
  const handleBack = () => {
    navigate("/program");
  };

  const handleSave = () => {
    mutation.mutate({
      id: programId || "",
      module_ids:
        editingData?.modules.map((day) => day.map((modules) => modules.id)) ||
        [],
      name: editingData?.name || "",
    });
  };

  const handleCreate = () => {
    const companyIdLocal = localStorage.getItem(KEY_VALUES.COMPANY_ID) ?? "";
    createMutation.mutate({
      company_id: companyIdLocal,
      module_ids:
        editingData?.modules.map((day) => day.map((modules) => modules.id)) ||
        [],
      name: editingData?.name || "",
    });
  };

  const handleDrop = (index: number, item: any) => {
    const { columnIndex, itemIndex, ...restItem } = item;

    if (index === columnIndex) return;

    setEditingData((state) =>
      update(state, {
        modules: {
          [index]: {
            $push: [restItem],
          },
        },
      })
    );
    columnIndex !== undefined &&
      setEditingData((state) =>
        update(state, {
          modules: {
            [columnIndex]: {
              $splice: [[itemIndex, 1]],
            },
          },
        })
      );
  };

  const handleMoveModule = (
    dragIndex: number,
    hoverIndex: number,
    columnIndex: number
  ) => {
    setEditingData((prevState) =>
      update(prevState, {
        modules: {
          [columnIndex]: {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevState.modules[columnIndex][dragIndex]],
            ],
          },
        },
      })
    );
  };

  const handleDeleteModule = (columnIndex: number, itemIndex: number) => {
    setEditingData((prevState) =>
      update(prevState, {
        modules: {
          [columnIndex]: {
            $splice: [[itemIndex, 1]],
          },
        },
      })
    );
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEditingData((state) => ({ ...state, name: e.target.value }));

  const handleChangeInput = (value: number | null) => {
    const numberValue = value ?? 0;
    setValue(numberValue);
    setIsChangeInputValueDay(true);
  };

  const handleChangeNumberOfDays = (newNumberOfDays: number) => () => {
    setValue(newNumberOfDays);
    if (newNumberOfDays > MAX_NEW_PROGRAM_DAYS) return;
    if (newNumberOfDays >= editingData.modules.length) {
      setEditingData((state) =>
        update(state, {
          modules: {
            $push: new Array(newNumberOfDays - editingData.modules.length).fill(
              []
            ),
          },
        })
      );
    } else {
      setEditingData((state) => ({
        ...state,
        modules: state.modules.filter(
          (module, index) => index < newNumberOfDays
        ),
      }));
    }
  };

  if ((isLoading || isFetching) && !!programId)
    return (
      <div className="min-h-[100vh] items-center flex justify-center">
        <Spin size="large" />
      </div>
    );

  return (
    <div className="">
      <Header className="px-7 py-8 h-fit leading-none shadow-[0_4px_20px_0_rgba(0,0,0,0.07)]">
        <div
          onKeyDown={(e) => {
            if (e.key === CONS_KEY_UP_ENTER) {
              e.preventDefault();
              const target = e.target as Element;
              if (target.id === KEY_FIELD_ID_NAME) {
                form.submit();
              }
            }
          }}
        >
          <Form
            form={form}
            initialValues={dataToRender}
            validateTrigger="submit"
            onFinish={isCreate ? handleCreate : handleSave}
          >
            <Row align="middle">
              <Col span={8} className="text-lg font-bold">
                {isEditing ? (
                  <Form.Item
                    name={KEY_FIELD_ID_NAME}
                    className="mb-0"
                    rules={RULE_FORM_ITEM_NAME(t("Program Name"))}
                  >
                    <Input
                      className="w-[200px]"
                      maxLength={LIMIT_LENGTH_INPUT.MAX}
                      value={dataToRender?.name}
                      onChange={handleChangeName}
                      width="125px"
                      placeholder={t("Program Name")}
                    />
                  </Form.Item>
                ) : (
                  dataToRender?.name
                )}
              </Col>
              {isEditing && (
                <Col span={7} offset={1} className="">
                  <div className="flex items-center">
                    {t("NUMBER OF DAYS")}
                    <TSButton
                      shape="circle"
                      className="ml-[16px] shadow-none"
                      onClick={handleChangeNumberOfDays(value - 1)}
                      icon={<MinusOutlined />}
                    ></TSButton>
                    <div className="w-[40px] ml-[16px]">
                      <InputNumber
                        className="text-center"
                        width={"40px"}
                        style={{ width: 40, textAlign: "center" }}
                        controls={false}
                        max={MAX_NEW_PROGRAM_DAYS}
                        value={value}
                        onChange={handleChangeInput}
                        onKeyUp={(e) => {
                          if (e.key === CONS_KEY_UP_ENTER) {
                            showModalConfirmChangeInput();
                          }
                        }}
                        onBlur={() => showModalConfirmChangeInput()}
                      />
                    </div>
                    <TSButton
                      shape="circle"
                      className="ml-[16px] shadow-none"
                      onClick={handleChangeNumberOfDays(value + 1)}
                      icon={<PlusOutlined />}
                    ></TSButton>
                  </div>
                </Col>
              )}
              {!isEditing && (
                <Col span={4} offset={12}>
                  <div className="flex justify-end">
                    <TSButton
                      className="text-base font-medium"
                      icon={<EditFilled />}
                      onClick={handleToggleEdit}
                      loading={mutation.isLoading}
                    >
                      {t("Edit")}
                    </TSButton>
                  </div>
                </Col>
              )}
              {isEditing && isCreate && (
                <Col span={4} offset={4}>
                  <div className="flex justify-end">
                    <TSButton
                      className="text-base font-medium mr-[8px]"
                      onClick={handleBack}
                      type="text"
                    >
                      {t("Cancel")}
                    </TSButton>
                    <TSButton
                      className="text-base font-medium"
                      icon={
                        <img
                          src="/images/Vector (1).png"
                          alt="Save"
                        />
                      }
                      htmlType="submit"
                      loading={createMutation.isLoading}
                    >
                      {t("Save")}
                    </TSButton>
                  </div>
                </Col>
              )}
              {isEditing && !isCreate && (
                <Col span={6} offset={2}>
                  <div className="flex justify-end">
                    <TSButton
                      className="text-base font-medium mr-[8px]"
                      onClick={handleToggleEdit}
                      type="text"
                    >
                      {t("Cancel")}
                    </TSButton>
                    <TSButton
                      className="text-base font-medium"
                      icon={
                        <img
                          src="/images/Vector (1).png"
                          alt="Save"
                        />
                      }
                      htmlType="submit"
                      loading={mutation.isLoading}
                    >
                      {t("Save")}
                    </TSButton>
                  </div>
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </Header>
      <div
        ref={containerRef}
        className="relative bg-greyBg flex max-w-[calc(100vw-224px)] overflow-x-scroll h-[calc(100vh-104px)] overflow-y-hidden"
      >
        {isLoading && programId && (
          <div className="flex items-center justify-center w-full">
            <Spin size="large" />
          </div>
        )}
        {isEditing && <ModulePanel />}

        {dataToRender?.modules.map((dayModules, index) => {
          return (
            <DayColumn
              key={index}
              dayModules={dayModules}
              index={index}
              onDrop={(item) => handleDrop(index, item)}
              onMoveModule={handleMoveModule}
              onDeleteModule={handleDeleteModule}
              canDrag={isEditing}
            />
          );
        })}
        {isEditing && <div className="h-[calc(100vh-96px)] px-[140px]"></div>}
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleConfirmModal}
        onCancel={handleCancelModal}
      >
        <div className="flex flex-col items-center my-4">
          <WarningOutlined style={{ fontSize: "2rem", color: "burlywood" }} />
          <p className="text-lg">
            {t("Are you sure to update the program’s number of days?")}
          </p>
        </div>
        <p className="text-md">
          {t(
            "In case of decreasing the number of days, all modules already added to those days will be removed."
          )}
        </p>
      </Modal>
    </div>
  );
}
