const ArrowDownSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    {/* <circle cx="12" cy="12" r="11.5" fill="#E1E1E1" stroke="#C2C2C2" /> */}
    <path
      d="M9 11L12 14L15 11"
      stroke="#454545"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);
export default ArrowDownSVG;
