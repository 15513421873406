import { PlusOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Col, Layout, Row } from "antd";
import FilterComponent from "app/components/FilterGroup/FilterComponent";
import TSButton from "app/components/TSButton";
import { AxiosResponse } from "axios";
import { ChangeEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SessionList from "./SessionList";
import ModalAddSession from "./ModalAddSession";
import { useParams } from "react-router-dom";
import coachApi from "api/coach";
import { IResponseGetMasterclassSession, SessionInfo } from "interfaces/IMasterclass";
import SidebarToggleAction from "app/components/SidebarToggleAction";

export interface SessionInfoWithIndex extends SessionInfo {
  id: string;
}

export default function Session() {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const classPageRef = useRef<HTMLDivElement>(null);
  const { Header } = Layout;
  const { classId } = useParams();
  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const { data, isLoading } = useQuery<AxiosResponse<IResponseGetMasterclassSession>>({
    queryKey: ["classId", classId],
    queryFn: coachApi.getClassSession,
  });

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const [searchValue, setSearchValue] = useState("");

  const filterSessionName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const filter = {
    label: t("Sessions"),
    icon: <img src="/images/education.svg" alt="Classes" />,
    filterMethod: filterSessionName,
  };

  const dataSource = data?.data?.sessions
    ?.map((item, index) => ({ ...item, id: index.toString() }))
    .filter((item) => item.name.toLowerCase().includes(searchValue)) as SessionInfoWithIndex[];

  return (
    <div className="h-screen overflow-hidden">
      <Header className="px-7 py-8 h-fit leading-none shadow-[0_4px_20px_0_rgba(0,0,0,0.07)]">
        <Row align="stretch">
          <SidebarToggleAction containerType="col" />
          <Col span={4} className="text-lg font-bold">
            {data?.data?.name}
          </Col>
          <Col span={11} offset={2} className="uppercase">
            <div className="flex items-center text-sm font-medium">
              <span>{t("Filters")}:</span>
              <div className="ml-5 inline-flex">
                <FilterComponent filterItem={filter} />
              </div>
            </div>
          </Col>

          <Col span={4} offset={2} className="flex flex-row justify-end">
            <TSButton className="text-base font-medium" icon={<PlusOutlined />} onClick={handleOpenModal}>
              {t("New Session")}
            </TSButton>
          </Col>
        </Row>
      </Header>
      <div className="" ref={classPageRef}>
        <SessionList
          dataSource={dataSource}
          isLoading={isLoading}
          renderListData={() => queryClient.invalidateQueries({ queryKey: ["classId"] })}
          classId={classId as string}
          numberOfParticipants={data?.data?.participants?.length ?? 0}
        />
      </div>
      {isOpenModal && (
        <ModalAddSession handleCloseModal={handleCloseModal} isOpen={isOpenModal} classId={classId as string} />
      )}
    </div>
  );
}
