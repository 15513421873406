import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Col, Layout, Row } from "antd";
import { AxiosResponse } from "axios";
import ListParticipant from "./ListParticipant";
import { useNavigate, useParams } from "react-router-dom";
import coachApi from "api/coach";
import { IResponseGetSessionDetail, ParticipantInfo } from "interfaces/IMasterclass";
import { useRef } from "react";
import TransientSider from "app/components/TransientSider";

export default function SessionDetail() {
  const classPageRef = useRef<HTMLDivElement>(null);
  const { Header } = Layout;
  const { classId, sessionIndex } = useParams();

  const navigate = useNavigate();

  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const { data, isLoading } = useQuery<AxiosResponse<IResponseGetSessionDetail>>(
    ["classId", classId, "sessionIndex", sessionIndex],
    () => coachApi.getSessionDetail(classId as string, Number(sessionIndex))
  );

  return (
    <div className="flex flex-row w-full h-screen relative">
      <video
        className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-full h-full object-cover"
        autoPlay
        muted
        loop
      >
        <source src="/videos/audience-listens-speech.mp4" type="video/mp4" />
      </video>

      <TransientSider />

      <div className=" overflow-hidden h-full flex-grow">
        <Header className="px-7 py-8 h-fit leading-none shadow-[0_4px_20px_0_rgba(0,0,0,0.07)] bg-transparent">
          <Row align="stretch">
            <Col className="text-4xl font-bold text-white flex items-center">
              <img
                className="cursor-pointer"
                src="/images/back-btn.svg"
                alt="back-btn"
                width={38}
                height={32}
                onClick={() => navigate(`/masterclass/${classId}`)}
              />
              <p className="ml-5">{data?.data?.session?.name}</p>
            </Col>
          </Row>
        </Header>
        <div className="px-[30px]" ref={classPageRef}>
          <ListParticipant
            dataSource={data?.data?.participants as ParticipantInfo[]}
            isLoading={isLoading}
            renderListData={() => queryClient.invalidateQueries({ queryKey: ["classId", "sessionIndex"] })}
            listAnalysis={data?.data?.session?.analysis ?? []}
          />
        </div>
      </div>
    </div>
  );
}
