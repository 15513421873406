export const timeLineStyle = `
  [part="timeline-wrapper"] {
    margin-top: 8px;
    padding-bottom: 10px;
    border-bottom: 1px solid #F8F8F8;
  }

  [part^="timeline-notch"] {
    height: 100% !important;
    border-left: none !important;
    opacity: 1 !important;
    text-indent: inherit !important;
    text-align: center;
    align-items: center;
    
  }
    
  [part^="timeline-notch"]::before {
    content: "";
    height: 10px;
    width: 0px;
    border-left: 3px solid #F8F8F8;
    position: absolute;
    top: -5px;
  }
`;

export const waveSurferStyle = (canvasHeight: number) =>
  `
  .progress {
    height: ${canvasHeight}px !important;
    background: linear-gradient(270deg, rgba(255, 212, 69, 0.56) 0%, rgba(255, 212, 69, 0.39) 11%, rgba(255, 212, 69, 0.02) 100%) !important;
  }
    
  .cursor {
    display: flex;
    height: ${canvasHeight}px !important;
    justify-content: center
  }
  .cursor::before, .cursor::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 50%;
    position: absolute;
  }
  .cursor::after {
    bottom: 0
  }
  .scroll::-webkit-scrollbar {
    width: 10px;
    height: 0px;
    display: none;
    -webkit-appearance: none;
  }

  /* Track */
  .scroll::-webkit-scrollbar-track {
    background: var(--bg-primary);
    border-radius: 5px;
  }

  /* Track */
  .scroll::-webkit-scrollbar-thumb {
    background: rgba(52, 64, 85, 1);
    border-radius: 5px;
  }

  /* Handle on hover */
  /* Track */
  .scroll::-webkit-scrollbar-thumb:hover {
    background: rgba(52, 64, 85, 1);
  }

  [part^="region"] {
    top: 33% !important;
    background-color: rgba(0,0,0,0) !important;
  }
  .respiration {
    box-sizing: border-box;
    height: 22px;
    width: 22px !important;
    background-color: #FFD445!important;
    border-radius: 50%;
    border: 4px solid black;
  }

  .perturbation {
    box-sizing: border-box;
    height: 22px;
    width: 22px !important;
    background-color: #E14545!important;
    border-radius: 50%;
    border: 4px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: bold;
    margin-top: 0px !important;
  }

  .silence {
    height: 16px;
    background-color: #63CF95!important;
    border-radius: 0.5rem;
    margin-top: 0px !important;
  }
    
  ${timeLineStyle}
  
`;
