import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Layout, Select, Spin, notification } from "antd";
import classApi from "api/class";
import TSButton from "app/components/TSButton";
import ArrowDownSVG from "app/icons/ArrowDown";
import SortBySVG from "app/icons/SortBy";
import { AxiosResponse } from "axios";
import classNames from "classnames";
import html2canvas from "html2canvas";
import update from "immutability-helper";
import { ClassParticipant, IResponseGetClassDetail } from "interfaces/IClass";
import jsPDF from "jspdf";
import { Fragment, RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CircleColor from "./CircleColor";
import DropdownColor from "./DropdownColor";
import ExportButton from "./ExportButton";
import HeaderCell from "./HeaderCell";
import ParticipantPopover from "./ParticipantPopover";
import { NUMBER_OF_WEEKDAYS } from "./constant";
import { getWeekList } from "./helper";
import SidebarToggleAction from "app/components/SidebarToggleAction";

export default function ClassDetail() {
  const { t } = useTranslation();
  const { Header } = Layout;
  const { classId } = useParams();
  const [editingData, setEditingData] = useState<ClassParticipant[]>();
  const [week, setWeek] = useState(0);
  const classDetailRef = useRef<HTMLDivElement>(null);
  const [dataClassDetail, setDataClassDetail] = useState<any[]>([]); // [SCV]
  const {
    data: classDetail,
    isLoading,
    isFetching,
  } = useQuery<AxiosResponse<IResponseGetClassDetail>>(
    ["class", classId],
    () => classApi.getClassDetail(classId || ""),
    {
      enabled: !!classId,
    }
  );
  useEffect(() => {
    if (!editingData) return;
    let dataCSV = [];
    let valueDataCSVList = [];
    const numberOfDayTitleOfSCS = [""];
    const morningAfternoonTitleList = [""];

    if (editingData[0]?.contribution !== null) {
      for (let i = 0; i < editingData[0]?.contribution?.length; i++) {
        numberOfDayTitleOfSCS.push(`Day ${i + 1}`);
        numberOfDayTitleOfSCS.push(``);
        morningAfternoonTitleList.push(`matin`);
        morningAfternoonTitleList.push(`après-midi`);
      }
      for (let i = 0; i < editingData.length; i++) {
        let valueTemp = [];
        valueTemp.push(`${editingData[i]?.info.first_name} ${editingData[i]?.info.last_name}`);
        for (let j = 0; j < editingData[i]?.contribution?.length; j++) {
          valueTemp.push(`${editingData[i].contribution[j].morning.state}`);
          valueTemp.push(`${editingData[i].contribution[j].afternoon.state}`);
        }
        valueDataCSVList.push(valueTemp);
      }
    }
    dataCSV = [numberOfDayTitleOfSCS, morningAfternoonTitleList, ...valueDataCSVList];
    setDataClassDetail(dataCSV);
  }, [editingData]);

  useEffect(() => {
    if (!classDetail?.data) return;

    // Remove the below comment block to be able to add placeholder to modify
    // contribution even if don't have data for that day
    // const numberOfParticipants = classDetail.data.participants.length || 0;

    // let arr: ClassParticipant[] = [];

    // for (
    //   let participantIndex = 0;
    //   participantIndex < numberOfParticipants;
    //   participantIndex++
    // ) {
    //   let contribution: ParticipantContribution[] = [];
    //   for (let dayIndex = 0; dayIndex < NUMBER_OF_WEEKDAYS; dayIndex++) {
    //     const participantDayFromApi =
    //       classDetail.data.participants[participantIndex]?.contribution?.[
    //         dayIndex
    //       ];
    //     contribution.push(
    //       participantDayFromApi || {
    //         morning: {},
    //         afternoon: {},
    //         courses: [],
    //       }
    //     );
    //   }
    //   arr.push({
    //     info: classDetail.data.participants[participantIndex]?.info,
    //     contribution: contribution,
    //   });
    // }

    classDetail && setEditingData(classDetail.data.participants);
  }, [classDetail, isLoading, isFetching]);

  const queryClient = useQueryClient();

  // Mutations
  const mutation = useMutation({
    mutationFn: classApi.updateClass,
    onSuccess: () => {
      // Invalidate and refetch
      notification.success({
        message: "Success",
        description: "Update Class Successfully"!,
      });
      queryClient.invalidateQueries({ queryKey: ["class"] });
    },
    onError: () => {
      notification.error({
        message: "Failed",
        description: "Update Class Failed"!,
      });
    },
  });

  const handleSave = () => {
    mutation.mutate({
      id: classId || "",
      body: editingData || [],
    });
  };

  const handleChangeCircleColor =
    (participantIndex: number, dayIndex: number, courseIndex: number) => (value: boolean) => {
      setEditingData((state) =>
        update(state, {
          [participantIndex]: {
            contribution: {
              [dayIndex]: {
                courses: {
                  [courseIndex]: {
                    is_completed: {
                      $set: value,
                    },
                  },
                },
              },
            },
          },
        })
      );
    };

  const handleChangeDropdownColor =
    ({
      participantIndex,
      dayIndex,
      morning,
      afternoon,
    }: {
      participantIndex: number;
      dayIndex: number;
      morning: boolean;
      afternoon: boolean;
    }) =>
    (classState: string | undefined, isRemote: boolean | undefined) => {
      setEditingData((state) =>
        update(state, {
          [participantIndex]: {
            contribution: {
              [dayIndex]: {
                morning: {
                  state: {
                    // only update (apply) if we are updating morning
                    $apply: (x) => (morning ? classState : x),
                  },
                  is_remote: {
                    // only update (apply) if we are updating morning
                    $apply: (x) => (morning ? isRemote : x),
                  },
                },
                afternoon: {
                  state: {
                    // only update (apply) if we are updating afternoon
                    $apply: (x) => (afternoon ? classState : x),
                  },
                  is_remote: {
                    // only update (apply) if we are updating afternoon
                    $apply: (x) => (afternoon ? isRemote : x),
                  },
                },
              },
            },
          },
        })
      );
    };

  const weekDays = new Array(NUMBER_OF_WEEKDAYS).fill([]);

  const weekList = getWeekList(classDetail?.data?.program?.number_of_days || 0);
  const weekOptions = weekList.map((week, index) => ({
    label: week,
    value: index,
  }));

  const handleChangeWeek = (value: number) => {
    setWeek(value);
  };

  function downloadPdf(refNode: RefObject<HTMLDivElement> | undefined): void {
    if (!refNode) return;
    let input = refNode.current;
    if (input) {
      html2canvas(input, {
        scrollX: 1000,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth * ratio, imgHeight * ratio);
        pdf.save("Classes.pdf");
      });
    }
  }

  if ((isLoading || isFetching || !editingData) && !!classId)
    return (
      <div className="min-h-[100vh] items-center flex justify-center">
        <Spin size="large" />
      </div>
    );
  return (
    <div className="">
      <Header className="px-7 py-8 h-fit leading-none shadow-[0_4px_20px_0_rgba(0,0,0,0.07)]">
        <div className="flex justify-between items-center">
          <SidebarToggleAction containerType="div" />
          <div className="text-lg font-bold">{classDetail?.data.name}</div>
          <div className="text-lg font-bold">{classDetail?.data.boarding_pass}</div>
          <div className="flex gap-4">
            <ExportButton downloadPdf={downloadPdf} classDetailRef={classDetailRef} dataClassDetail={dataClassDetail} />
            <Select
              className="week-select"
              value={week}
              style={{ width: 92, background: "#F1F1F1" }}
              onChange={handleChangeWeek}
              options={weekOptions || undefined}
              removeIcon={() => <ArrowDownSVG />}
              suffixIcon={<ArrowDownSVG />}
            />
            <TSButton
              className="text-base font-medium"
              icon={<img src="/images/Vector (1).png" alt="Save" />}
              htmlType="submit"
              loading={mutation.isLoading}
              onClick={handleSave}
            >
              {t("Save")}
            </TSButton>
          </div>
        </div>
      </Header>
      <div className="h-[calc(100vh-104px)] overflow-auto" ref={classDetailRef}>
        <div className="bg-greyBg flex max-w-[calc(100vw-224px)]">
          {isLoading && (
            <div className="flex items-center justify-center w-full">
              <Spin size="large" />
            </div>
          )}

          <div className="grid grid-cols-classes divide-x divide-y border-b-grey2 border-solid border-t-0 border-x-0 w-full">
            <div className="bg-grey6 h-[70px] border-solid border-grey2 flex items-center pl-6 border-0 border-t">
              Sort by
              <span className="ml-2">
                <SortBySVG />
              </span>
            </div>

            {weekDays.map((_, index) => (
              <HeaderCell key={index} day={week * NUMBER_OF_WEEKDAYS + (index + 1)} />
            ))}

            {classDetail?.data.participants.map((participant, participantIndex) => {
              if (!participant) return null;
              return (
                <Fragment key={participantIndex}>
                  <div
                    className={classNames(
                      "flex items-center shadow-[2px_0px_rgba(0,0,0,0.04)] h-[80px] left-0 sticky z-10 border-solid border-grey2 pl-4 border-r-0",
                      participantIndex % 2 === 0 ? "bg-grey6" : "bg-white"
                    )}
                  >
                    <div className="flex justify-between items-center flex-1">
                      <div>
                        <div
                          style={{
                            background: "black",
                          }}
                          className="w-[8px] h-[8px] rounded-full mr-2 inline-block"
                        ></div>
                        {participant?.info?.first_name} {participant?.info?.last_name}
                      </div>
                      <ParticipantPopover />
                    </div>
                  </div>
                  {weekDays.map((_, weekdayIndex) => {
                    const dayIndex = week * NUMBER_OF_WEEKDAYS + weekdayIndex;
                    return (
                      <Fragment key={weekdayIndex}>
                        <div
                          className={classNames(
                            "p-3 h-[80px] border-solid border-grey2",
                            participantIndex % 2 === 0 ? "bg-grey6" : "bg-white"
                          )}
                        >
                          {participant?.contribution !== null && participant?.contribution?.length - 1 >= dayIndex && (
                            <>
                              <div className="flex gap-[6px]">
                                {/* Morning */}
                                <DropdownColor
                                  title="Matin"
                                  classState={editingData?.[participantIndex]?.contribution?.[dayIndex]?.morning.state}
                                  isRemote={
                                    editingData?.[participantIndex]?.contribution?.[dayIndex]?.morning.is_remote
                                  }
                                  onChange={handleChangeDropdownColor({
                                    participantIndex,
                                    dayIndex,
                                    morning: true,
                                    afternoon: false,
                                  })}
                                />
                                {/* Afternoon */}
                                <DropdownColor
                                  title="Après-midi"
                                  classState={
                                    editingData?.[participantIndex]?.contribution?.[dayIndex]?.afternoon.state
                                  }
                                  isRemote={
                                    editingData?.[participantIndex]?.contribution?.[dayIndex]?.afternoon.is_remote
                                  }
                                  onChange={handleChangeDropdownColor({
                                    participantIndex,
                                    dayIndex,
                                    morning: false,
                                    afternoon: true,
                                  })}
                                />
                              </div>
                              <div className="mt-[6px] flex gap-[6px]">
                                {editingData?.[participantIndex]?.contribution?.[dayIndex]?.courses.map(
                                  (course, courseIndex) => (
                                    <div key={course.id}>
                                      <CircleColor
                                        name={course?.name}
                                        isCompleted={course?.is_completed}
                                        onChange={handleChangeCircleColor(participantIndex, dayIndex, courseIndex)}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
