import { Input } from "antd";
import TSButton from "app/components/TSButton";
import { useTranslation } from "react-i18next";
import { LOGIN_PARAM } from "./constant";

const DoubleAuthenForm = ({ loading }: { loading: boolean }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <label>
          <Input
            className="px-4 py-3 bg-primary text-white border-white border placeholder:text-white focus:border-white hover:border-white"
            name={LOGIN_PARAM.CODE}
            type="text"
            placeholder={t("Code")}
          />
        </label>
      </div>
      <div className="mt-4">
        <TSButton
          className="w-full h-[46px] py-[12px] px-[16px] bg-white text-black border rounded-[5px] hover:!bg-slate-400 hover:!text-primary"
          htmlType="submit"
          type="primary"
          loading={loading}
        >
          {t("Validate")}
        </TSButton>
      </div>
    </>
  );
};

export default DoubleAuthenForm;
