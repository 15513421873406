import { Progress } from "antd";
import "./Participation.css";
import { IParticipationProp } from "./ParticipationComponent.d";

export default function ParticipationComponent({
  percent,
}: IParticipationProp) {
  return (
    <div className="participation">
      <div className="bg-white p-6 w-[245px] h-[233px] rounded-lg border-solid border-greyBorder">
        <div className="text-center mb-5 text-darkGrey2 text-[18px] font-bold ">
          Participation
        </div>
        <div className="flex justify-center ">
          <Progress
            type="circle"
            percent={percent || 0}
            size={130}
            strokeColor={"#63cf95"}
            strokeWidth={10}
            className="font-bold"
          />
        </div>
      </div>
    </div>
  );
}
