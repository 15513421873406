import { notification } from "antd";
import categoryApi from "api/category";
import PopoverActionButton from "app/components/PopoverActionButton/PopoverActionButton";
import NotificationErrorBtn from "app/components/TSNotification/NotificationErrorBtn";
import TSTable from "app/components/TSTable/TSTable";
import { NAME_FEATURE, TYPE_NOTIFICATION } from "common/constants";
import { notificationSuccess } from "common/helper";
import { IResponseGetCategory } from "interfaces";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IListCategory } from "./ListCategory.d";
import { KEY_VALUE, TITLE_COLUMN } from "./constant";

const ListCategory = ({
  dataSource,
  isLoading,
  renderListData,
}: IListCategory) => {
  const { t } = useTranslation();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isShowAction, setIsShowAction] = useState(false);
  const [recordIdSelected, setRecordIdSelected] = useState("");
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const handleOpenPopover = (newOpen: boolean) => {
    setIsOpenPopover(newOpen);
  };
  const handleDeleteCategory = async (id: string) => {
    try {
      await categoryApi.deleteCategory(id);
      renderListData();
      setIsLoadingDelete(false);
      notificationSuccess(TYPE_NOTIFICATION.DELETE, NAME_FEATURE.CATEGORY);
    } catch (err: any) {
      const { data } = err.response;
      notification.error({
        message: "Failed",
        description: data.message,
        btn: (
          <NotificationErrorBtn
            renderListData={renderListData}
            notification={notification}
          />
        ),
      });
      setIsLoadingDelete(false);
    }
  };

  const handleOkDeleteModal = () => {
    setIsLoadingDelete(true);
    handleDeleteCategory(recordIdSelected);
  };

  const columns = [
    {
      title: t(TITLE_COLUMN.CATEGORIES).toUpperCase(),
      dataIndex: KEY_VALUE.NAME_CATEGORY,
      key: KEY_VALUE.NAME_CATEGORY,
      sorter: (a: IResponseGetCategory, b: IResponseGetCategory) =>
        a.name.localeCompare(b.name),
      render: (text: string, record: IResponseGetCategory) => {
        return (
          <div className="w-full inline-flex justify-between items-center">
            <div className="inline-flex items-center">
              <div
                className="w-2 h-2 rounded mr-2"
                style={{
                  backgroundColor: record.color,
                }}
              ></div>
              <span style={{ color: record.color }}>{text}</span>
            </div>
            <PopoverActionButton
              nameModule="category"
              handleOkDelete={handleOkDeleteModal}
              isRecordSelected={isShowAction && recordIdSelected === record.id}
              isOpenPopover={isOpenPopover}
              handleOpenPopover={handleOpenPopover}
            />
          </div>
        );
      },
    },
  ];

  const onMouseEnterOnRowTable = (record: IResponseGetCategory) => {
    setRecordIdSelected(record.id);
    setIsShowAction(true);
  };
  const onMouseLeaveOnRowTable = () => {
    setRecordIdSelected("");
    setIsShowAction(false);
    setIsOpenPopover(false);
  };

  return (
    <TSTable
      isLoading={isLoading || isLoadingDelete}
      dataSource={dataSource}
      columns={columns}
      onMouseEnterRow={onMouseEnterOnRowTable}
      onMouseLeaveRow={onMouseLeaveOnRowTable}
    />
  );
};

export default ListCategory;
