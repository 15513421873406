export enum KEY_VALUE {
  NAME_CATEGORY = "name",
}

export enum TITLE_COLUMN {
  CATEGORIES = "CATEGORIES",
}

export enum COLOR_CODE {
  TUFTS_BLUE = "#3783D3",
  LIBERTY = "#495DAD",
  AMETHYST = "#9462D2",
  CADMIUM_VIOLET = "#8C2294",
  BARBIE_PINK = "#D91D8E",
  MULBERRY = "#C94E81",
  CORAL_RED = "#FE4242",
  INTERNATIONAL_ORANGE = "#BE352D",
  OCHRE = "#D5702A",
  COPPER = "#B57327",
  INDEPENDENCE = "#525264",
  OLD_GOLD = "#C4AF3D",
  GREEN = "#54A231",
  GREEN_CYAN = "#4AA594",
  LIGHT_TAUPE = "#BF836F",
  DARK_OLIVE_GREEN = "#596524",
}

export const listColor = [
  {
    name: "tuftsBlue",
    color: COLOR_CODE.TUFTS_BLUE,
  },
  {
    name: "amethyst",
    color: COLOR_CODE.AMETHYST,
  },
  {
    name: "mulberry",
    color: COLOR_CODE.MULBERRY,
  },
  {
    name: "barbiePink",
    color: COLOR_CODE.BARBIE_PINK,
  },
  {
    name: "ochre",
    color: COLOR_CODE.OCHRE,
  },
  {
    name: "copper",
    color: COLOR_CODE.COPPER,
  },
  {
    name: "green",
    color: COLOR_CODE.GREEN,
  },
  {
    name: "greenCyan",
    color: COLOR_CODE.GREEN_CYAN,
  },
  {
    name: "lightTaupe",
    color: COLOR_CODE.LIGHT_TAUPE,
  },
  {
    name: "liberty",
    color: COLOR_CODE.LIBERTY,
  },
  {
    name: "cadmiumViolet",
    color: COLOR_CODE.CADMIUM_VIOLET,
  },
  {
    name: "oldGold",
    color: COLOR_CODE.OLD_GOLD,
  },
  {
    name: "darkOliveGreen",
    color: COLOR_CODE.DARK_OLIVE_GREEN,
  },
  {
    name: "internationalOrange",
    color: COLOR_CODE.INTERNATIONAL_ORANGE,
  },
  {
    name: "coralRed",
    color: COLOR_CODE.CORAL_RED,
  },
  {
    name: "independence",
    color: COLOR_CODE.INDEPENDENCE,
  },
];
