const TagAltSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M6.24997 5.0001C6.00275 5.0001 5.76107 5.07341 5.55551 5.21076C5.34995 5.34811 5.18973 5.54333 5.09512 5.77174C5.00051 6.00015 4.97576 6.25148 5.02399 6.49396C5.07222 6.73643 5.19127 6.95916 5.36609 7.13398C5.54091 7.30879 5.76363 7.42785 6.00611 7.47608C6.24859 7.52431 6.49992 7.49955 6.72833 7.40494C6.95674 7.31034 7.15196 7.15012 7.28931 6.94456C7.42666 6.739 7.49997 6.49732 7.49997 6.2501C7.49997 5.91857 7.36828 5.60063 7.13386 5.36621C6.89944 5.13179 6.58149 5.0001 6.24997 5.0001ZM17.6 8.9251L10.5916 1.90843C10.5138 1.83119 10.4214 1.77009 10.3199 1.72862C10.2184 1.68715 10.1096 1.66613 9.99997 1.66676H2.49997C2.27896 1.66676 2.067 1.75456 1.91072 1.91084C1.75444 2.06712 1.66664 2.27908 1.66664 2.5001V10.0001C1.66601 10.1098 1.68703 10.2185 1.7285 10.32C1.76997 10.4215 1.83107 10.5139 1.90831 10.5918L8.92497 17.6001C9.39373 18.0683 10.0291 18.3312 10.6916 18.3312C11.3541 18.3312 11.9896 18.0683 12.4583 17.6001L17.6 12.5001C18.0681 12.0313 18.3311 11.3959 18.3311 10.7334C18.3311 10.0709 18.0681 9.43551 17.6 8.96676V8.9251ZM16.425 11.2751L11.275 16.4168C11.1188 16.572 10.9076 16.6591 10.6875 16.6591C10.4673 16.6591 10.2561 16.572 10.1 16.4168L3.33331 9.65843V3.33343H9.65831L16.425 10.1001C16.5022 10.178 16.5633 10.2703 16.6048 10.3718C16.6463 10.4734 16.6673 10.5821 16.6666 10.6918C16.6657 10.9104 16.5789 11.1199 16.425 11.2751Z" />
  </svg>
);
export default TagAltSVG;
