import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, Popover } from "antd";
import { ThreeDot } from "app/icons/ThreeDot";
import { useTranslation } from "react-i18next";
import TSButton from "../TSButton";
import { IPopOverActionButton } from "./PopoverActionButton.d";

const PopoverOverActionButton = ({
  nameModule,
  isRecordSelected,
  handleOpenPopover,
  isOpenPopover,
  handleOkDelete,
  handleEditBtn,
}: IPopOverActionButton) => {
  const { t } = useTranslation();
  const { confirm } = Modal;

  const showDeleteConfirm = () => {
    confirm({
      title: t(`Delete the ${nameModule}`),
      icon: <ExclamationCircleFilled />,
      content: t(`Are you sure to delete this ${nameModule}`),
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk() {
        handleOkDelete();
      },
    });
  };
  return (
    <Popover
      content={
        <div className="inline-flex w-full justify-between">
          <TSButton className="w-[45%]" onClick={handleEditBtn}>
            {t("Edit")}
          </TSButton>
          <TSButton className="w-[45%]" onClick={showDeleteConfirm}>
            {t("Delete")}
          </TSButton>
        </div>
      }
      title={t("Action")}
      trigger="hover"
      open={isRecordSelected && isOpenPopover}
      onOpenChange={handleOpenPopover}
    >
      <ThreeDot
        className={`cursor-pointer ${
          isRecordSelected ? "opacity-100" : "opacity-0"
        }`}
      />
    </Popover>
  );
};

export default PopoverOverActionButton;
