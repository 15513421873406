const HeaderCell = ({ day }: { day: number }) => {
  return (
    <div className="bg-grey6 h-[70px] border-solid border-grey2">
      <div className="text-center mt-4 mb-2">Day {day}</div>
      <div className="flex">
        <div className="flex-1 text-center text-[#848484]">matin</div>
        <div className="flex-1 text-center text-[#848484]">après-midi</div>
      </div>
    </div>
  );
};

export default HeaderCell;
