export const ChartColorLineHex = {
  "total impact": "#63CF95",
  voice: "#DB708A",
  corps: "#FFAE06",
  message: "#D08CC5",
  connaissances: "#24C9D3",
  selfEvaluation: "#1C5E45",
  knowledge: "#5D0458",
  body: "#FFAE06",
};

export const ChartLabels = {
  WEEK: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  MONTHS: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};

export const LabelButton = {
  WEEK: "Week",
  MONTH: "Month",
  TOTAL_DURATION: "Total duration",
  CUSTOM: "Custom",
};

export const LabelName = {
  TOTAL_IMPACT: "Total impact",
  VOIX: "Voix",
  CORPS: "Corps",
  MESSAGE: "Message",
  CONNAISSANCES: "Connaissances",
  SELF_EVALUATION: "Self-evaluation",
};

export const DAYS_OF_WEEK = 7;

export const DAYS_OF_MONTH = 30;
