import { useCallback, useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";

const getWaveColor = (val: number) => {
  if (val < 0.01) return "#4CC9F0";
  if (val < 0.02) return "#F72585";
  else return "#F8F8F8";
};

const FrequencyChart = ({
  data,
  started,
  stopped,
}: {
  data: { x: number[]; y: number[] };
  started: boolean;
  stopped: boolean;
}) => {
  const [is4K, setIs4K] = useState(false);
  const wrapperRef = useCallback((ref: HTMLDivElement) => {
    setIs4K(ref?.getBoundingClientRect?.().width === 1600);
  }, []);
  const [isHovered, setIsHovered] = useState(false);
  const distance = data.y.reduce((acc, curr) => {
    if (curr < 0.02 && curr > 0.01) return acc + (is4K ? 38 : 19);

    return acc + (is4K ? 12 : 6);
  }, 0);

  let silenceWidth = 3;

  const [timeMarkers, setTimeMarkers] = useState([{ time: 0, position: 0 }]);

  useEffect(() => {
    if (!started) return;
    const intervalId = setInterval(() => {
      const currentDistance = data.y.reduce((acc, curr) => {
        if (curr < 0.02 && curr > 0.01) return acc + (is4K ? 38 : 19);
        return acc + (is4K ? 12 : 6);
      }, 0);
      setTimeMarkers((prev) => [
        ...prev,
        { time: prev.length * 5, position: currentDistance },
      ]);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [data, is4K, started]);

  return (
    <div
      ref={wrapperRef}
      onMouseEnter={() => (started || stopped) && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`fixed bottom-[2vh] z-10 transition-all duration-300 ease-linear rounded-xl left-1/2 transform -translate-x-1/2 origin-top bg-[#7B7B7B]/60 backdrop-blur-md max-w-[800px] 3xl:max-w-[1600px]
        ${isHovered ? "h-[250px] 3xl:h-[500px]" : "h-[150px] 3xl:h-[300px] "}`}
    >
      <div className="h-[130px] 3xl:h-[260px] border-0 border-r-2 border-r-[white] border-solid audio-cursor w-[120px] 3xl:w-[240px] bg-gradient-to-r absolute right-4 top-[10px] 3xl:top-[20px] from-yellow-400/5 to-yellow-600/60"></div>

      {!started && !stopped && (
        <div className="text-white text-[20px] 3xl:text-[40px] w-full text-center font-medium flex flex-col justify-center h-full">
          <div>Démarrez l'enregistrement dès que vous êtes prêt</div>
        </div>
      )}
      <div className={`waves-wrapper z-10 p-4 pb-0`}>
        <div
          className="h-[130px] 3xl:h-[260px]"
          style={{
            transform: `translateX(${-distance - 4 + "px"})`,
            width: "12000px",
            transition: started ? "all 1s linear" : "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          {data.y.map((val, i) => {
            if (val >= 0.01) {
              silenceWidth = is4K ? 6 : 3;
            }
            if (i < data.y.length - 1 && data.y[i + 1] < 0.01 && val < 0.01) {
              silenceWidth += is4K ? 12 : 6;
              return <></>;
            }
            if (val < 0.01)
              return (
                <div
                  key={`wave-${i}`}
                  className="h-4 3xl:h-8 bg-[#63CF95] rounded-full relative left-[100%] ml-[3px] 3xl:ml-[6px]"
                  style={{ width: silenceWidth }}
                ></div>
              );
            if (val < 0.02)
              return (
                <div
                  key={`wave-${i}`}
                  className="h-4 3xl:h-8 relative left-[100%] flex items-center justify-center w-4 3xl:w-8 bg-red-500 border-solid border-[3px] 3xl:border-[6px] border-black rounded-full ml-[3px] 3xl:ml-[6px]"
                >
                  <RxCross2 />
                </div>
              );
            return (
              <div
                key={`wave-${i}`}
                className="wave max-h-[118px] ml-[3px] 3xl:ml-[6px] 3xl:max-h-[236px] w-[3px] 3xl:w-[6px]"
                style={{
                  height: `${val * (is4K ? 3000 : 1500)}px`,
                  backgroundColor: getWaveColor(val),
                }}
              ></div>
            );
          })}
        </div>
      </div>
      {isHovered && (
        <div className="p-4 pt-0">
          <div className="waves-wrapper py-4">
            <div
              className="flex items-center relative"
              style={{
                transform: `translateX(${-distance + "px"})`,
                transition: started ? "all 1s linear" : "none",
              }}
            >
              {timeMarkers.map((timeMarker, index) => (
                <div
                  className="flex flex-col items-center absolute"
                  key={index}
                  style={{
                    left: timeMarker.position + "px",
                    transform: "translateX(-50%)",
                  }}
                >
                  <div className="time-indicator"></div>
                  <p
                    className="text-white"
                    style={{
                      visibility:
                        timeMarker.time % 10 === 0 ? "visible" : "hidden",
                    }}
                  >
                    {timeMarker.time}s
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="border-t-[1px] border-solid border-white w-full mb-4"></div>
          <div className="flex justify-center text-white">
            <div className="flex flex-col items-center">
              <div className="text-black h-4 flex items-center justify-center w-4 bg-[#FFD445] border-[3px] border-black border-solid rounded-full ml-[3px]"></div>
              <div className="mt-1">Respirations</div>
            </div>
            <div className="ml-4 flex flex-col items-center">
              <div className="text-black h-4 flex items-center justify-center w-4 bg-red-500 border-[3px] border-black border-solid rounded-full ml-[3px]">
                <RxCross2 />
              </div>
              <div className="mt-1">Perturbations</div>
            </div>
            <div className="ml-4 flex flex-col items-center">
              <div className="h-4 w-9 bg-[#63CF95] rounded-full relative "></div>
              <div className="mt-1">Silences</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FrequencyChart;
