import { Button } from "antd";
import ITSButton from "./TSButton";

const TSButton = ({ children, ...props }: ITSButton) => {
  return (
    <Button className="w-full" type="primary" {...props}>
      {children}
    </Button>
  );
};

export default TSButton;
