const SortBySVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="none"
  >
    <path
      d="M1 10L4 13L7 10"
      stroke="#454545"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M7 5L4 2L1 5"
      stroke="#454545"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);
export default SortBySVG;
