const LineChartSVG = () => (
  <svg
    width="16"
    height="9"
    viewBox="0 0 16 9"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.5455 0C13.4909 0 12.9018 1.08 13.1418 1.8825L10.56 4.5525C10.3418 4.485 10.0218 4.485 9.80364 4.5525L7.94909 2.64C8.19636 1.8375 7.60727 0.75 6.54545 0.75C5.49091 0.75 4.89455 1.83 5.14182 2.64L1.82545 6.0525C1.04727 5.805 0 6.4125 0 7.5C0 8.325 0.654545 9 1.45455 9C2.50909 9 3.09818 7.92 2.85818 7.1175L6.16727 3.6975C6.38545 3.765 6.70546 3.765 6.92364 3.6975L8.77818 5.61C8.53091 6.4125 9.12 7.5 10.1818 7.5C11.2364 7.5 11.8327 6.42 11.5855 5.61L14.1745 2.9475C14.9527 3.195 16 2.5875 16 1.5C16 0.675 15.3455 0 14.5455 0Z" />
  </svg>
);
export default LineChartSVG;
