import { useQuery } from "@tanstack/react-query";
import { Dropdown, MenuProps, Space } from "antd";
import analysisApi from "api/analysis";
import coachApi from "api/coach";
import BackIconButton from "app/components/BackIconButton/BackIconButton";
import { DownOutlined } from "app/icons/DownOutlined";
import { AxiosResponse } from "axios";
import { IAnalysis } from "interfaces/IAnalysis";
import {
  IResponseGetSessionDetail,
  ParticipantInfo,
} from "interfaces/IMasterclass";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import EmotionChart from "../../chart/EmotionChart";
import "./index.css";

import SpeedChart from "app/chart/SpeedChart";
import SpeedMeterChart from "app/chart/SpeedMeterChart";
import VoiceFrequencyChart from "app/chart/VoiceFrequencyChart";
import TransientSider from "app/components/TransientSider";
import VideoPlayer from "app/components/VideoPlayer";
import {
  onGetVideo,
  onGetVideoBlob,
  resetState,
  useVideoContext,
} from "context/VideoContext";
import { useBoolean } from "hooks/useBoolean";
import useWindowSize from "hooks/WindowResize";
import {
  average,
  useAllAnalysisProgresses,
  useGetAudioAnnotationScore,
  useGetEmotionData,
  useGetParticipantsWithAnalysis,
  useGetTranscripts,
  useGetViteseeData,
  useGetVoiceAnalysisData,
} from "./index.hook";

const ParticipantAnalysis = () => {
  const { t } = useTranslation();
  const { classId, sessionIndex } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const participantId = searchParams.get("participantId");

  const navigate = useNavigate();

  const [windowWidth] = useWindowSize();

  const { dispatch, state } = useVideoContext();

  const { currentViteseeIndex } = state;

  const [selectedUser, setSelectedUser] = useState<ParticipantInfo | undefined>(
    undefined
  );

  const [userAnalysisId, setUserAnalysisId] = useState<string | undefined>(
    undefined
  );

  const viteseeContainerRef = useRef<HTMLDivElement>(null);
  const voiceFrequencyRef = useRef<HTMLDivElement>(null);

  const [voiceFrequencyWidth, setVoiceFrequencyWidth] = useState(385);

  const isOpenMenu = useBoolean(false);

  const dropdownDivRef = useRef<HTMLDivElement>(null);
  const chartDivRef = useRef<HTMLDivElement>(null);
  const [dropdownWidth, setDropdownWidth] = useState<number | string>("100%");
  const [marginDropdown, setMarginDropdown] = useState<number>(0);
  const windowSize = useWindowSize();

  /**
   * Get sesion by classId and session index
   */
  const { data: sessionData } = useQuery<
    AxiosResponse<IResponseGetSessionDetail>
  >(["classId", classId, "sessionIndex", sessionIndex], () =>
    coachApi.getSessionDetail(classId as string, Number(sessionIndex))
  );

  const participants = useGetParticipantsWithAnalysis(sessionData?.data);

  const analysisProgresses = useAllAnalysisProgresses(participants).map(
    (item) => item.data?.data
  );

  /**
   * Get analysis by analysis id
   */
  const { data: analysisData } = useQuery<AxiosResponse<IAnalysis>>(
    ["analysisId", userAnalysisId],
    () => analysisApi.getAnalysis(userAnalysisId || ""),
    { enabled: Boolean(userAnalysisId) }
  );

  /**
   * Download video with analysis id
   */
  useQuery(
    ["fetchVideo", userAnalysisId],
    () => analysisApi.fetchVideo(userAnalysisId || ""),
    {
      onSuccess: (response) => {
        const url = window.URL.createObjectURL(response.data);
        dispatch(onGetVideo(url));
        dispatch(onGetVideoBlob(response.data));
      },
      onError: (error) => {
        console.error("Error fetching video:", error);
      },
      enabled: Boolean(userAnalysisId),
    }
  );

  const emotionChartData = useGetEmotionData(analysisData?.data);

  const { hertzData, respirationsData, silencesData, perturbationsData } =
    useGetVoiceAnalysisData(analysisData?.data);

  const {
    viteseeData = [],
    videoDuration,
    // time = [],
    speechrates = [],
  } = useGetViteseeData(analysisData?.data);

  const transcripts = useGetTranscripts(analysisData?.data);

  const audioScores = useGetAudioAnnotationScore(
    analysisData?.data.results.audio_annotations,
    analysisData?.data.results.silences
  );

  useEffect(() => {
    setVoiceFrequencyWidth(
      (voiceFrequencyRef.current?.getBoundingClientRect().width || 385) - 20
    );
  }, [windowWidth]);

  /**
   * Set first user of session as initialize selected user
   */
  useEffect(() => {
    if (participantId) {
      const user = participants.find((par) => par.id === participantId);
      setSelectedUser(user);
    } else {
      setSelectedUser(participants[0]);
    }
  }, [sessionData]);

  /**
   * Set analysis id when change user from dropdown
   */
  useEffect(() => {
    if (selectedUser) {
      const userIndex = sessionData?.data.participants.findIndex(
        (user) => user.id === selectedUser?.id
      );

      if (typeof userIndex === "number" && userIndex >= 0) {
        const analysisId = sessionData?.data.session.analysis[userIndex];
        if (analysisId) {
          setUserAnalysisId(analysisId);
          searchParams.set("participantId", selectedUser.id);
          setSearchParams(searchParams);
        } else {
          setUserAnalysisId("");
        }
      }
      dispatch(resetState());
      // refetch();
    }
  }, [selectedUser, sessionData]);

  useLayoutEffect(() => {
    if (chartDivRef.current) {
      setDropdownWidth(chartDivRef.current.clientWidth);
    }

    if (dropdownDivRef.current) {
      setMarginDropdown(dropdownDivRef.current.clientHeight + 16);
    }
  }, [
    dropdownDivRef.current?.clientHeight,
    chartDivRef.current?.clientWidth,
    windowSize,
  ]);
  useEffect(() => {
    document.body.style.backgroundColor = "#282932";

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleClickBackButton = () => {
    navigate(`/masterclass/${classId}/${sessionIndex}`);
  };

  /**
   * Dropdown menu items
   */
  const items: MenuProps["items"] = participants
    ?.map((user, index) => {
      if (analysisProgresses[index] === 0) {
        return null;
      }
      return {
        key: user.id,
        className: `text-[24px] 3xl:text-5xl font-bold text-left !bg-[#282932] hover:bg-[#282932] hover:text-white !px-5  ${
          analysisProgresses[index] === 0 ? "!cursor-default" : ""
        }`,
        onClick: () => {
          if (analysisProgresses[index] === 1) {
            setSelectedUser(user);
            isOpenMenu.onFalse();
          }
        },
        label: (
          <Space
            className={`text-[24px] 3xl:text-5xl font-bold text-left !bg-[#282932] !hover:bg-[#282932]  ${
              user.id === selectedUser?.id ? "text-white" : "text-[#9F9F9F]"
            } ${
              analysisProgresses[index] === 0
                ? "hover:text-[#9F9F9F] !cursor-default"
                : "hover:text-white"
            }`}
          >
            {`${user?.first_name || ""} ${user?.last_name || ""}`}
          </Space>
        ),
      };
    })
    .filter((item) => item !== null);

  const speedChart = useMemo(() => {
    return (
      <SpeedChart
        data={viteseeData}
        currentIndex={currentViteseeIndex}
        videoDuration={videoDuration || 1}
        // containerWidth={viteseeContainerRef.current?.getBoundingClientRect().width || 503}
      />
    );
  }, [viteseeData, currentViteseeIndex, videoDuration]);

  return (
    <div className="h-full w-full flex flex-row bg-primary">
      <TransientSider />

      <div className="flex flex-col p-3 3xl:p-10 bg-primary 2xl:h-[1080px] 3xl:h-screen pt-5 3xl:pt-10 w-full">
        <BackIconButton onClick={handleClickBackButton} className="mt-4 mb-8" />

        <div className="flex flex-row relative gap-4 3xl:gap-8 3xl:gap-[1vw] w-full">
          {isOpenMenu.value ? (
            <div className="h-full w-full rounded-xl absolute bg-[rgba(17,17,17,0.05)] backdrop-blur-md z-10" />
          ) : null}

          <div className="flex flex-col gap-4 3xl:gap-8">
            {/* Dropdown menu */}
            <div
              className="flex flex-col mb-2 cursor-pointer absolute z-20 top-0"
              style={{
                width: dropdownWidth,
              }}
              ref={dropdownDivRef}
            >
              <div className="text-3xl font-normal text-white">
                <p>{t("Analyse complète")}</p>
              </div>
              <Dropdown
                open={isOpenMenu.value}
                menu={{ items: items || [] }}
                trigger={["click"]}
                className={`rounded-xl bg-[#282932] !w-full flex justify-between items-center !p-5 !mt-4`}
                onOpenChange={(open) => {
                  isOpenMenu.setValue(open);
                }}
                disabled={participants.length === 0}
              >
                <div>
                  <Space
                    className={`text-[24px] 3xl:text-5xl font-bold text-white text-center bg-[#282932] cursor-pointer ${
                      selectedUser ? "visible" : "invisible"
                    }`}
                  >
                    {`${selectedUser?.first_name} ${selectedUser?.last_name}`}
                  </Space>
                  <DownOutlined
                    width={24}
                    style={{ fontSize: 20, fontWeight: "bold" }}
                    className={`text-white cursor-pointer transition-[300ms] ease-out ${
                      isOpenMenu.value ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </div>
              </Dropdown>
            </div>

            <div
              className="flex flex-row gap-4 3xl:gap-8"
              style={{
                marginTop: marginDropdown,
              }}
            >
              {/* Speed meter chart */}
              <div
                className="rounded-xl bg-[#282932] grid grid-cols-[50%_50%] p-[10px] w-[416px] h-[273px] 2xl:w-[543px] 3xl:w-[1086px] 2xl:h-[308px] 3xl:h-[616px]"
                ref={chartDivRef}
              >
                <SpeedMeterChart
                  label={t("Hertz")}
                  hasRangeNeedle
                  data={hertzData ? hertzData : undefined}
                />
                <SpeedMeterChart
                  label={t("Respirations / min")}
                  data={respirationsData ? respirationsData : undefined}
                />
                <SpeedMeterChart
                  label={t("Silences >2s / min")}
                  data={silencesData ? silencesData : undefined}
                />
                <SpeedMeterChart
                  label={t("Perturbations / min")}
                  data={perturbationsData ? perturbationsData : undefined}
                />
              </div>

              {/* Emotion chart */}
              <div
                className={`rounded-xl bg-[#282932] relative w-[385px] h-[273px] 2xl:w-[503px] 3xl:w-[1006px] 2xl:h-[308px] 3xl:h-[616px]`}
              >
                <p className="absolute top-5 left-5 text-white text-2xl 3xl:text-[48px] font-bold">
                  {t("Emotion")}
                </p>
                <EmotionChart data={emotionChartData} />
              </div>
            </div>

            <div className="flex flex-row gap-4 3xl:gap-8 max-h-[477px] 3xl:max-h-[954px]">
              {/* Retranscription */}
              <div
                className={`flex flex-col rounded-xl bg-[#282932] p-5 w-[416px] 2xl:w-[543px] 3xl:w-[1086px]`}
              >
                <div
                  className={`font-bold text-2xl 3xl:text-[48px] text-white mb-5 3xl: mb-10`}
                >
                  {t("Retranscription")}
                </div>
                <div className="font-semibold text-xl 3xl:text-[40px] text-white  3xl:leading-10 max-h-[380px] 3xl:max-h-[760px]">
                  {transcripts}
                </div>
              </div>

              <div className="flex flex-col gap-4 3xl:gap-8">
                {/* Vitesse */}
                <div
                  className={`rounded-xl bg-[#282932] relative w-[385px] h-[216px] 2xl:w-[503px] 3xl:w-[1006px] 3xl:h-[532px]`}
                  ref={viteseeContainerRef}
                >
                  <div className="absolute top-5 left-5">
                    <p
                      className={`font-bold text-2xl 3xl:text-[48px] 3xl:leading-[48px] text-white mb-2`}
                    >
                      {t("Vitesse")}
                    </p>
                    <p className="font-bold text-[#9F9F9F] text-xs 3xl:text-[24px]">
                      {average(speechrates || []).toFixed(1)} syl. / S
                    </p>
                  </div>
                  <div className="w-full h-full">{speedChart}</div>
                </div>

                {/* Voice visualizer */}
                <div
                  className="rounded-xl bg-[#282932] p-[10px_10px_0_10px] w-[385px] 2xl:w-[503px] 3xl:w-[1006px] h-[245px] 3xl:h-[490px]"
                  ref={voiceFrequencyRef}
                >
                  <VoiceFrequencyChart
                    audioScores={audioScores}
                    containerWidth={voiceFrequencyWidth}
                    containerHeight={225}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white ml-auto rounded-[20px] w-full">
            <VideoPlayer
              viteseeDataLength={viteseeData.length}
              totalDuration={videoDuration || 1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantAnalysis;
