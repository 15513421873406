import { CloseOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Popover, Radio, Space } from "antd";
// import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useState } from "react";

const ParticipantPopover = () => {
  //   const [value, setValue] = useState(true);
  const [clicked, setClicked] = useState(false);

  const handleClickChange = (open: boolean) => {
    setClicked(open);
  };

  const hide = () => {
    setClicked(false);
  };

  const options = [
    { label: "Cours suivi partiellement", value: "Cours suivi partiellement" },
    {
      label: "Le cours a été suivi sans interruption",
      value: "Le cours a été suivi sans interruption",
    },
    {
      label: "Le cours n’a pas été suivi",
      value: "Le cours n’a pas été suivi",
    },
  ];

  const content = (
    <div className="">
      <Button
        className="absolute right-[8px] top-[8px]"
        onClick={hide}
        type="text"
        icon={<CloseOutlined />}
      ></Button>

      <Radio.Group>
        <Space direction="vertical">
          {options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </div>
  );

  return (
    <Popover
      overlayClassName="w-[280px] circle-popover"
      content={content}
      //   title="Sources de malentendu (partie 2)"
      trigger="click"
      open={clicked}
      onOpenChange={handleClickChange}
    >
      <Button type="text" icon={<MoreOutlined />}></Button>
    </Popover>
  );
};

export default ParticipantPopover;
