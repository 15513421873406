import { DeleteOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row } from "antd";
import TSButton from "app/components/TSButton";
import TSModal from "app/components/TSModal/TSModal";
import { LIMIT_LENGTH_INPUT, REGEX_PATTERN_INPUT } from "common/constants";
import { NewParticipantsInfo } from "interfaces/IClass";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export interface IModalAdd {
  handleCloseModal: () => void;
  isOpen: boolean;
  onOk: (data: NewParticipantsInfo[]) => void;
}

export interface FormData {
  new_participants_info: {
    email: string;
    first_name: string;
    last_name: string;
  }[];
}

const initialValues: FormData = {
  new_participants_info: [
    {
      email: "",
      first_name: "",
      last_name: "",
    },
  ],
};

const ModalAddNewParticipant: React.FC<IModalAdd> = ({
  handleCloseModal,
  isOpen,
  onOk,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<FormData>();

  const handleAdd = useCallback(
    (values: FormData) => {
      const filterData = values.new_participants_info.filter(
        (item) =>
          !isEmpty(item.first_name) &&
          !isEmpty(item.last_name) &&
          !isEmpty(item.email)
      );

      onOk(
        filterData.map((value) => ({
          email: value.email,
          first_name: value.first_name,
          last_name: value.last_name,
        }))
      );
      handleCloseModal();
    },
    [onOk, handleCloseModal]
  );

  const handleCancel = () => {
    form.resetFields();
    handleCloseModal();
  };

  const handleChange =
    (add: () => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const formValue = form.getFieldsValue().new_participants_info;

      if (
        !isEmpty(formValue[formValue.length - 1]?.first_name) &&
        !isEmpty(formValue[formValue.length - 1]?.last_name) &&
        !isEmpty(formValue[formValue.length - 1]?.email)
      ) {
        add();
      }
    };

  return (
    <TSModal
      title={t("Add new participants")}
      open={isOpen}
      confirmLoading={false}
      onCancel={handleCancel}
      width="744px"
    >
      <Form
        name="dynamic_form_nest_item"
        onFinish={handleAdd}
        autoComplete="off"
        form={form}
        validateTrigger={"submit"}
        initialValues={initialValues}
      >
        <Form.List name="new_participants_info">
          {(fields, { add, remove }) => (
            <>
              <Row className="mb-4">
                <Col span={6} offset={1}>
                  <span>{t("Name")}</span>
                </Col>
                <Col span={6}>
                  <span>{t("Surname")}</span>
                </Col>
                <Col span={10}>
                  <span>{t("Email")}</span>
                </Col>
              </Row>
              {fields.map(({ key, name, ...restField }, index) => (
                <div className="mb-4 w-full" key={key}>
                  <Row>
                    <Col span={1} className="flex">
                      <div className="flex items-center">{index + 1}</div>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        className="mb-0 mr-2 "
                        name={[name, "first_name"]}
                        required={false}
                        rules={[
                          {
                            required: index < fields.length - 1,
                            message: t(`Please input your First Name!`),
                          },
                          {
                            min: LIMIT_LENGTH_INPUT.MIN,
                            message: t(
                              `First Name must be minimum 3 characters`
                            ),
                          },
                          {
                            max: LIMIT_LENGTH_INPUT.MAX,
                            message: t(
                              `First Name must be maximum 64 characters`
                            ),
                          },
                          {
                            pattern: new RegExp(REGEX_PATTERN_INPUT),
                            message: t(`First Name is invalid`),
                          },
                        ]}
                      >
                        <Input onChange={handleChange(add)} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        className="mb-0 mr-2 "
                        name={[name, "last_name"]}
                        required={false}
                        rules={[
                          {
                            required: index < fields.length - 1,
                            message: t(`Please input your Last Name!`),
                          },
                          {
                            min: LIMIT_LENGTH_INPUT.MIN,
                            message: t(
                              `Last Name must be minimum 3 characters`
                            ),
                          },
                          {
                            max: LIMIT_LENGTH_INPUT.MAX,
                            message: t(
                              `Last Name must be maximum 64 characters`
                            ),
                          },
                          {
                            pattern: new RegExp(REGEX_PATTERN_INPUT),
                            message: t(`Last Name is invalid`),
                          },
                        ]}
                      >
                        <Input onChange={handleChange(add)} />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        {...restField}
                        className="mb-0 mr-2 "
                        name={[name, "email"]}
                        rules={[
                          {
                            required: index < fields.length - 1,
                            message: t("Please input your email!"),
                          },
                          {
                            max: LIMIT_LENGTH_INPUT.MAX,
                            message: t("Name must be maximum 64 characters"),
                          },
                          {
                            type: "email",
                            message: t("Email is invalid"),
                          },
                        ]}
                      >
                        <Input onChange={handleChange(add)} />
                      </Form.Item>
                    </Col>
                    {index < fields.length - 1 && (
                      <Col className="flex" span={1}>
                        <div className="flex items-center">
                          <DeleteOutlined
                            onClick={() => remove(name)}
                            title={t("Remove")}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              ))}

              <Row className="mb-4 ">
                <Col span={6} offset={1}>
                  <Form.Item
                    className="mb-0 mr-2 "
                    name={["new_participants_info", "first_name"]}
                  >
                    <Input onChange={handleChange(add)} disabled />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    className="mb-0 mr-2 "
                    name={["new_participants_info", "last_name"]}
                  >
                    <Input onChange={handleChange(add)} disabled />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    className="mb-0 mr-2 "
                    name={["new_participants_info", "email"]}
                  >
                    <Input onChange={handleChange(add)} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
        <Form.Item>
          <div className="flex justify-end">
            <TSButton
              type="text"
              htmlType="submit"
              loading={false}
              className="px-8 h-10 text-base bg-[#EAEAEA] focus:bg-[#EAEAEA] hover:bg-[#EAEAEA]"
              onClick={handleCloseModal}
            >
              {t("Cancel")}
            </TSButton>
            <TSButton
              type="primary"
              htmlType="submit"
              loading={false}
              className="px-8 h-10 text-base ml-4"
            >
              {t("Add")}
            </TSButton>
          </div>
        </Form.Item>
      </Form>
    </TSModal>
  );
};

export default ModalAddNewParticipant;
