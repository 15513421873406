import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";
import reportsApi from "api/reports";
import ParticipationComponent from "app/components/Participation/ParticipationComponent";
import ProgressLineComponent from "app/components/ProgressLine/ProgressLineComponent";
import RadarComponent from "app/components/Radar/RadarComponent";
import RateComponent from "app/components/Rate/RateComponent";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import ReportChart, { getRandomHexColor } from "./ReportChart";
import { ChartColorLineHex } from "./report.constant";
import { IResponseGetSummaryReport, IScores } from "./type";
import { IReportQuery } from "interfaces/IReport";
import { t } from "i18next";

function AllReports({ classIDs, participantIDs }: IReportQuery) {
  const {
    data: chartData,
    isLoading,
    isError,
  } = useQuery<AxiosResponse<IResponseGetSummaryReport>>({
    queryKey: ["reports", classIDs, participantIDs],
    retry: 0,
    queryFn: () => reportsApi.getSummary({ classIDs, participantIDs })
  });

  const [chartWithTotalImpact, setChartWithTotalImpact] =
    useState<IResponseGetSummaryReport>();

  useEffect(() => {
    function totalImpact() {
      const newChartData = JSON.parse(JSON.stringify(chartData?.data));
      const scores: IScores = chartData?.data.scores || {
        voice: [],
        body: [],
        message: [],
        knowledge: [],
        "total impact": [],
      };
      let keys = Object.keys(scores);

      // Calculate the totalImpact array
      if (!scores) return;
      if (!scores[keys[0] as keyof IScores]) return;
      let totalImpact = scores[keys[0] as keyof IScores].map((value, index) => {
        return (
          keys.reduce((sum, key) => sum + scores[key][index], 0) / keys.length
        );
      });

      // Add the totalImpact array to the scores object
      scores["total impact"] = totalImpact;

      // Display the updated scores object
      newChartData.scores = scores;
      setChartWithTotalImpact(newChartData);
    }
    if (chartData?.data) {
      totalImpact();
    }
  }, [chartData]);
  function renderProgressChart() {
    if (!chartData) return;
    if (!chartWithTotalImpact) return;
    const keysScoreList = Object.keys(chartData.data.scores);

    return keysScoreList.map((keyScore: any) => {
      if (keyScore === "total impact") return null;
      if (!chartWithTotalImpact.scores["total impact"]) return null;
      return (
        <div
          key={keyScore}
          className="w-[245px] h-[233px] flex flex-col justify-between capitalize"
        >
          <ProgressLineComponent
            title={keyScore}
            total={9999}
            current={chartWithTotalImpact.scores[keyScore].slice(-1)[0]}
            color={getRandomHexColor(keyScore, ChartColorLineHex)}
          />
        </div>
      );
    });
  }
  if (isLoading)
    return (
      <div className="min-h-[100vh] items-center flex justify-center">
        <Spin size="large" />
      </div>
    );

  if (isError) {
    return (
      <div className="min-h-[100vh] items-center flex justify-center text-lg font-bold">
        {t("nothing_selected_report")}
      </div>
    );
  }

  return (
    <div className="bg-greyBg pt-6">
      {chartWithTotalImpact && <ReportChart chartData={chartWithTotalImpact} />}
      <div className="flex m-6 justify-start flex-wrap gap-6 mt-12">
        <ParticipationComponent percent={chartData?.data.contribution} />
        <RateComponent total={10} current={chartData?.data.satisfaction} />
        {chartWithTotalImpact?.scores && (
          <div className="w-[245px] h-[233px] flex flex-col justify-between">
            {renderProgressChart()}
          </div>
        )}
        <div className="bg-white w-[245px] h-[233px] flex justify-center rounded-lg border-solid border-greyBorder">
          {chartWithTotalImpact && (
            <RadarComponent data={chartData?.data.colors ?? []} />
          )}
        </div>
      </div>
    </div>
  );
}
export default AllReports;
