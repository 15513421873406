import { LOGIN_RESPONSE_DATA } from "app/containers/Auth/Login/constant";
import { axiosClient } from "./apiClient";

const authenApi = {
  async validate({
    username,
    body,
  }: {
    username: string;
    body: { code: number };
  }) {
    const url = `/auth/tf_validate`;
    const { data } = await axiosClient.post(url, body, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    localStorage.setItem(
      "user",
      JSON.stringify({
        username,
        token: data[LOGIN_RESPONSE_DATA.ACCESS_TOKEN],
      })
    );
  },
};

export default authenApi;
