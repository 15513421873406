import React, { memo } from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from "recharts";

import { useBoolean } from "hooks/useBoolean";
import { IEmotionItem } from "interfaces/IAnalysis";
import { max, maxBy, sumBy } from "lodash";

interface IEmotionChartProps {
  data: Array<IEmotionItem>;
}

const EmojiCircle: React.FC<{
  icon: string;
  color: string;
  item: IEmotionItem;
}> = ({ icon, color, item }) => {
  const svgWidth = 100; // Total size of the SVG
  const svgHeight = 100; // Total size of the SVG
  const circleRadius = svgWidth / 2 - 10; // Circle radius with 10px padding
  const centerWidth = svgWidth / 2; // Center of the circle
  const centerHeight = svgHeight / 2; // Center of the circle

  const isShowlabel = useBoolean();

  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      preserveAspectRatio="xMaxYMax meet"
      className="relative"
    >
      <foreignObject
        x={centerWidth - 50}
        y={centerHeight - 28}
        width={svgWidth}
        height={40}
      >
        <div
          className="py-[4px] px-[4px] bg-[#E6E6E6] rounded-[10px] font-bold text-black text-center"
          style={{ display: isShowlabel.value ? "block" : "none" }}
        >
          {item?.name}
        </div>
      </foreignObject>

      <image
        onMouseEnter={isShowlabel.onTrue}
        onMouseLeave={isShowlabel.onFalse}
        href={icon}
        x={centerWidth - 10}
        y={centerHeight}
        height="28"
        width="28"
        className="cursor-pointer"
      />
    </svg>
  );
};

const EmotionChart = ({ data }: IEmotionChartProps) => {
  const renderCustomizedLabel = (props: any) => {
    const { x, y, cx, cy, radius, index } = props;

    const item = data[index];

    const angle = Math.atan2(y - cy, x - cx); // Calculate the angle
    const newX = cx + (radius + 25) * Math.cos(angle);
    const newY = cy + (radius + 25) * Math.sin(angle);

    return (
      <foreignObject x={newX - 55} y={newY - 65} width="100" height="100">
        <EmojiCircle icon={item?.icon} color={"white"} item={item} />
      </foreignObject>
    );
  };

  const removedNeutreData = data.filter((item) => item.name !== "Neutre");

  const maxItem = maxBy(removedNeutreData, "value");
  let maxPolygonValue = 0;
  if (!maxItem?.value) {
    maxPolygonValue = 0;
  } else if (maxItem?.value < 40) {
    maxPolygonValue = Math.round((maxItem?.value || 0) / 4) + 1;
  } else if (maxItem?.value <= 60) {
    maxPolygonValue = 15;
  } else if (maxItem?.value <= 80) {
    maxPolygonValue = 20;
  } else if (maxItem?.value <= 100) {
    maxPolygonValue = 25;
  }
  // const maxPolygonValue = 12;
  const transformedData = removedNeutreData.map((item) => {
    return {
      ...item,
      polygon1: 25,
      polygon2: 25 * 2,
      polygon3: 25 * 3,
      polygon4: 25 * 4,
    };
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="50%" data={transformedData}>
        <PolarGrid strokeWidth={1} stroke="rgba(123,123,123,0.2)" />
        <PolarAngleAxis
          domain={[0, 100]}
          dataKey="icon"
          tick={renderCustomizedLabel}
        />
        {/* <PolarRadiusAxis angle={30} /> */}
        {/* This for background color */}

        {[1, 2, 3, 4].map((item) => (
          <Radar
            key={item}
            dataKey={`polygon${item}`}
            fill="rgba(123,123,123,0.2)"
            isAnimationActive={false}
          />
        ))}

        {/* <PolarRadiusAxis domain={[0, 100]} axisLine={false} tick={false} hide /> */}

        {/* Main radar chart data */}
        <Radar
          dataKey="value"
          stroke="rgba(255,212,69,1)"
          strokeWidth={4}
          fill="rgb(255,212,69)"
          fillOpacity={0.2}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default memo(EmotionChart);
