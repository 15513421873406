import { useMutation } from "@tanstack/react-query";
import { Avatar, Button, notification } from "antd";
import { authService } from "api/auth";
import authenApi from "api/authen";
import { KEY_VALUES } from "common/constants";
import { getUser } from "common/helper";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { FailureCallback, IUserInfo, SuccessCallback } from "types";
import DoubleAuthenForm from "./DoubleAuthenForm";
import LoginForm from "./LoginForm";
import { LOGIN_PARAM } from "./constant";
interface AuthContextType {
  user?: IUserInfo;
  signin: (
    param: FormData,
    successCallback: SuccessCallback,
    failureCallback: FailureCallback
  ) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<IUserInfo>();

  const signin = (
    param: FormData,
    callback: SuccessCallback,
    failureCallback: FailureCallback
  ) => {
    const handleSuccess = (data: any) => {
      setUser(jwtDecode(data["access_token"]));
      callback(data);
    };

    return authService.signin(param, handleSuccess, failureCallback);
  };

  const signout = (callback: VoidFunction) => {
    const handleSuccess = () => {
      setUser(undefined);
      callback();
    };

    return authService.signout(handleSuccess);
  };

  useEffect(() => {
    const userItem = getUser();

    setUser(userItem);
  }, []);

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthStatus() {
  const auth = useAuth();
  const navigate = useNavigate();

  const user = getUser();

  return (
    <div className="flex items-center flex-row">
      <div>
        <Link to="/profile" className="flex decoration-inherit">
          <div className="flex items-center">
            <Avatar
              style={{
                border: "2px solid white",
                verticalAlign: "middle",
              }}
              size="large"
            >
              {user?.user?.substring(0, 2).toUpperCase()}
            </Avatar>
            <div className="ml-2 text-[12px] text-white 3xl:text-[24px] max-w-[113px] 3xl:max-w-[223px]">
              <div>{`${user?.["first_name"]} ${user?.["last_name"]}`}</div>
              <div className="overflow-hidden whitespace-nowrap text-ellipsis ">
                {user?.["user"]}
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div>
        <Button
          className="w-[20px]"
          type="link"
          onClick={() => {
            localStorage.removeItem("user");
            localStorage.removeItem(KEY_VALUES.COMPANY_ID);
            auth.signout(() => navigate("/"));
          }}
        >
          <div className="w-5 3xl:w-10">
            <img src="/images/Vector.png" alt="log out" width={"100%"} />
          </div>
        </Button>
      </div>
    </div>
  );
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();

  if (!getUser()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function LoginPage() {
  const [isDoubleAuthenticationStep, setIsDoubleAuthenticationStep] =
    useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const user = getUser();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const mutation = useMutation({
    mutationFn: authenApi.validate,
    onSuccess: () => {
      // Invalidate and refetch
      notification.success({
        message: "Success",
        description: "Login Successfully"!,
      });
      navigate("/company", { replace: true });
      setIsDoubleAuthenticationStep(false);
    },
    onError: () => {
      notification.error({
        message: "Failed",
        description: "Failed to validate"!,
      });
    },
  });

  const handleSubmitDoubleAuthen = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const body = {
      code: new FormData(event.currentTarget).get(LOGIN_PARAM.CODE) as any,
    };

    mutation.mutate({ username: user?.user || "", body });
  };

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    setLoading(true);
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    auth.signin(
      formData,
      () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        setLoading(false);
        setIsDoubleAuthenticationStep(true);
        // navigate("/company", { replace: true });
      },
      (error) => {
        setLoading(false);
        setError(error?.response?.data?.detail);
      }
    );
  }

  return (
    <div className="h-[100vh] bg-primary flex align-middle text-center">
      <div className="box-border w-[344px] m-auto py-[54px] px-[32px] rounded-[8px]">
        <div className="mb-[48px]">
          <img width={160} src="/images/logo_blanc 1.png" alt="talk swiss" />
        </div>
        {error && <p className="text-[red]">{error}</p>}
        <form
          onSubmit={
            isDoubleAuthenticationStep ? handleSubmitDoubleAuthen : handleSubmit
          }
        >
          {!isDoubleAuthenticationStep && <LoginForm loading={loading} />}
          {isDoubleAuthenticationStep && <DoubleAuthenForm loading={loading} />}
        </form>
      </div>
    </div>
  );
}
