import { notification } from "antd";
import { HTTP_STATUS_CODE } from "common/constants/httpStatusCode";
import jwt_decode from "jwt-decode";
import { IUserInfo, NotificationSuccessType } from "types";
import { API_KEY_NAME } from "./constants";
import { LOCAL_STORAGE_KEY } from "./localStorage";

export const getUserToken = () => {
  const userObj = localStorage.getItem(LOCAL_STORAGE_KEY.USER);
  if (!userObj) return undefined;
  try {
    const parsedUser = JSON.parse(userObj);

    return parsedUser;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getUser = () => {
  const userObj = localStorage.getItem(LOCAL_STORAGE_KEY.USER);
  if (!userObj) return undefined;
  try {
    const parsedUser = JSON.parse(userObj);
    const decodedUser = jwt_decode(parsedUser?.token) as IUserInfo;

    return decodedUser;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export function filterListItemByKey(value: string, data: any[], key: string) {
  const filterDataTable = data.filter((item) =>
    item[key]?.toLowerCase().includes(value.toLowerCase())
  );
  return filterDataTable;
}

export const convertMinutesToHour = (minutes: number) => {
  const secondPerMinute = 60;
  const hour = Math.floor(minutes / secondPerMinute);
  const minute = minutes % secondPerMinute;
  const formatNumber = (number: number) =>
    number.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  return `${formatNumber(hour)}h${formatNumber(minute)}`;
};

export function removeItemIsNotExistInDataSource<T extends { id: string }>(
  arrayBeFilter: T[],
  arrayFilterTo: T[]
) {
  return arrayBeFilter.filter(
    (data) => !arrayFilterTo.some((item) => item.id === data.id)
  );
}

export function filterDataSource<T>(dataSource: T[], valueInput: string) {
  const dataFilter = filterListItemByKey(valueInput, dataSource, API_KEY_NAME);
  const dataIsDeleted = removeItemIsNotExistInDataSource(
    dataFilter,
    dataSource
  );
  if (dataIsDeleted.length)
    return removeItemIsNotExistInDataSource(dataFilter, dataIsDeleted);
  return dataFilter;
}

export const notificationError = (status: string) => {
  if (Number(status) === HTTP_STATUS_CODE.EXIST_DATA)
    return notification.error({
      message: "Failed",
      description: "Data already exists",
    });
};

export const notificationSuccess = (
  type: NotificationSuccessType,
  nameNotification: string
) => {
  notification.success({
    message: "Success",
    description: `${type} ${nameNotification} Successfully`,
  });
};
