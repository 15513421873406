import { useMutation, useQuery } from "@tanstack/react-query";
import { notification } from "antd";
import coachApi from "api/coach";
import EmotionChart from "app/chart/EmotionChart2";
import SyllablesChart from "app/chart/SyllabesChart";
import arrayPadStart, { CHART_LENGTH } from "app/chart/SyllabesChart/helper";
import TSButton from "app/components/TSButton";
import Video from "app/components/webcam/Webcam";
import { AxiosResponse } from "axios";
import { IResponseGetSessionDetail } from "interfaces/IMasterclass";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Timer from "react-compound-timer";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { EMOTION_TEMPLATE } from "../ParticipantAnalysis/index.const";
import "./Dashboard.css";
import FrequencyChart from "./FrequencyChart";

////-----------Start Volum Meter Code Test-------------------------------
let audioContext: AudioContext | null = null;
let meter: any = null;
let mediaStreamSource: any = null;
let rafID: any = null;
////-----------End Volum Meter Code Test-------------------------------
let isStreaming = false;
let recordedChunks: any[] = [];
// let stopped = false;
let mediaRecorder: MediaRecorder;
let stream_ref: any = null;

function useInterval(callback: () => void, delay: number) {
  const savedCallback = React.useRef<() => void>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function Dashboard() {
  const [state, setstate] = React.useState<{
    expressions: {
      happy: number;
      surprised: number;
      fearful: number;
      angry: number;
      neutral: number;
      disgusted: number;
      sad: number;
    } | null;
  }>({
    expressions: null,
  });
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [currentTranscript, setCurrentTranscript] = useState("");

  React.useEffect(() => {
    if (stopped) return;
    setCurrentTranscript(transcript);
  }, [transcript]);

  //-----------Start Volum Meter Code Test-------------------------------
  const [data, setData] = useState({ x: [], y: [] });
  //-----------End Volum Meter Code Test---------------------------------

  const location = useLocation();
  const {
    individual_analysis_name,
    individual_language_code,
    individual_participant_id,
  } = location.state || {};

  let { classId, sessionIndex, participantIndex } = useParams();
  const [stats, setStats] = React.useState<{ syllables: number[] }>({
    syllables: [],
  });
  const [buffer, setBuffer] = React.useState("");
  const [started, setStarted] = React.useState(false);
  const [stopped, setIsStopped] = React.useState(false);
  const [volumeBuffer, setVolumeBuffer] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: sessionDetail, isLoading: isLoadingSessionDetail } = useQuery<
    AxiosResponse<IResponseGetSessionDetail>
  >(["classId", classId, "sessionIndex", sessionIndex], () =>
    coachApi.getSessionDetail(classId as string, Number(sessionIndex))
  );

  const { mutate, isLoading } = useMutation({
    mutationFn: coachApi.addAnalysisToSession,
    onSuccess: () => {
      navigate("recording-ended");
      // Invalidate and refetch
    },
    onError: () => {
      notification.error({
        message: "Failed",
        description: t("Add Analysis Failed")!,
      });
    },
  });

  const handleAddAnalysis = () => {
    mutate({
      classId: classId ?? "",
      languageCode: "fr-FR",
      sessionIndex: Number(sessionIndex),
      participantIndex: Number(participantIndex),
      title:
        sessionDetail?.data.participants[Number(participantIndex)].first_name ??
        "" +
          sessionDetail?.data.participants[Number(participantIndex)]
            .last_name ??
        "",
      video: new Blob(recordedChunks),
    });
  };

  React.useEffect(() => {
    return () => window.cancelAnimationFrame(rafID);
  }, []);

  const new_count = (word: string | null) => {
    if (word == null || word === "") {
      return 0;
    }
    word = word.toLowerCase(); //word.downcase!
    if (word.length <= 3) {
      return 1;
    } //return 1 if word.length <= 3
    word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, ""); //word.sub!(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '')
    word = word.replace(/^y/, ""); //word.sub!(/^y/, '')
    return word.match(/[aeiouy]{1,2}/g)
      ? word.match(/[aeiouy]{1,2}/g)?.length
      : 0; //word.scan(/[aeiouy]{1,2}/).size
  };

  const TimeFunc = React.useRef<{ start: () => void; stop: () => void }>();

  useInterval(() => {
    if (!started) return;
    let newText = currentTranscript.substring(buffer.length);
    setStats({
      ...stats,
      syllables: [...stats.syllables, new_count(newText)!],
    });
    setBuffer(currentTranscript + "");
  }, 1000);

  useInterval(() => {
    if (!started) return;
    const average = (arr: any[]) =>
      arr.length ? arr.reduce((p, c) => p + c, 0) / arr.length : 0;
    const volAvg = average(volumeBuffer);
    setData(Object.assign(data, { y: [...data.y, volAvg] }));
    setVolumeBuffer((old) => []);
  }, 1000);

  function startStreaming() {
    SpeechRecognition.startListening({
      continuous: true,
      language: "fr-FR",
    });

    //-----Start Volum Meter Test------------------
    // monkeypatch Web Audio
    window.AudioContext =
      window.AudioContext || (window as any).webkitAudioContext;

    // grab an audio context
    audioContext = new AudioContext();
    //----End volum Meter test--------------------

    isStreaming = true;

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then(
        function (stream) {
          const options = { mimeType: "video/webm" };
          stream_ref = stream;
          mediaRecorder = new MediaRecorder(stream, options);
          mediaRecorder.addEventListener("dataavailable", function (e) {
            if (e.data.size > 0) {
              recordedChunks.push(e.data);
            }
            //console.log("[RECORDER] Chunks size : " + recordedChunks.length )
          });

          mediaRecorder.addEventListener("stop", function () {
            console.log(
              "[RECORDER] Stopped and chunk size = " + recordedChunks.length
            );
            this.stream.getTracks().forEach((track) => track.stop());
          });

          mediaRecorder.start(1);

          //-----------------Start Code Volum Meter Test------------------------
          onMicrophoneGranted(stream);
          //-----------------End Code Volum Meter Test------------------------
          return true;
        },
        function (err) {
          alert("Could not acquire media: " + err);
        }
      )
      .catch((err) => {
        console.error(`The following error occurred: ${err}`);
      });
  }

  function stopStreaming() {
    isStreaming = false;
    setIsStopped(true);
    stream_ref
      ?.getTracks()
      ?.forEach((track: { stop: () => void }) => track.stop());
    mediaRecorder.stop();
    SpeechRecognition?.stopListening();
  }

  function handleStopTime() {
    TimeFunc.current?.stop();
    setStarted(false);
    setIsStopped(true);
    stopStreaming();
  }

  function handleStartTime() {
    recordedChunks = [];
    console.log("Start Recording...");
    setStarted(true);
    startStreaming();
    TimeFunc.current?.start();
  }

  function setExpressions(expressions: any) {
    setstate((state) => {
      return { ...state, ...{ expressions: expressions } };
    });
  }

  //------Start Volum Meter code test---------------------------------
  function onMicrophoneGranted(stream: any) {
    // Create an AudioNode from the stream.
    mediaStreamSource = audioContext?.createMediaStreamSource(stream);

    // Create a new volume meter and connect it.
    meter = createAudioMeter(audioContext);
    mediaStreamSource.connect(meter);

    // kick off the visual updating
    onLevelChange();
  }
  function onLevelChange() {
    setVolumeBuffer((old: any[]) => [...old, meter.volume]);
    // set up the next visual callback
    rafID = window.requestAnimationFrame(onLevelChange);
  }

  //--------------------------------------------------------------------
  //--------------------------------------------------------------------
  //--------------------- Plugins --------------------------------------
  //--------------------------------------------------------------------
  //--------------------------------------------------------------------
  function createAudioMeter(
    audioContext: any,
    clipLevel?: any,
    averaging?: any,
    clipLag?: any
  ) {
    var processor = audioContext.createScriptProcessor(512);
    processor.onaudioprocess = volumeAudioProcess;
    processor.clipping = false;
    processor.lastClip = 0;
    processor.volume = 0;
    processor.clipLevel = clipLevel || 0.98;
    processor.averaging = averaging || 0.95;
    processor.clipLag = clipLag || 750;

    // this will have no effect, since we don't copy the input to the output,
    // but works around a current Chrome bug.
    processor.connect(audioContext.destination);

    processor.checkClipping = function () {
      if (!this.clipping) return false;
      if (this.lastClip + this.clipLag < window.performance.now())
        this.clipping = false;
      return this.clipping;
    };

    processor.shutdown = function () {
      this.disconnect();
      this.onaudioprocess = null;
    };

    return processor;
  }

  function volumeAudioProcess(this: any, event: any) {
    var buf = event.inputBuffer.getChannelData(0);
    var bufLength = buf.length;
    var sum = 0;
    var x;

    // Do a root-mean-square on the samples: sum up the squares...
    for (var i = 0; i < bufLength; i++) {
      x = buf[i];
      if (Math.abs(x) >= this.clipLevel) {
        this.clipping = true;
        this.lastClip = window.performance.now();
      }
      sum += x * x;
    }

    // ... then take the square root of the sum.
    var rms = Math.sqrt(sum / bufLength);

    // Now smooth this out with the averaging factor applied
    // to the previous sample - take the max here because we
    // want "fast attack, slow release."
    this.volume = Math.max(rms, this.volume * this.averaging);
  }
  //------End Volum Meter code test---------------------------------

  const { happy, surprised, fearful, angry, disgusted, sad } =
    state.expressions ?? {};
  const emotionsChartData = !started
    ? new Array(6).fill(0.1)
    : [happy, surprised, fearful, sad, disgusted, angry];

  const chart = useMemo(() => {
    return (
      <EmotionChart
        data={emotionsChartData.map((item, index) => ({
          value: Number(item) * 100,
          label: EMOTION_TEMPLATE[index].name,
        }))}
      />
    );
  }, [emotionsChartData]);

  const participantName =
    (sessionDetail?.data?.participants[Number(participantIndex!)]?.first_name ??
      "") +
    " " +
    (sessionDetail?.data?.participants[Number(participantIndex!)]?.last_name ??
      "");

  const syllableChartData = useMemo(
    () =>
      arrayPadStart(stats.syllables, CHART_LENGTH, 0).map((item, index) => ({
        time: index,
        value: item,
      })),
    [stats]
  );

  const syllablesChart = useMemo(() => {
    return <SyllablesChart data={syllableChartData} />;
  }, [syllableChartData]);

  const transcriptionRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const div = transcriptionRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  }, [currentTranscript]);

  useEffect(() => {
    return function cleanup() {
      stream_ref
        ?.getTracks()
        ?.forEach((track: { stop: () => void }) => track?.stop());
      mediaRecorder?.stop();
      SpeechRecognition?.stopListening();
    };
  }, []);

  return (
    <div className="overflow-hidden h-screen ">
      <div className="fixed p-4 rounded-xl left-5 top-[2vh] bg-[#7B7B7B]/60 backdrop-blur-md w-[150px] h-[160px] 3xl:w-[17vh] 3xl:h-[17vh] 3xl:left-[1vh]">
        <img src="/images/logo_blanc 1.png" alt="logo" width={"100%"} />
      </div>
      <div className="fixed flex z-10 flex-col justify-between p-4 rounded-xl left-[200px] top-[2vh] bg-[#7B7B7B]/60 backdrop-blur-md w-[350px] h-[160px] 3xl:w-[18vw] 3xl:h-[17vh] 3xl:left-[19vh]">
        <div className="cursor-pointer w-[38px] h-[32px] 3xl:w-[76px] 3xl:h-[64px]">
          <img
            width={"100%"}
            src="/images/back-btn.svg"
            alt="back-btn"
            onClick={() => navigate(`/masterclass/${classId}/${sessionIndex}`)}
          />
        </div>
        <p className=" text-white text-[18px] 3xl:text-[36px]">Analyse Live</p>
        <p className=" text-white font-bold text-[32px] truncate 3xl:text-[64px]">
          {participantName}
        </p>
      </div>
      <div className="fixed z-10 w-[30vh] h-[100vh] right-[1vw]">
        <div className="p-[0.5vw] rounded-xl bg-[#7B7B7B]/60 backdrop-blur-md w-full h-[17vh] flex flex-col justify-between mt-[2vh]">
          <div className="text-white text-left text-[24px] font-semibold 3xl:text-[48px]">
            Vitesse
          </div>
          {/* <SyllablesChart data={syllableChartData} /> */}
          {syllablesChart}
        </div>
        <div className="p-[0.5vw] z-10 rounded-xl items-center bg-[#7B7B7B]/60 backdrop-blur-md w-full h-[30vh] mt-[2vh] relative">
          <p className="text-white text-left text-[24px] font-semibold 3xl:text-[48px]">
            Émotions
          </p>
          <div className="absolute left-0 top-5 z-20 w-full h-full">
            {chart}
          </div>
        </div>
        <div className="p-[0.5vw] z-10 flex flex-col text-white rounded-xl bg-[#7B7B7B]/60 backdrop-blur-md w-full h-[45vh] mt-[2vh]">
          <p className="text-white mb-2 text-left text-[24px] font-semibold 3xl:text-[48px]">
            Retranscription
          </p>
          <div
            ref={transcriptionRef}
            className="scrollable-menu flex-grow overflow-y-scroll text-[20px] font-semibold 3xl:text-[40px]"
          >
            {currentTranscript}
          </div>
        </div>
      </div>

      <div className="fixed z-10 flex flex-col justify-center rounded-xl bottom-[2vh] left-5 bg-[#7B7B7B]/60 backdrop-blur-md 2xl:w-[350px] md:w-[250px] h-[150px] 3xl:h-[300px] 3xl:w-[18vw] ">
        <Timer
          initialTime={0}
          formatValue={(value: number) => `${value < 10 ? `0${value}` : value}`}
          startImmediately={false}
        >
          {(control: any) => {
            TimeFunc.current = control;
            return (
              <div className="text-white text-center text-[40px] 3xl:text-[80px]">
                <Timer.Minutes />:
                <Timer.Seconds />
              </div>
            );
          }}
        </Timer>
        <div className="flex justify-center items-center">
          {!stopped && !started && (
            <span className="cursor-pointer" onClick={handleStartTime}>
              <div className="w-[50px] 3xl:w-[100px]">
                <img src="/images/Record.svg" alt="recording" width={"100%"} />
              </div>
            </span>
          )}
          {!stopped && started && (
            <span className="cursor-pointer" onClick={handleStopTime}>
              <div className="w-[50px] 3xl:w-[100px]">
                <img
                  src="/images/Recording.svg"
                  alt="recording"
                  width={"100%"}
                />
              </div>
            </span>
          )}
          {stopped && (
            <div className="flex gap-4 px-4 3xl:px-8 w-full 3xl:gap-8">
              <TSButton
                type="primary"
                className="font-medium w-[232px] 3xl:w-[464px] mt-[16px] text-[12px]  3xl:text-[24px] h-auto"
                onClick={() => window.location.reload()}
              >
                Annuler
              </TSButton>
              <TSButton
                type="default"
                className="font-medium text-grey5 w-[232px] 3xl:w-[464px] mt-[16px] text-[12px] 3xl:text-[24px]  h-auto"
                onClick={handleAddAnalysis}
                loading={isLoading}
              >
                Sauvegarder
              </TSButton>
            </div>
          )}
        </div>
      </div>
      {/* <div className="fixed z-10 bottom-10"> */}
      <FrequencyChart data={data} started={started} stopped={stopped} />
      {/* </div> */}
      <Video setExpressions={setExpressions} />
    </div>
  );
}

export default Dashboard;
