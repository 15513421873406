import { ConfigProvider } from "antd";
import { AuthProvider } from "app/containers/Auth/Login";
import { CompanyProvider } from "context/CompanyContext";
import React from "react";
import ReactDOM from "react-dom/client";
import themeConfig from "theme";
import App from "./App";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <CompanyProvider>
        <ConfigProvider theme={themeConfig}>
          <App />
        </ConfigProvider>
      </CompanyProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
